import { useContext, useRef } from 'react';

import { useForm } from 'react-hook-form';

import FadeIn from '@/components/animations/FadeIn';
import { Button } from '@/components/ui/button';

import CourseEditContext from '../CourseEditContext';

import CourseUnitsForm from './CourseUnitsForm';
import CourseUnitsPlaceholder from './CourseUnitsPlaceholder';

const CourseUnitsTab = () => {
	const { addFocus, courseDraftData, isApprovalMode, canEdit, submitCourse, updateFocus } =
		useContext(CourseEditContext);

	const { handleSubmit, register, watch, getValues } = useForm({
		shouldUnregister: true,
	});

	const handleFocusUpdate = () => {
		const formValues = watch();
		const entries = Object.entries(formValues);

		// First pass: collect focuses and skills
		const focuses = new Map();
		const skills = new Map();

		entries.forEach(([key, value]) => {
			if (key.includes('focusBlob')) {
				const { courseFocusId, focusId, focusOrder } = JSON.parse(value);
				const title =
					courseDraftData.courses_focuses?.find(
						(focus) => focus.courses_focuses_id === courseFocusId
					)?.focus?.focus_title ?? '';

				focuses.set(courseFocusId, {
					courses_focuses_id: courseFocusId,
					focus: {
						focus_id: focusId,
						focus_order: +focusOrder,
						focus_type: '',
						focus_title: title,
						focuses_skills: [],
					},
				});
			}

			if (key.includes('skillBlob')) {
				const { focusSkillId, skillText, skillId, skillType, parentId } = JSON.parse(value);
				if (!skills.has(parentId)) {
					skills.set(parentId, []);
				}
				skills.get(parentId).push({
					focuses_skills_id: focusSkillId,
					skill: {
						skill_id: skillId,
						skill_text: getValues(skillText),
						skill_type: getValues(skillType),
					},
				});
			}
		});

		// Second pass: combine focuses with their skills
		const focusArray = Array.from(focuses.values()).map((focus) => {
			const focusSkills = skills.get(focus.courses_focuses_id) ?? [];
			return {
				...focus,
				focus: {
					...focus.focus,
					focuses_skills: focusSkills,
				},
			};
		});

		updateFocus(focusArray);
	};

	const inputFocusRef = useRef(false);

	return (
		<FadeIn style={{ width: '100%' }}>
			<div style={{ width: '100%' }}>
				<CourseUnitsPlaceholder
					isApprovalMode={isApprovalMode}
					coursesDraftFocuses={courseDraftData.courses_focuses ?? []}
				/>
				<form onSubmit={handleSubmit(submitCourse)} onChange={handleFocusUpdate}>
					<CourseUnitsForm inputFocusRef={inputFocusRef} register={register} />
				</form>
			</div>
			{canEdit && !isApprovalMode && (
				<div className="actions-container">
					<Button variant="secondary" size="small" type="button" onClick={addFocus}>
						Add Unit of Focus
					</Button>
				</div>
			)}
		</FadeIn>
	);
};

export default CourseUnitsTab;
