import { Dispatch, SetStateAction } from 'react';

import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import FadeIn from '@/components/animations/FadeIn';
import { BoxedIconMessage } from '@/components/BoxedIconMessage';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { GetCoursesQuery, GetAllDisciplinesAndDivisionsQuery } from '@/graphql/graphql';
import { courseGrades } from '@/Shared/Data/StaticData';
import { StyledCheckbox, StyledCheckboxLabel } from '@/Shared/StyledElements';
import { StyledH5 } from '@/Shared/Typography/typography';
import { themeSelectStyles } from '@/utils';

import { FormErrorMessage } from '../../FormErrorMessage';

import { StyledCloseIcon, StyledEditForm } from './CourseMapList.Styles';

interface CourseMapListFormProps {
	onSubmit: SubmitHandler<FieldValues>;
	editingCourse: GetCoursesQuery['dev_courses'][0];
	isSuccess: boolean;
	setEditingCourse: Dispatch<
		SetStateAction<GetCoursesQuery['dev_courses'][0] | null | undefined>
	>;
	saveStatus: string;
	disciplineList: GetAllDisciplinesAndDivisionsQuery['dev_disciplines'] | undefined;
	divisionList: GetAllDisciplinesAndDivisionsQuery['dev_divisions'] | undefined;
}

const CourseMapListForm = ({
	onSubmit,
	editingCourse,
	isSuccess,
	setEditingCourse,
	saveStatus,
	disciplineList,
	divisionList,
}: CourseMapListFormProps) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const { course_name: courseName, is_ap: isAp, is_archived: isArchived } = editingCourse;

	const formattedList = (
		list:
			| GetAllDisciplinesAndDivisionsQuery['dev_divisions']
			| GetAllDisciplinesAndDivisionsQuery['dev_disciplines']
	) =>
		list.map((item) => {
			return {
				value: item.name,
				label: item.name,
				id: item.id,
			};
		});

	return (
		<StyledEditForm>
			<StyledH5 mb="var(--spacing-2)">Editing: {courseName}</StyledH5>
			<StyledCloseIcon
				className="is-new-course "
				passedEvent={() => setEditingCourse(null)}
			/>
			<form onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="panelCourseName">Name</label>
				<Input
					type="text"
					autoFocus
					id="panelCourseName"
					placeholder="Name"
					defaultValue={courseName}
					{...register('courseNameEdits', {
						required: 'Course name is required',
					})}
					className="mb-1"
				/>
				<FormErrorMessage
					className="error-message"
					isShowing={!!errors?.courseNameEdits}
					message={`${errors?.courseNameEdits?.message}`}
				/>
				{divisionList && (
					<Controller
						control={control}
						name="divisionEdits"
						defaultValue={editingCourse?.courses_division?.division?.name}
						render={({ field }) => (
							<>
								<span className="label">Division</span>
								<AsyncSelect
									styles={themeSelectStyles}
									placeholder="Division"
									defaultInputValue={field.value}
									onChange={({ value }) => {
										field.onChange(value);
									}}
									isClearable={false}
									loadOptions={(inputValue) => {
										return new Promise((resolve) => {
											const array = formattedList(divisionList);
											const filterResults = array.filter((x) =>
												x?.value
													?.toLowerCase()
													.includes(inputValue.toLowerCase())
											);
											resolve(filterResults);
										});
									}}
									defaultOptions={formattedList(divisionList)}
									noOptionsMessage={() => 'No Divisions Found'}
								/>
							</>
						)}
					/>
				)}
				{disciplineList && (
					<Controller
						control={control}
						name="disciplineEdits"
						defaultValue={editingCourse?.courses_discipline?.discipline?.name ?? ''}
						render={({ field }) => (
							<>
								<span className="label">Discipline</span>
								<AsyncSelect
									styles={themeSelectStyles}
									placeholder="Discipline"
									defaultInputValue={field.value}
									onChange={({ value }) => field.onChange(value)}
									isClearable={false}
									loadOptions={(inputValue) => {
										return new Promise((resolve) => {
											const array = formattedList(disciplineList);
											const filterResults = array.filter((x) =>
												x?.value
													?.toLowerCase()
													.includes(inputValue.toLowerCase())
											);
											resolve(filterResults);
										});
									}}
									defaultOptions={formattedList(disciplineList)}
									noOptionsMessage={() => 'No Disciplines Found'}
								/>
							</>
						)}
					/>
				)}
				<Controller
					control={control}
					name="grade"
					defaultValue={editingCourse?.grade ?? courseGrades[0].value}
					render={({ field }) => (
						<>
							<span className="label">Grade</span>
							<AsyncSelect
								styles={themeSelectStyles}
								placeholder="Grade"
								defaultInputValue={field.value}
								onChange={({ value }) => field.onChange(value)}
								isClearable={false}
								loadOptions={(inputValue) => {
									return new Promise((resolve) => {
										const array = courseGrades;
										const filterResults = array.filter((x) =>
											x.value.toLowerCase().includes(inputValue.toLowerCase())
										);
										resolve(filterResults);
									});
								}}
								defaultOptions={courseGrades}
								noOptionsMessage={() => 'No Grades Found'}
							/>
						</>
					)}
				/>
				<StyledCheckboxLabel htmlFor="isAp" style={{ marginTop: 'var(--spacing-3)' }}>
					<StyledCheckbox
						type="checkbox"
						id="isAp"
						defaultChecked={isAp ?? false}
						{...register('isAp')}
					/>
					Is AP
				</StyledCheckboxLabel>
				<StyledCheckboxLabel htmlFor="isArchived" style={{ marginTop: 'var(--spacing-3)' }}>
					<StyledCheckbox
						type="checkbox"
						id="isArchived"
						defaultChecked={isArchived ?? false}
						{...register('isArchived')}
					/>
					Archived
				</StyledCheckboxLabel>
				<Button
					type="submit"
					isLoading={saveStatus === 'pending'}
					disabled={saveStatus === 'pending'}
					size="small"
					className="mt-2">
					Update
				</Button>
				<Button
					type="button"
					variant="secondary"
					isLoading={saveStatus === 'pending'}
					disabled={saveStatus === 'pending'}
					style={{ marginLeft: '16px' }}
					onClick={() => setEditingCourse(null)}
					size="small">
					Close
				</Button>
				{isSuccess && (
					<FadeIn>
						<BoxedIconMessage
							message={isSuccess ? 'Course Updated!' : 'Unable to Update Course'}
							messageColor={isSuccess ? 'var(--green-300)' : 'var(--red-300)'}
							hasError={!isSuccess}
						/>
					</FadeIn>
				)}
			</form>
		</StyledEditForm>
	);
};

export default CourseMapListForm;
