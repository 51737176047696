import { useQuery } from '@tanstack/react-query';
import { gql, GraphQLClient } from 'graphql-request';

import { GetCourseMapQuery, GetCourseOutcomeQuery } from '@/graphql/graphql';
import { useAlignmentStore } from '@/stores/alignmentStore';
import { prefix, renameAndDestructure } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export function useCourseAlignment() {
	return useQuery({
		queryKey: ['get-course-alignment'],

		queryFn: async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const courseQuery = gql`
				query GetCourseMap {
					${prefix}course_map(where: { isDraft: { _eq: false } }) {
						course_map_json
					}
				}
			`;

			const result = await graphQLClient.request<GetCourseMapQuery>(courseQuery);
			const { course_map: map } = renameAndDestructure(result, prefix) as {
				course_map: GetCourseMapQuery['dev_course_map'];
			};

			return JSON.parse(map[0]?.course_map_json ?? '{}');
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}

export function useAllAlignmentCourses() {
	const { updateAlignmentCourses } = useAlignmentStore();
	return useQuery({
		queryKey: ['get-all-alignment-courses'],

		queryFn: async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const result = await graphQLClient.request<GetCourseOutcomeQuery>(
				gql`
					query GetCourseOutcome {
						${prefix}courses {
							course_name
							course_id
							course_description
							course_resource_info
							course_prereq
							course_extras
							course_discipline
							course_division
							grade
							last_updated
							courses_discipline {
								discipline {
									name
									id
								}
							}
							courses_division {
								division {
									name
									id
								}
							}
							courses_outcomes {
								courses_outcomes_id
								outcome {
									outcome_order
									outcome_text
									outcome_id
								}
							}
							courses_resources {
								course_resources_id
								resource {
									resource_author
									resource_detail
									resource_isbn
									resource_id
									resource_title
									resource_type
								}
							}
							courses_users {
								user_id
								course_id
								user {
									user_id
									user_first
									user_last
									dept_chair {
										user_id
									}
								}
							}
							courses_focuses {
								courses_focuses_id
								focus {
									focus_title
									focus_type
									focus_order
									focus_id
									focuses_skills {
										focuses_skills_id
										skill {
											skill_id
											skill_text
											skill_type
										}
									}
								}
							}
						}
					}
				`
			);

			const { courses } = renameAndDestructure(result, prefix) as {
				courses: GetCourseOutcomeQuery['dev_courses'];
			};
			updateAlignmentCourses(courses);
			return courses;
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}
