import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { useUserRole } from '@/hooks/useAuth';
import {
	CourseIcon,
	EditDisciplineIcon,
	EditDivisionIcon,
	EditUsersIcon,
	KnowledgeBaseIcon,
	MapIcon,
	PublicViewIcon,
	SiteSettingIcon,
	StatsIcon,
	SubmissionsIcon,
	SwitchIcon,
	TagIcon,
} from '@/icons/index';

import AppUpdateNotification from './AppUpdateNotification/AppUpdateNotification';

const StyledSidebar = styled.ul`
	height: 100%;
	padding-left: var(--spacing-6);
	padding-right: var(--spacing-6);
	padding-top: var(--spacing-10);

	.sidebar-container {
		grid-area: sidebar;
		height: 1px;
		position: sticky;
		top: 0;
	}

	li {
		margin-bottom: var(--spacing-8);
	}

	a {
		text-decoration: none;
		&:focus,
		&:hover {
			outline: none;

			svg {
				color: var(--icon-hover);
			}
		}
	}

	.active svg {
		color: var(--icon-hover);
	}
`;

const SidebarNav = () => {
	const { data: userRoleData } = useUserRole();

	if (!userRoleData) return null;

	return (
		<StyledSidebar>
			<NavLink to="/dashboard/courses" aria-label="courses">
				<li>
					<CourseIcon />
				</li>
			</NavLink>
			<NavLink to="/dashboard/submissions" aria-label="submissions">
				<li>
					<SubmissionsIcon />
				</li>
			</NavLink>
			{userRoleData?.user_role === 'admin' && (
				<>
					<NavLink to="/dashboard/users" aria-label="users">
						<li>
							<EditUsersIcon />
						</li>
					</NavLink>
					<NavLink to="/dashboard/tags" aria-label="tags">
						<li>
							<TagIcon />
						</li>
					</NavLink>
					<NavLink to="/dashboard/disciplines" aria-label="disciplines">
						<li>
							<EditDisciplineIcon />
						</li>
					</NavLink>
					<NavLink to="/dashboard/divisions" aria-label="divisions">
						<li>
							<EditDivisionIcon />
						</li>
					</NavLink>
					<NavLink to="/dashboard/stats" aria-label="stats">
						<li>
							<StatsIcon />
						</li>
					</NavLink>
				</>
			)}
			<NavLink to="/dashboard/course-alignment" aria-label="course alignment">
				<li>
					<SwitchIcon />
				</li>
			</NavLink>
			{userRoleData?.user_role === 'admin' && (
				<>
					<NavLink to="/dashboard/course-map" aria-label="course map">
						<li>
							<MapIcon />
						</li>
					</NavLink>
					<NavLink to="/dashboard/settings" aria-label="settings">
						<li>
							<SiteSettingIcon />
						</li>
					</NavLink>
				</>
			)}
			<a href="https://support.elevatemap.app/" target="_blank" rel="noreferrer">
				<li>
					<KnowledgeBaseIcon />
				</li>
			</a>
			<NavLink to="/">
				<li>
					<PublicViewIcon />
				</li>
			</NavLink>
			<AppUpdateNotification />
		</StyledSidebar>
	);
};

export default SidebarNav;
