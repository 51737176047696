import { cn } from '@/lib/utils';

interface FormErrorMessageProps {
	isShowing?: boolean;
	message?: string;
	className?: string;
}

export const FormErrorMessage = ({
	isShowing = false,
	message = '',
	className,
}: FormErrorMessageProps) => (
	<>
		{isShowing && (
			<div className={cn('flex w-full py-2 pr-2 text-body', className)}>
				<svg viewBox="0 0 24 24" width="18" className="fill-red-500 mr-2">
					<path d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207A11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z" />
				</svg>
				<span>{message}</span>
			</div>
		)}
	</>
);

export default FormErrorMessage;
