import { Fragment } from 'react';

import dompurify from 'dompurify';

import { NoDataIcon } from '@/icons/index';
import { StyledOrderedList } from '@/Shared/StyledElements';
import { StyledH6, StyledP } from '@/Shared/Typography/typography';
import { sanitizeOptions } from '@/utils';

interface UnitFocusTabProps {
	courseUnit: CourseFocusEntity[] | any;
}

const FocusTab = ({ courseUnit }: UnitFocusTabProps) => {
	const sanitizer = dompurify.sanitize;

	const setType = (type: string, options: FocusSkillEntity[] | undefined, title: string) => {
		const currentType = options?.filter(({ skill }) => {
			let currentSkillType = skill.skill_type;
			if (skill.skill_type === null || skill.skill_type === '') {
				currentSkillType = 'topic';
			}
			return type === currentSkillType;
		});

		if (!options || currentType?.length === 0) return null;

		return (
			<>
				<StyledH6
					style={{ color: 'var(--org-color)', textTransform: 'capitalize' }}
					mb="8px"
					mt="8px">
					{title}
				</StyledH6>
				{currentType?.map(({ skill }: FocusSkillEntity) => (
					<StyledP key={skill.skill_id}>{skill.skill_text}</StyledP>
				))}
			</>
		);
	};

	if (!courseUnit || courseUnit?.length === 0) {
		return (
			<div className="panel-content">
				<NoDataIcon
					message="This course does not have any listed units of focus."
					delay={0}
				/>
			</div>
		);
	}

	return (
		<div className="panel-content">
			<StyledOrderedList icon="brain">
				{courseUnit
					.sort(
						(a: CourseFocusEntity, b: CourseFocusEntity) =>
							a.focus.focus_order - b.focus.focus_order
					)
					.map(({ focus }: CourseFocusEntity) => {
						const { focus_id: id, focus_title: title, focuses_skills: skills } = focus;
						return (
							<Fragment key={id}>
								<li>
									<StyledP
										mb="16px"
										dangerouslySetInnerHTML={{
											__html: sanitizer(title, {
												...sanitizeOptions,
											}),
										}}
									/>
									<div className="sub-list">
										{setType('question', skills, 'Essential Questions')}
										{setType('topic', skills, 'Subtopics/Descriptions')}
										{setType('skill', skills, 'Skills')}
									</div>
								</li>
							</Fragment>
						);
					})}
			</StyledOrderedList>
		</div>
	);
};

export default FocusTab;
