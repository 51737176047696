import { useState } from 'react';

import { UseFormRegisterReturn } from 'react-hook-form';

import { FormErrorMessage } from '@/components/FormErrorMessage';
import { Input } from '@/components/ui/input';
import { PasswordIcon } from '@/icons';

interface PasswordInputProps {
	registerOptions: UseFormRegisterReturn;
	errors?: any;
	labelText?: string;
}

const PasswordInput = ({ registerOptions, errors, labelText = 'Password' }: PasswordInputProps) => {
	const [isPasswordVisible, setPasswordVisible] = useState(false);

	const togglePassword = () => {
		setPasswordVisible(!isPasswordVisible);
	};

	const key = registerOptions.name;

	return (
		<div className="mb-4">
			<label htmlFor={key} className="block mb-2 text-sm font-medium">
				{labelText}
			</label>
			<div className="relative">
				<Input
					data-testid={key}
					id={key}
					type={isPasswordVisible ? 'text' : 'password'}
					className="pr-14"
					{...registerOptions}
				/>
				<button
					type="button"
					className="absolute right-0 top-0 bottom-0 px-1 w-auto flex items-center max-h-10"
					onClick={togglePassword}>
					<PasswordIcon
						isPasswordVisible={isPasswordVisible}
						passedEvent={togglePassword}
					/>
				</button>
				{errors?.[key] && (
					<FormErrorMessage
						className="-mt-4"
						isShowing={!!errors?.[key]}
						message={errors[key]?.message}
					/>
				)}
			</div>
		</div>
	);
};

export default PasswordInput;
