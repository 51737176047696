import dompurify from 'dompurify';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import { CloseIcon } from '@/icons/index';
import { StyledH3 } from '@/Shared/Typography/typography';
import { sanitizeOptions } from '@/utils';

const StyledCloseIcon = styled(CloseIcon as any)`
	align-self: center;
`;

const StyledWrapper = styled.div`
	color: var(--header-text);
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: var(--spacing-6) var(--spacing-8) var(--spacing-4);

	&:after {
		content: '';
		padding-bottom: var(--spacing-4);
	}

	.header {
		display: flex;
		justify-content: end;
		margin-bottom: var(--spacing-8);
		position: relative;

		${StyledH3} {
			margin: auto;
		}
	}

	li:not([class]) {
		counter-increment: listCounter;
		position: relative;
	}
	li:not([class])::before {
		content: counter(listCounter) '.';
		margin-right: var(--spacing-2);
	}
	.ql-indent-1 {
		list-style-type: circle;
		margin-left: var(--spacing-10);
	}

	.ql-indent-2 {
		list-style-type: circle;
		margin-left: var(--spacing-12);
	}

	ol {
		counter-reset: listCounter;
		list-style: none;
		margin: 0;
	}

	ul {
		margin: 0;
	}
`;

interface PhiloModalProps {
	philoModalActive: boolean;
	currentPhilo: Discipline;
	closeEvent(): void;
}

const PhiloModal = ({ philoModalActive, currentPhilo, closeEvent }: PhiloModalProps) => {
	const modalStyles: ReactModal.Styles = {
		content: {
			background: 'none',
			backgroundColor: 'var(--modal-bg)',
			border: 'none',
			inset: 'initial',
			margin: 'auto',
			maxHeight: '90vh',
			maxWidth: '900px',
			overflow: 'auto',
			padding: 0,
			position: 'relative',
			width: '95%',
		},
		overlay: {
			backgroundColor: 'var(--modal-overlay)',
			display: 'flex',
			justifyContent: 'center',
			zIndex: 10,
		},
	};
	ReactModal.setAppElement('#root');
	const sanitizer = dompurify.sanitize;

	return (
		<ReactModal
			style={modalStyles}
			shouldCloseOnOverlayClick
			onRequestClose={closeEvent}
			shouldCloseOnEsc
			isOpen={philoModalActive}
			contentLabel="Philosophy Modal">
			<StyledWrapper>
				<div className="header">
					<StyledH3 mb="0">{`${currentPhilo?.name}`} Philosophy</StyledH3>
					<StyledCloseIcon
						top="-8px"
						right="0"
						className="close-btn"
						passedEvent={closeEvent}
					/>
				</div>
				<div
					dangerouslySetInnerHTML={{
						__html: sanitizer(currentPhilo?.philosophy, {
							...sanitizeOptions,
						}),
					}}
				/>
			</StyledWrapper>
		</ReactModal>
	);
};

export default PhiloModal;
