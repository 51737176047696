import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import scrollIntoView from 'scroll-into-view';

interface LocationState {
	id?: string;
}

export const useHashScrollTo = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const locationState = location.state as LocationState;

	useEffect(() => {
		if (locationState?.id) {
			const courseId = locationState?.id;
			const element = document.getElementById(`row-${courseId}`) as HTMLElement;

			scrollIntoView(
				element,
				{
					align: {
						topOffset: 0,
						left: 0,
					},
				},
				() => {
					// Prevents false positive on initial load
					if (element.id === `row-${courseId}`) {
						element.classList.add('active-row');
					}
					setTimeout(() => {
						element.classList.remove('active-row');
					}, 1000);
					navigate(
						{
							pathname: location.pathname,
							search: location.search,
						},
						{
							state: null,
						}
					);
				}
			);
		}
	});
};
