import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';
import { LoaderCircle } from 'lucide-react';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
	'inline-flex items-center justify-center whitespace-nowrap rounded-lg typo-body-2  ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-sm font-poppinsSemiBold',
	{
		variants: {
			variant: {
				default: 'bg-purple-900 text-white hover:bg-purple-700',
				destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
				green: 'bg-green-700 text-white hover:bg-green-600',
				outline:
					'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
				'pill-outline':
					'border border-border bg-background hover:bg-purple-50 focus-visible:bg-purple-50 focus-visible:border-primary focus-visible:text-primary hover:text-primary hover:border-primary rounded-full',
				secondary: 'border border-primary bg-white text-primary hover:bg-secondary/80',
				ghost: 'hover:bg-accent hover:text-accent-foreground',
				link: 'text-primary underline-offset-4 hover:underline',
			},
			size: {
				min: '',
				default: 'h-9 px-4 py-2',
				small: 'h-8 px-3',
				xs: 'h-7 px-3',
				large: 'h-11 px-8',
				icon: 'h-10 w-10',
			},
			isLoading: {
				true: 'relative',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		React.PropsWithChildren,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{ className, variant, size, children, asChild = false, disabled, isLoading, ...props },
		ref
	) => {
		const Comp = asChild ? Slot : 'button';
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, isLoading, className }))}
				disabled={isLoading || disabled}
				ref={ref}
				{...props}>
				{isLoading ? (
					<>
						<span className={cn(isLoading && 'invisible')}>{children}</span>
						<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
							<LoaderCircle className="animate-spin" />
						</div>
					</>
				) : (
					children
				)}
			</Comp>
		);
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };
