import { useQuery } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';

import {
	DeleteDisciplineMutation,
	GetAllDisciplinesQuery,
	GetCourseMapQuery,
	UpdateCourseDisciplineMutation,
	UpdateDisciplinesMutation,
} from '@/graphql/graphql';
import { updateMutation } from '@/mutations';
import { isObjectEmpty, prefix, renameAndDestructure } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

const getGraphQLClient = async () => {
	const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	const graphQLClient = new GraphQLClient(endpoint, {
		headers: {
			Authorization: `Bearer ${token}`,
			'x-hasura-role': 'admin',
		},
	});

	return graphQLClient;
};

const eachRecursive = (obj: any, previousName = '', newName = '') => {
	// eslint-disable-next-line
	for (const k in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, k)) {
			if (typeof obj[k] === 'object' && obj[k] !== null) {
				eachRecursive(obj[k], previousName, newName);
			}

			if (k === 'discipline' && obj[k] === previousName) {
				obj[k] = newName;
			}
			if (k === 'name' && obj[k] === previousName) {
				obj[k] = newName;
			}
			if (k === 'isDiscipline' && obj[k] === previousName) {
				obj[k] = newName;
				obj.id = obj.id.replace(previousName, newName);
			}
		}
	}
	return obj;
};

const updateCourseMap = async (previousName: string, newName: string) => {
	const graphQLClient = await getGraphQLClient();

	const courseQuery = gql`
		query GetCourseMap {
			${prefix}course_map(where: { isDraft: { _eq: false } }) {
				course_map_json
			}
		}
	`;

	const result = await graphQLClient.request<GetCourseMapQuery>(courseQuery);
	const { course_map: map } = renameAndDestructure(result, prefix) as {
		course_map: GetCourseMapQuery['dev_course_map'];
	};
	const mapJSON = JSON.parse(map[0]?.course_map_json ?? '{}');

	if (isObjectEmpty(mapJSON)) {
		return { affected_rows: 0 };
	}

	const filteredChildren = mapJSON.children.filter((child: any) => child.name !== previousName);
	const newChildren = mapJSON.children.find(
		(child: any) => child.name === previousName
	)?.children;
	const updateIdx = filteredChildren.findIndex((child: any) => child.name === newName);
	// move existing children to new parent
	if (newChildren && newChildren?.length !== 0 && filteredChildren[updateIdx]?.children) {
		filteredChildren[updateIdx].children = [
			...newChildren,
			...(filteredChildren[updateIdx].children ?? []),
		];
	}

	// update base node children array
	mapJSON.children = filteredChildren;

	const updatedMap = JSON.stringify(eachRecursive(mapJSON, previousName, newName));

	return graphQLClient.request(updateMutation, { map: updatedMap });
};

const updateCourseMapDisciplineName = async (previousName: string, newName: string) => {
	const graphQLClient = await getGraphQLClient();

	const courseQuery = gql`
		query GetCourseMap {
			${prefix}course_map(where: { isDraft: { _eq: false } }) {
				course_map_json
			}
		}
	`;

	const result = await graphQLClient.request<GetCourseMapQuery>(courseQuery);
	const { course_map: map } = renameAndDestructure(result, prefix) as {
		course_map: GetCourseMapQuery['dev_course_map'];
	};
	const mapJSON = JSON.parse(map[0]?.course_map_json ?? '{}');

	if (isObjectEmpty(mapJSON)) {
		return { affected_rows: 0 };
	}

	const updatedMap = JSON.stringify(eachRecursive(mapJSON, previousName, newName));

	return graphQLClient.request(updateMutation, { map: updatedMap });
};

interface ColorRefObj {
	newColor: string;
	newName: string;
	previousName: string;
}

const updateCourseMapColors = async (refObj: ColorRefObj) => {
	const graphQLClient = await getGraphQLClient();
	const { newColor, newName, previousName } = refObj;
	const courseQuery = gql`
		query GetCourseMap {
			${prefix}course_map(where: { isDraft: { _eq: false } }) {
				course_map_json
			}
		}
	`;

	const result = await graphQLClient.request<GetCourseMapQuery>(courseQuery);
	const { course_map: map } = renameAndDestructure(result, prefix) as {
		course_map: GetCourseMapQuery['dev_course_map'];
	};
	const mapJSON = JSON.parse(map[0]?.course_map_json ?? '{}');

	if (isObjectEmpty(mapJSON)) {
		return { affected_rows: 0 };
	}
	// find course within the course map that has the previous of name or new name
	const childRef = mapJSON.children.find(
		(child: any) => child.name === (newName || previousName)
	);
	if (childRef) {
		childRef.color = newColor;
	}

	const updatedMap = JSON.stringify(mapJSON);

	return graphQLClient.request(updateMutation, { map: updatedMap });
};

export const deleteDiscipline = async (variables: Pick<DisciplinesMutation, 'id'>) => {
	const graphQLClient = await getGraphQLClient();

	const mutation = gql`
		mutation DeleteDiscipline($id: uuid!) {
			${prefix}delete_disciplines(where: { id: { _eq: $id } }) {
				returning {
					id
					name
				}
			}
		}
	`;

	const result = await graphQLClient.request<DeleteDisciplineMutation>(mutation, variables);
	const { delete_disciplines: deleteDisciplines } = renameAndDestructure(result, prefix) as {
		delete_disciplines: DeleteDisciplineMutation['dev_delete_disciplines'];
	};

	if (deleteDisciplines?.returning?.[0].name) {
		updateCourseMap(deleteDisciplines.returning?.[0]?.name, '');
	}
	return deleteDisciplines?.returning[0];
};

export const updateCourseDiscipline = async (variables: {
	previousDiscipline: GetAllDisciplinesQuery['dev_disciplines'][0];
	newDiscipline: Pick<GetAllDisciplinesQuery['dev_disciplines'][0], 'id' | 'name'>;
}) => {
	const graphQLClient = await getGraphQLClient();
	const { previousDiscipline, newDiscipline } = variables;

	const mutation = gql`
		mutation UpdateCourseDiscipline($previousDisciplineId: uuid!, $newDisciplineId: uuid!) {
			${prefix}update_courses_disciplines(
				where: { discipline_id: { _eq: $previousDisciplineId } }
				_set: { discipline_id: $newDisciplineId }
			) {
				returning {
					course_id
					courses_disciplines_id
					discipline_id
				}
			}
		}
	`;

	const result = await graphQLClient.request<UpdateCourseDisciplineMutation>(mutation, {
		previousDisciplineId: previousDiscipline.id,
		newDisciplineId: newDiscipline.id,
	});

	const { update_courses_disciplines: updateCoursesDisciplines } = renameAndDestructure(
		result,
		prefix
	) as {
		update_courses_disciplines: UpdateCourseDisciplineMutation['dev_update_courses_disciplines'];
	};

	await updateCourseMap(previousDiscipline.name ?? '', newDiscipline.name ?? '');

	await deleteDiscipline({
		id: previousDiscipline.id,
	});

	return updateCoursesDisciplines?.returning;
};

interface DisciplinesMutation {
	id: string;
	philosophy: string;
	name: string;
	color: string;
	image: string;
	previousState: GetAllDisciplinesQuery['dev_disciplines'][0] | null;
}

export const updateDisciplines = async (variables: DisciplinesMutation) => {
	const graphQLClient = await getGraphQLClient();
	const mutation = gql`
		mutation UpdateDisciplines(
			$id: uuid!
			$philosophy: String!
			$name: String!
			$color: String!
			$image: String!
		) {
			${prefix}update_disciplines(
				where: { id: { _eq: $id } }
				_set: { philosophy: $philosophy, name: $name, color: $color, image: $image }
			) {
				returning {
					id
					philosophy
					name
					color
					image
					courses_disciplines {
						courses_disciplines_id
						course_id
						discipline_id
					}
				}
			}
		}
	`;

	if (variables.previousState?.name !== variables.name && variables.previousState) {
		await updateCourseMapDisciplineName(variables.previousState.name ?? '', variables.name);
	}

	if (variables.previousState?.color !== variables.color && variables.previousState) {
		const refObj = {
			previousColor: variables.previousState.color,
			previousName: variables.previousState.name,
			newColor: variables.color,
			newName: variables.name,
		};
		await updateCourseMapColors(refObj as ColorRefObj);
	}

	const result = await graphQLClient.request<UpdateDisciplinesMutation>(mutation, {
		id: variables.id,
		philosophy: variables.philosophy,
		name: variables.name,
		color: variables.color,
		image: variables.image,
	});

	const returnValue = renameAndDestructure(result, prefix) as {
		update_disciplines: UpdateDisciplinesMutation['dev_update_disciplines'];
	};
	return returnValue.update_disciplines?.returning[0];
};

export const createDiscipline = async (
	variables: Omit<DisciplinesMutation, 'id' | 'previousState'>
) => {
	const graphQLClient = await getGraphQLClient();
	const mutation = gql`
		mutation CreateDiscipline(
			$name: String!
			$philosophy: String
			$color: String!
			$image: String!
		) {
			${prefix}insert_disciplines_one(
				object: { name: $name, philosophy: $philosophy, color: $color, image: $image }
			) {
				name
				philosophy
				id
				color
				image
			}
		}
	`;

	return graphQLClient.request(mutation, variables);
};

export function useGetDisciplines() {
	return useQuery({
		queryKey: ['get-all-disciplines'],

		queryFn: async () => {
			graphQLClient.setHeader('content-type', `application/json`);

			const result = await graphQLClient.request<GetAllDisciplinesQuery>(
				gql`
					query GetAllDisciplines {
						${prefix}disciplines(order_by: { name: asc }) {
							id
							name
							philosophy
							color
							image
							courses_disciplines {
								courses_disciplines_id
								course_id
								discipline_id
							}
						}
					}
				`
			);
			const { disciplines } = renameAndDestructure(result, prefix) as {
				disciplines: GetAllDisciplinesQuery['dev_disciplines'];
			};
			return disciplines;
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}

// TODO: add to migrate courses to a new discipline
// export const migrateDisciplines = async () => {
// 	const graphQLClient = await getGraphQLClient();
// 	const type = 'Math';
// 	const type2 = 'Math';
// 	const courseQuery = gql`
// 		query Migrate($type: String!, $type2: String!) {
// 			courses(where: { course_discipline: { _eq: $type } }) {
// 				course_name
// 				course_discipline
// 				course_id
// 				courses_disciplines {
// 					discipline {
// 						name
// 					}
// 				}
// 			}
// 			disciplines(where: { name: { _eq: $type2 } }) {
// 				name
// 				id
// 			}
// 		}
// 	`;

// 	const a = await graphQLClient.request(courseQuery, { type, type2 });
// 	// eslint-disable-next-line arrow-body-style
// 	const objects = a.courses.map((course: any) => {
// 		return { course_id: course.course_id, discipline_id: a.disciplines[0].id };
// 	});

// 	const mutation = gql`
// 		mutation CourseDiscMut($objects: [courses_disciplines_insert_input!]!) {
// 			insert_courses_disciplines(
// 				objects: $objects
// 				on_conflict: {
// 					update_columns: discipline_id
// 					constraint: courses_disciplines_course_id_key
// 				}
// 			) {
// 				affected_rows
// 			}
// 		}
// 	`;

// 	return graphQLClient.request(mutation, { objects });
// };
