import { useContext } from 'react';

import { useIsMutating } from '@tanstack/react-query';

import { Button } from '@/components/ui/button';

import CourseEditContext from '../CourseEditContext';

interface CourseModalSubmitProps {
	submitEvent(): void;
	draftSubmitEvent(): void;
}

const CourseEditSubmit = ({ submitEvent, draftSubmitEvent }: CourseModalSubmitProps) => {
	const isMutatingPosts = useIsMutating({ mutationKey: ['save-course-draft'] });
	const { isPendingApproval } = useContext(CourseEditContext);

	return (
		<>
			<Button
				type="submit"
				variant="outline"
				disabled={isMutatingPosts !== 0 || isPendingApproval()}
				isLoading={isMutatingPosts !== 0}
				onClick={draftSubmitEvent}
				className="ml-auto mr-4">
				Save Draft
			</Button>
			<Button
				type="submit"
				disabled={isMutatingPosts !== 0}
				onClick={submitEvent}
				className="save-btn">
				Submit for Review
			</Button>
		</>
	);
};

export default CourseEditSubmit;
