import { useReducer } from 'react';

import { CourseResourceEnum } from '@/Enums/enum';
import { cn } from '@/lib/utils';

interface StringMap {
	[key: string]: string;
}

interface ButtonGroupProps {
	filterEvent: (type: CourseResourceEnum) => void;
	types: StringMap;
}

const ButtonGroup = ({ ...props }: ButtonGroupProps) => {
	const { types, filterEvent } = props;
	const initialType = { show: 'all' };

	const typeReducer = (_: StringMap, action: StringMap) => {
		return {
			show: action.type,
		};
	};

	const [state, dispatch] = useReducer(typeReducer, initialType);

	const handleFilter = (type: string) => {
		filterEvent(type as CourseResourceEnum);
		dispatch({ type });
	};

	return (
		<>
			<div className="pb-2 text-purple-900 font-poppins">Filter By</div>
			<div className="button-group rounded-lg p-1 mb-4 bg-purple-50">
				{Object.entries(types)
					.sort()
					.map((type: string[], i: number) => (
						<button
							key={`${type}-${i.toString()}`}
							// className={state.show === type[1] ? 'active' : ''}
							className={cn(
								'rounded cursor-pointer mr-2 px-4 py-1 font-poppinsSemiBold text-[1.5rem] last-of-type:mr-0 hover:bg-purple-700 hover:text-white border-0 focus-visible:outline-1 focus-visible:outline focus-visible:outline-purple-900 transition-colors duration-300',
								state.show === type[1] && 'bg-purple-900 text-white'
							)}
							onClick={() => handleFilter(type[1])}
							type="button">
							{type[0]}
						</button>
					))}
			</div>
		</>
	);
};

export default ButtonGroup;
