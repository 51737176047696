import { useRef, useState } from 'react';

import styled from 'styled-components';

import useOutsideClick from '@/hooks/useOutsideClick';

const StyledOptionMenu = styled.div`
	position: relative;

	.option-trigger {
		background: none;
		border-radius: 4px;
		border: none;
		cursor: pointer;
		display: flex;
		margin-bottom: var(--spacing-1);
		padding: 4px;

		&:focus,
		&:hover,
		&:focus-visible {
			outline: 1px solid #999fa3;
		}
	}
	.option-container {
		border-radius: 4px;
		box-shadow: 0px 1px 0px rgba(0, 15, 25, 0.05), 0px 8px 16px rgba(0, 15, 25, 0.05);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: absolute;
		right: 0;

		button {
			background-color: var(--c-white);
			border: none;
			color: var(--elevate-primary);
			font-size: 1.4rem;
			padding: var(--spacing-1) var(--spacing-2);
			text-align: left;

			&:hover,
			&:focus-visible {
				background-color: #e4e8eb;
			}
		}
	}
`;

interface OptionMenuProps {
	options: { label: string; onClick: () => void; id: string }[];
}

const OptionMenu = ({ options }: OptionMenuProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const menuButtonRef = useRef(document.createElement('div'));

	useOutsideClick(menuButtonRef, () => {
		setIsOpen(false);
	});

	return (
		<StyledOptionMenu>
			<button type="button" className="option-trigger" onClick={() => setIsOpen(!isOpen)}>
				<svg
					viewBox="0 0 32 32"
					width="20"
					xmlns="http://www.w3.org/2000/svg"
					fill="#999FA3">
					<path d="M13 16c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zM13 26c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zM13 6c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z" />
				</svg>
				<span className="screen-reader-text">Options Menu</span>
			</button>
			{isOpen && (
				<div className="option-container" ref={menuButtonRef}>
					{options.map((option) => (
						<button
							key={option.id}
							type="button"
							onClick={() => {
								option.onClick();
								setIsOpen(false);
							}}>
							{option.label}
						</button>
					))}
				</div>
			)}
		</StyledOptionMenu>
	);
};

export default OptionMenu;
