import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';

import { AddCourseMutation } from '@/graphql/graphql';
import { courseDisciplineMutation, courseDivisionMutation } from '@/mutations';
import { prefix, renameAndDestructure } from '@/utils';

interface NewCourseEntity {
	disciplineId: string;
	divisionId: string;
	courseName: string;
	grade: string;
	isAp: boolean;
}

export const addNewCourse = async (variables: NewCourseEntity) => {
	const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('content-type', `application/json`);
	graphQLClient.setHeader('x-hasura-role', 'admin');
	const addCourseMutation = gql`
		mutation AddCourse($courseName: String!, $grade: String!, $isAp: Boolean!) {
			${prefix}insert_courses_one(object: { course_name: $courseName, grade: $grade, is_ap: $isAp }) {
				course_id
			}
		}
	`;

	const result = await graphQLClient.request<AddCourseMutation>(addCourseMutation, {
		courseName: variables.courseName,
		grade: variables.grade,
		isAp: variables.isAp,
	});

	const { insert_courses_one: course } = renameAndDestructure(result, prefix) as {
		insert_courses_one: AddCourseMutation['dev_insert_courses_one'];
	};

	await graphQLClient.request(courseDisciplineMutation, {
		disciplineId: variables.disciplineId,
		courseId: course?.course_id,
	});

	const data = await graphQLClient.request(courseDivisionMutation, {
		divisionId: variables.divisionId,
		courseId: course?.course_id,
	});

	const response = renameAndDestructure(data, prefix);
	return response;
};
