import { useEffect } from 'react';

import * as d3 from 'd3';
import styled from 'styled-components';

const StyledControls = styled.div`
	display: flex;
	margin: 10px;
	position: fixed;

	.control-btn {
		align-items: center;
		background: var(--org-color);
		border-radius: 4px;
		border: 1px solid var(--org-color);
		color: var(--neutral-50);
		cursor: pointer;
		display: flex;
		height: 35px;
		justify-content: center;
		line-height: 0;
		margin-right: 10px;
		transition: background-color 0.3s, color 0.3s;
		width: 35px;

		&:hover,
		&:focus {
			background-color: var(--neutral-50);
			color: var(--org-color);
			outline: none;
		}

		&--disabled {
			opacity: 0.2;
			pointer-events: none;
		}

		svg {
			fill: currentColor;
			stroke: currentColor;
			stroke-width: 1px;
		}
	}
`;

interface MapButtonControlsProps {
	zoom: { current: d3.ZoomBehavior<any, any> };
	svgInstance: { current: d3.Selection<SVGElement, any, HTMLElement, any> | any };
	duration: number;
	zoomRef: { current: d3.ZoomTransform };
}

function MapButtonControls({ zoom, svgInstance, duration, zoomRef }: MapButtonControlsProps) {
	useEffect(() => {
		const { x: xCoords, y: yCoords, k: zoomLevel } = zoomRef.current;
		const { current: svg } = svgInstance;

		function setButtonState() {
			if (zoomRef.current.k >= 0.9) {
				d3.select('#zoom-in').classed('control-btn--disabled', true);
			} else {
				d3.select('#zoom-in').classed('control-btn--disabled', false);
			}

			if (zoomRef.current.k <= 0.15) {
				d3.select('#zoom-out').classed('control-btn--disabled', true);
			} else {
				d3.select('#zoom-out').classed('control-btn--disabled', false);
			}
		}

		zoom.current.on('end', () => {
			setButtonState();
		});

		d3.select('#pan-reset').on('click', () => {
			zoom.current.transform(
				svg
					.interrupt()
					.transition()
					.duration(duration * 2),
				d3.zoomIdentity.translate(xCoords, yCoords).scale(zoomLevel)
			);
		});

		d3.select('#zoom-in').on('click', () => {
			zoom.current.scaleBy(svg.interrupt().transition().duration(duration), 1.3, [
				xCoords / 2,
				yCoords,
			]);
		});

		d3.select('#zoom-out').on('click', () => {
			zoom.current.scaleBy(svg.interrupt().transition().duration(duration), 1 / 1.3, [
				xCoords,
				yCoords,
			]);
		});
	}, [zoom, svgInstance, duration, zoomRef]);

	return (
		<StyledControls className="map-controls">
			<button
				id="zoom-in"
				aria-label="Zoom in"
				className="control-btn control-btn--left"
				type="button">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="14"
					height="14"
					aria-hidden="true"
					focusable="false">
					<path d="M7.5 6.5H14v1H7.5V14h-1V7.5H0v-1h6.5V0h1z" />
				</svg>
			</button>
			<button
				id="zoom-out"
				aria-label="Zoom out"
				className="control-btn control-btn--left"
				type="button">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="14"
					height="4"
					aria-hidden="true"
					focusable="false">
					<path d="M14 .5v1H0v-1z" />
				</svg>
			</button>
			<button id="pan-reset" aria-label="Reset map" className="control-btn" type="button">
				<svg
					viewBox="0 0 35 35"
					width="19"
					aria-hidden="true"
					focusable="false"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path d="M17.2 1.2c-5.9 0-11 3.4-13.4 8.5L2 6.2 0 7.2l3.5 7.2 7.2-3.5-1-2-3.8 1.7a12.5 12.5 0 111 12.6l-1.8 1.3A14.9 14.9 0 1017 1.2z" />
				</svg>
			</button>
		</StyledControls>
	);
}
export default MapButtonControls;
