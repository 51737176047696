import styled from 'styled-components';

export const StyledFilters = styled.div`
	display: grid;
	gap: var(--spacing-5);
	grid-template-columns: 1fr minmax(200px, 230px);
	grid-template-rows: auto auto;
	justify-content: flex-end;
	position: relative;

	.filter-select {
		grid-column: 2;
		grid-row: 2;
	}
`;

export const StyledSearch = styled.div`
	display: inline-block;
	grid-row: 2;
	justify-self: flex-end;
	position: relative;
	height: max-content;

	&.is-single {
		grid-column: 2;
	}
`;

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: var(--spacing-6);

	.result-count {
		color: var(--body-text);
		grid-column: 2;
		justify-self: end;
	}
`;
