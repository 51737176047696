/* eslint-disable react/no-unstable-nested-components */
import { useState, useCallback, useMemo } from 'react';

import { useQueries } from '@tanstack/react-query';
import { Link, useSearchParams } from 'react-router-dom';

import FadeIn from '@/components/animations/FadeIn';
import Badge from '@/components/Badge/Badge';
import Table from '@/components/Table';
import TableFilters from '@/components/TableFilters/TableFilters';
import { Skeleton } from '@/components/ui/skeleton';
import { SetStatus } from '@/Enums/enum';
import { currentUser } from '@/hooks/useAuth';
import { useHashScrollTo } from '@/hooks/useHashScrollTo';
import { EditIcon, ViewIcon } from '@/icons';
import { StyledCheckbox, StyledCheckboxLabel } from '@/Shared/StyledElements';

import { getAllCourses } from './CoursesContainers';
import { StyledActionGroup, StyledCourseFilters } from './CoursesStyles';

const Courses = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	// scroll to course if hash is set in url
	useHashScrollTo();

	const results = useQueries({
		queries: [
			{
				queryKey: ['all-courses'],
				queryFn: () => getAllCourses(),
				staleTime: Infinity,
				refetchOnMount: 'always',
			},
			{
				queryKey: ['current-user'],
				queryFn: () => currentUser(),
				staleTime: Infinity,
				refetchOnMount: 'always',
			},
		],
	});

	const courses = results[0].data;
	const userData = results[1].data as Omit<User, 'courses' | 'courses_users'>;
	const isLoading = results.some((result) => result.isLoading);

	function isCourseOwner(
		courseArray: CourseUserEntity[],
		user: Omit<User, 'courses' | 'courses_users'>
	) {
		if (!user) return false;

		const canEdit =
			courseArray.some((c: CourseUserEntity) => c.user_id === user.user_id) ||
			user.user_role === 'admin';

		const isDept = courseArray.some(
			(c: CourseUserEntity) => c?.user?.dept_chair?.user_id === user.user_id
		);

		return isDept || canEdit;
	}

	const columns = useMemo(
		() => [
			{
				Header: 'Course',
				accessor: 'courseColumn',
				sortType: 'basic',
				fixedWidth: '25%',
			},
			{
				Header: 'Teacher',
				accessor: 'courseTeacher',
				sortType: 'basic',
				fixedWidth: '20%',
				Cell(props: any) {
					const { rowData } = props;
					const { courses_users: courseUsers } = rowData.actionsColumn;
					if (courseUsers.length !== 0) {
						return courseUsers.map((courseUser: CourseUserEntity, i: number) => {
							const { user } = courseUser;

							return (
								<div key={`teacher-row-${rowData.idColumn}-${i.toString()}`}>
									{`${user?.user_first ?? ''} ${user?.user_last ?? ''}`}
								</div>
							);
						});
					}
					return <div />;
				},
			},
			{
				Header: 'Division',
				accessor: 'courseDivision',
				sortType: 'basic',
				fixedWidth: '20%',
			},
			{
				Header: 'Discipline',
				accessor: 'courseDiscipline',
				sortType: 'basic',
				fixedWidth: '15%',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				fixedWidth: '20%',
				alignment: 'right',
				Cell(props: { [key: string]: any }) {
					const { rowData } = props;
					const { actionsColumn } = rowData;
					const { is_saved_draft: hasDraft } = actionsColumn;

					const isPendingApproval = () => {
						if (actionsColumn?.submission) {
							return Object.values(actionsColumn.submission).every(
								(status) => status !== SetStatus.APPROVED
							);
						}
						return false;
					};

					const renderBadge = () => {
						if (hasDraft) {
							return (
								<Badge style={{ marginRight: 'var(--spacing-2)' }} text="Draft" />
							);
						}

						if (isPendingApproval()) {
							return (
								<Badge
									style={{ marginRight: 'var(--spacing-2)' }}
									text="Approval Pending"
								/>
							);
						}
						return null;
					};

					const canEdit = isCourseOwner(actionsColumn.courses_users, userData);

					if (canEdit) {
						return (
							<StyledActionGroup>
								{renderBadge()}
								<Link to={`${actionsColumn.course_id}`}>
									<EditIcon width={18} passedEvent={() => false} />
								</Link>
							</StyledActionGroup>
						);
					}
					return (
						<StyledActionGroup>
							<Link to={`${actionsColumn.course_id}`}>
								<ViewIcon />
							</Link>
						</StyledActionGroup>
					);
				},
			},
		],
		[userData]
	);

	const isArchived = JSON.parse(`${searchParams.get('showArchived') ?? false}`);

	const queryCourseData = useMemo(
		() =>
			courses
				?.filter((course) => course?.is_archived === isArchived)
				?.map((course) => ({
					courseColumn: course.course_name,
					courseArchivedColumn: course.is_archived,
					courseDivision: course?.courses_division?.division?.name,
					courseDiscipline: course?.courses_discipline?.discipline?.name,
					courseTeacher: course,
					actionsColumn: course,
					idColumn: course.course_id,
				})),
		[courses, isArchived]
	);

	const [filteredData, setFilteredData] = useState(queryCourseData);
	const handleSetData = useCallback(
		(queryTableData: any) => {
			const userSortedCourses = queryTableData.reduce(
				(acc: CourseTableRow[], element: CourseTableRow) => {
					const canEdit = isCourseOwner(
						element.actionsColumn.courses_users,
						userData as User
					);
					if (canEdit) {
						return [element, ...acc];
					}
					return [...acc, element];
				},
				[]
			);
			// get last index of user assigned course
			const lastIdx = userSortedCourses.filter((row: CourseTableRow) => {
				const canEdit = isCourseOwner(row.actionsColumn.courses_users, userData);
				if (canEdit) {
					return row;
				}
				return false;
			}).length;

			// sort user assigned courses
			const allSortedCourses = userSortedCourses
				.slice(0, lastIdx)
				.sort((a: CourseTableRow, b: CourseTableRow) => {
					if (a.courseColumn > b.courseColumn) return 1;
					if (a.courseColumn < b.courseColumn) return -1;
					return 0;
				})
				.concat(userSortedCourses.slice(lastIdx));

			setFilteredData(allSortedCourses);
		},
		[userData]
	);

	// if (isLoading) {
	// 	return (
	// 		<>
	// 			<Skeleton className="w-full max-w-4xl h-[96px] pt-8 mb-4 ml-auto" />
	// 			<div className="flex flex-col pb-5 h-full">
	// 				<Skeleton className="w-full mb-3 h-[96px]" />
	// 				<Skeleton className="w-full mb-3 h-[96px]" />
	// 				<Skeleton className="w-full mb-3 h-[96px]" />
	// 				<Skeleton className="w-full mb-3 h-[96px]" />
	// 				<Skeleton className="w-full mb-3 h-[96px]" />
	// 				<Skeleton className="w-full mb-3 h-[96px]" />
	// 			</div>
	// 		</>
	// 	);
	// }

	return (
		<>
			<FadeIn style={{ height: '100%' }}>
				<>
					<StyledCourseFilters>
						<StyledCheckboxLabel htmlFor="showArchivedCheckbox">
							<StyledCheckbox
								type="checkbox"
								id="showArchivedCheckbox"
								checked={isArchived}
								onChange={(value) => {
									const isChecked = value.target.checked;
									const updatedSearchParams = new URLSearchParams(
										searchParams.toString()
									);
									if (isChecked) {
										updatedSearchParams.set('showArchived', 'true');
										setSearchParams(updatedSearchParams.toString());

										const archivedCourses = queryCourseData?.filter(
											(course) => course.courseArchivedColumn
										);
										setFilteredData(archivedCourses);
										return;
									}
									updatedSearchParams.set('showArchived', 'false');
									setSearchParams(updatedSearchParams.toString());

									setFilteredData(queryCourseData);
								}}
							/>
							{isArchived ? 'Hide Archived' : 'Show Archived'}
						</StyledCheckboxLabel>
						{queryCourseData && (
							<TableFilters
								data={queryCourseData}
								handleSetData={handleSetData}
								searchColumn={['courseColumn', 'courseDiscipline', 'courseTeacher']}
								filteredColumn="courseDivision"
								resultQty={filteredData?.length ?? 0}
							/>
						)}
					</StyledCourseFilters>
					{isLoading && (
						<div className="flex flex-col pb-5 h-full">
							<Skeleton className="w-full mb-3 h-[96px]" />
							<Skeleton className="w-full mb-3 h-[96px]" />
							<Skeleton className="w-full mb-3 h-[96px]" />
							<Skeleton className="w-full mb-3 h-[96px]" />
							<Skeleton className="w-full mb-3 h-[96px]" />
							<Skeleton className="w-full mb-3 h-[96px]" />
						</div>
					)}
					{!isLoading && filteredData && (
						<FadeIn style={{ height: '100%' }}>
							<Table data={filteredData} columns={columns} />
						</FadeIn>
					)}
				</>
			</FadeIn>
		</>
	);
};

export default Courses;
