import styled from 'styled-components';

import { media } from '@/Shared/theme';

const StyledIcon = styled.div`
	align-items: center;
	color: var(--label-text);
	cursor: pointer;
	display: flex;

	svg {
		transition: color 0.15s ease-in;
	}

	span {
		display: none;
		margin-left: var(--spacing-2);
		${media.condensed} {
			display: inline;
		}
	}

	path {
		fill: currentColor;
	}
`;

const KnowledgeBaseIcon = () => (
	<StyledIcon>
		<svg xmlns="http://www.w3.org/2000/svg" width="32" viewBox="0 0 512 512">
			<path d="M256 386.5a49.7 49.7 0 0 1-49.7-49.6v-18c0-44.7 28.3-84.8 70.2-99.6a61.7 61.7 0 1 0-82.3-58.2 49.7 49.7 0 0 1-99.2 0A161.7 161.7 0 0 1 266.8.3c31.2 2 61 13.1 86.2 32.2a7.5 7.5 0 0 1-9 12 144.8 144.8 0 0 0-78.2-29.3A146.7 146.7 0 0 0 110 161a34.7 34.7 0 0 0 69.3 0 77 77 0 0 1 82-76.5 76.8 76.8 0 0 1 20.3 149 90.8 90.8 0 0 0-60.3 85.5v17.9a34.7 34.7 0 0 0 69.4 0v-18c0-9 5.6-17.2 14-20.1a146.6 146.6 0 0 0 97-148.6 144.4 144.4 0 0 0-33.8-82.8 7.5 7.5 0 1 1 11.5-9.7c21.9 26.1 34.8 57.7 37.2 91.4a161.6 161.6 0 0 1-107 163.8c-2.3.9-4 3.3-4 6v18a49.7 49.7 0 0 1-49.6 49.6zM256 512c-3.5 0-7-.4-10.4-1.1a7.5 7.5 0 1 1 3.1-14.7 34.7 34.7 0 0 0 42-33.9v-.3a34.7 34.7 0 0 0-69.4 0v.3c0 6 1.6 11.8 4.4 16.9a7.5 7.5 0 1 1-13 7.3 49.7 49.7 0 0 1-6.4-24.2v-.3a49.7 49.7 0 0 1 99.4 0v.3A49.7 49.7 0 0 1 256 512z" />
		</svg>
		<span>Knowledge Base</span>
	</StyledIcon>
);

export default KnowledgeBaseIcon;
