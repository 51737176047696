import styled from 'styled-components';

export const StyledModalPanel = styled.div`
	background-color: var(--panel-color);
	bottom: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 300px;

	h5 {
		padding: var(--spacing-6) var(--spacing-8) var(--spacing-4);
	}

	.scroll-container {
		align-self: stretch;
		border-top: 1px solid #d8e0ec;
		display: flex;
		flex-direction: column;
		height: calc(100% - 130px);
		overflow-y: auto;
		padding: var(--spacing-6) var(--spacing-8) 0;
	}
`;

export const ScrollFooter = styled.div`
	background-color: #d8e0ec;
	border-top: 4px solid #d8e0ec;
	display: flex;
	padding: 0 var(--spacing-10);
	position: relative;
	width: 100%;
`;
