import styled from 'styled-components';

interface Typography {
	mt?: string;
	mr?: string;
	ml?: string;
	mb?: string;
}
interface VariedFont extends Typography {
	fontSize?: string;
	lineHeight?: string;
}
interface JumboText extends Typography {
	fontSize?: string;
	lineHeight?: string;
}

export const StyledH1 = styled.h1<Typography>`
	color: var(--header-text);
	font-size: 5.6rem;
	line-height: 72px;
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-10)'};
	margin-left: ${(props) => props.ml || 0};

	&.text-center {
		text-align: center;
	}
`;

export const StyledH2 = styled.h2<Typography>`
	color: var(--header-text);
	font-family: var(--font-semibold);
	font-size: 4.4rem;
	line-height: 56px;
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-10)'};
	margin-left: ${(props) => props.ml || 0};

	&.text-center {
		text-align: center;
	}
`;

export const StyledH3 = styled.h3<Typography>`
	color: var(--header-text);
	font-family: var(--font-semibold);
	font-size: 3.2rem;
	line-height: 40px;
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-10)'};
	margin-left: ${(props) => props.ml || 0};

	&.text-center {
		text-align: center;
	}
`;

export const StyledH4 = styled.h4<Typography>`
	&:not([class*='text-']) {
		color: var(--header-text);
	}
	/* color: var(--header-text); */
	font-family: var(--font-semibold);
	font-size: 2.4rem;
	line-height: 32px;
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-10)'};
	margin-left: ${(props) => props.ml || 0};

	&.text-center {
		text-align: center;
	}
`;

export const StyledH5 = styled.h5<Typography>`
	color: var(--header-text);
	font-family: var(--font-semibold);
	font-size: 1.8rem;
	line-height: 24px;
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-10)'};
	margin-left: ${(props) => props.ml || 0};

	&.text-center {
		text-align: center;
	}
`;

export const StyledH6 = styled.h6<Typography>`
	color: var(--header-text);
	font-family: var(--font-semibold);
	font-size: 1.6rem;
	line-height: 20px;
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-10)'};
	margin-left: ${(props) => props.ml || 0};

	&.text-center {
		text-align: center;
	}
`;

export const StyledSubHeader = styled.div<VariedFont>`
	color: var(--header-text);
	font-family: var(--font-regular);
	font-size: ${(props) => props.fontSize || '1.6rem'};
	line-height: 20px;
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-6)'};
	margin-left: ${(props) => props.ml || 0};

	&.text-center {
		text-align: center;
	}
`;

export const StyledJumboText = styled.div<JumboText>`
	color: var(--header-text);
	font-family: var(--font-regular);
	font-weight: bold;
	font-size: ${(props) => props.fontSize || '10.6rem'};
	line-height: ${(props) => props.lineHeight || '11.6rem'};
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-6)'};
	margin-left: ${(props) => props.ml || 0};

	&.text-center {
		text-align: center;
	}
`;

export const StyledP = styled.p<VariedFont>`
	color: var(--body-text);
	font-family: var(--font-regular);
	font-size: ${(props) => props.fontSize || '1.6rem'};
	line-height: 25px;
	margin-top: ${(props) => props.mt || 0};
	margin-right: ${(props) => props.mr || 0};
	margin-bottom: ${(props) => props.mb || 'var(--spacing-4)'};
	margin-left: ${(props) => props.ml || 0};
`;
