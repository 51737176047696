import { useContext, useEffect, useRef } from 'react';

import FadeIn from '@/components/animations/FadeIn';
import Loader from '@/components/Loader';
import { ToastNotification } from '@/components/ToastNotification';
import GlobalContext from '@/context/GlobalContext';
import { useCurrentWidth } from '@/hooks/useCurrentWidth';
import { SettingsIcon, ExpandIcon } from '@/icons/index';
import { GlobalAlignmentStyle, StyledCheckbox, StyledCheckboxLabel } from '@/Shared/StyledElements';
import { StyledH5 } from '@/Shared/Typography/typography';
import { useAlignmentStore } from '@/stores/alignmentStore';

import { Skeleton } from '../ui/skeleton';

import { useAllAlignmentCourses, useCourseAlignment } from './AlignmentContainer';
import { StyledContainer, StyledCourseAlignment, StyledLegend } from './CourseAlignmentStyles';
import AlignmentSearch from './sub-components/AlignmentSearch/AlignmentSearch';
import CardDeck from './sub-components/CardDeck';

function CourseAlignment() {
	const alignmentRef = useRef(document.createElement('div'));

	const {
		data: alignmentCourses,
		isLoading: alignmentCoursesLoading,
		isFetching: alignmentCoursesFetching,
	} = useAllAlignmentCourses();

	const { data: mapData } = useCourseAlignment();

	const {
		details,
		disciplines,
		divisions,
		toggleBoolean,
		updateDisciplines,
		updateDivisions,
		toggleAllDisciplines,
		updateAlignmentCourses,
		updateMapData,
	} = useAlignmentStore();

	const {
		disciplines: disciplineData,
		divisions: divisionData,
		siteSettings,
	} = useContext(GlobalContext);

	const toggleTags = () => {
		const alignment = document?.querySelector('.course-alignment');
		if (alignment?.classList.contains('hidden-tags')) {
			alignment?.classList.remove('hidden-tags');
		} else {
			alignment?.classList.add('hidden-tags');
		}
	};

	// This function takes an array of any type as input and returns a flattened array.
	function flattenArray<T>(arr: T[]): T[] {
		const result: any = [];

		if (!Array.isArray(arr)) {
			return result;
		}

		function flatten(item: any) {
			result.push(item);
			if (item.children && item.children.length > 0) {
				for (const child of item.children) {
					flatten(child);
				}
			}
		}

		for (const item of arr) {
			flatten(item);
		}

		return result;
	}

	const hasAPCourses = flattenArray(mapData?.children)?.some((x: any) => x.isAp);

	const width = useCurrentWidth();
	const legendRef = useRef<any>(null);

	useEffect(() => {
		if (alignmentCourses) {
			updateAlignmentCourses(alignmentCourses);
		}
		if (mapData) {
			updateMapData(mapData);
		}
	}, [alignmentCourses, updateAlignmentCourses, updateMapData, mapData]);

	useEffect(() => {
		if (width > 900) {
			const elm = alignmentRef.current;
			elm?.classList.add('active-legend');
		}
		if (width <= 900) {
			const container = document.querySelector('.alignment-container');
			if (container) {
				container.scrollTop = 0;
			}

			const elm = alignmentRef.current;
			elm?.classList.remove('active-legend');
		}
	}, [width]);

	const toggleLegend = () => {
		const elm = alignmentRef.current;
		if (elm?.className.includes('active-legend')) {
			elm?.classList.remove('active-legend');
		} else {
			elm?.classList.add('active-legend');
		}
	};

	if (alignmentCoursesLoading) {
		return (
			<div className="flex flex-row pb-5 pt-8 h-full gap-4">
				<Skeleton className="h-full w-full max-w-[275px]" />
				<div className="h-full w-full grid gap-4 grid-cols-3">
					<Skeleton className="h-full w-full" />
					<Skeleton className="h-full w-full" />
					<Skeleton className="h-full w-full" />
					<Skeleton className="h-full w-full" />
					<Skeleton className="h-full w-full" />
					<Skeleton className="h-full w-full" />
				</div>
			</div>
		);
	}

	return (
		<>
			<StyledCourseAlignment ref={alignmentRef} className="course-alignment active-legend">
				<GlobalAlignmentStyle />
				{!alignmentCoursesFetching &&
					!alignmentCoursesLoading &&
					mapData &&
					disciplineData &&
					divisionData && (
						<>
							<div className="button-group">
								<SettingsIcon className="toggle-btn" passedEvent={toggleLegend} />
								<ExpandIcon className="expand-btn" />
							</div>
							<FadeIn id="legend--intro" className="fade-wrapper">
								<StyledLegend ref={legendRef} className="legend--intro">
									<>
										<StyledH5 mb="16px">Disciplines</StyledH5>
										{import.meta.env.VITE_ORG !== 'Urban Community' && (
											<StyledCheckboxLabel
												htmlFor="selectAllToggle"
												onChange={toggleAllDisciplines}>
												<StyledCheckbox
													isOrg
													type="checkbox"
													id="selectAllToggle"
													defaultChecked
												/>
												Select All
											</StyledCheckboxLabel>
										)}
										{disciplines.map((d) => {
											const { id, name } = d;

											return (
												<StyledCheckboxLabel key={id}>
													<StyledCheckbox
														isOrg
														type="checkbox"
														onChange={() => updateDisciplines(d)}
														checked={d.isSelected}
													/>
													{name}
												</StyledCheckboxLabel>
											);
										})}
										<hr />
										<StyledH5 mb="16px">Division</StyledH5>
										{hasAPCourses && (
											<StyledCheckboxLabel
												htmlFor="apCheckbox"
												onChange={() => toggleBoolean('showApOnly')}>
												<StyledCheckbox
													isOrg
													type="checkbox"
													id="apCheckbox"
													defaultChecked={details.showApOnly}
												/>
												AP Only
											</StyledCheckboxLabel>
										)}
										{divisions.map((d) => {
											const { id, name } = d;

											return (
												<StyledCheckboxLabel key={id}>
													<StyledCheckbox
														isOrg
														disabled={details.showApOnly}
														type="checkbox"
														onChange={() => updateDivisions(d)}
														checked={d.isSelected}
													/>
													{name}
												</StyledCheckboxLabel>
											);
										})}
										<hr />
										<StyledH5 mb="16px" mt="16px">
											Options
										</StyledH5>
										<StyledCheckboxLabel
											htmlFor="tagToggle"
											onChange={toggleTags}>
											<StyledCheckbox
												isOrg
												type="checkbox"
												id="tagToggle"
												defaultChecked
											/>
											Show Tags
										</StyledCheckboxLabel>
										{[
											{ name: 'Description', label: 'Description' },
											{ name: 'Outcomes', label: 'Outcomes' },
											{ name: 'Resources', label: 'Resources' },
											{ name: 'Units', label: 'Units of Focus' },
										].map((item: any) => {
											const { name, label } = item;
											return (
												<StyledCheckboxLabel
													key={name}
													htmlFor={`${name}Toggle`}
													onChange={() =>
														toggleBoolean(`show${name}` as any)
													}>
													<StyledCheckbox
														isOrg
														type="checkbox"
														id={`${name}Toggle`}
														defaultChecked
													/>
													{label}
												</StyledCheckboxLabel>
											);
										})}
									</>
								</StyledLegend>
							</FadeIn>
							<div className="search-row">
								<AlignmentSearch />
							</div>
						</>
					)}
				<StyledContainer
					image={siteSettings?.logo ?? ''}
					className="alignment-container"
					isLoading={alignmentCoursesFetching}>
					{alignmentCoursesFetching && (
						<Loader color="var(--org-color)" ringColor="var(--org-color)" />
					)}
					{!alignmentCoursesFetching &&
						!alignmentCoursesLoading &&
						mapData &&
						disciplineData &&
						mapData?.children?.map((c: any) => {
							return (
								<CardDeck
									key={`${c.id}-${c.tracker}`}
									course={c}
									selectedDiscipline={disciplines}
									coursesArray={alignmentCourses}
									selectedDivision={divisions}
									details={details}
									isAP={details.showApOnly}
								/>
							);
						})}
				</StyledContainer>
			</StyledCourseAlignment>
			<ToastNotification />
		</>
	);
}

export default CourseAlignment;
