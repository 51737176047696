import { useState, FormEvent, useContext } from 'react';

import { useLocation } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import useConfirmationDialog from '@/hooks/useConfirmationModal';
import useSaveDraft from '@/hooks/useSaveDraft';
import { StyledTextArea } from '@/Shared/StyledElements';
import { useCourseEditStore, useCourseStore } from '@/stores/courseEditStore';

import CourseEditContext from '../CourseEditContext';

import OptionMenu from './OptionMenu';
import { updateComment, renderDate, removeComment } from './utils';

interface CourseThreadItemProps {
	comment: UserComment;
	reply: UserComment;
	courseId: string;
	userId: string;
	userRole: string;
}

const CourseThreadItem = ({
	reply,
	comment,
	courseId,
	userId,
	userRole,
}: CourseThreadItemProps) => {
	const [isEditing, setIsEditing] = useState(false);
	const { editorList: courseEditorList } = useCourseEditStore();
	const { editorList: submissionEditorList } = useCourseStore();
	const { currentUserData } = useContext(CourseEditContext);
	const { pathname } = useLocation();
	const editorList: any = pathname.includes('submissions')
		? submissionEditorList
		: courseEditorList;

	const { saveDraft, updateCourseReducer } = useContext(CourseEditContext);

	const handleCommentUpdate = async (
		e: FormEvent<HTMLButtonElement>,
		reply: UserComment,
		comment: UserComment,
		courseId: string
	) => {
		await updateComment(e, reply, comment, courseId);

		setIsEditing(false);
	};

	const getMessage = () => {
		if (reply.thread?.length > 0) {
			return 'Are you sure you want to delete this comment and the following thread?';
		}

		return 'Are you sure you want to delete this comment?';
	};

	const [isCommented, setIsCommented] = useState(false);
	// once a comment is added, state is updated and the draft is saved
	useSaveDraft(isCommented, saveDraft, setIsCommented);

	const confirm = useConfirmationDialog({
		message: getMessage(),
		onConfirm: () => {
			removeComment(comment, reply, courseId, editorList);
			const currentEditor = editorList[comment.editorType];
			if (currentEditor) {
				updateCourseReducer(currentEditor.getHTML(), comment.editorType);
				setIsCommented(!isCommented);
			}
		},
		onCancel: () => null,
	});

	const createOptionList = () => {
		if (comment.userId === userId || userRole === 'admin') {
			return [
				{
					label: 'Edit',
					onClick: () => setIsEditing(true),
					id: `${reply.id}-edit`,
				},
				{
					label: 'Delete',
					onClick: () => confirm.onOpen(comment),
					id: `${reply.id}-delete`,
				},
			];
		}
		return [];
	};

	const isOptionMenuVisible =
		currentUserData?.user_id === reply.userId || currentUserData?.user_role === 'admin';

	return (
		<>
			{confirm.Dialog()}
			<div className="comment-head">
				<span>{reply.userName}</span>
				{isOptionMenuVisible && <OptionMenu options={createOptionList()} />}
			</div>
			<time>{renderDate(reply.documentCreatedAt.toDate())}</time>
			{!isEditing && (
				<div>
					{reply.text}
					{reply.isEdited && <small>(Edited)</small>}
				</div>
			)}
			{isEditing && (
				<form>
					<StyledTextArea
						placeholder="Reply to comment..."
						defaultValue={reply.text}
						name="comment"
					/>
					<Button
						type="button"
						onClick={(e) => handleCommentUpdate(e, reply, comment, courseId)}
						className="mr-2"
						size="xs">
						Save
					</Button>
					<Button
						variant="secondary"
						type="button"
						onClick={() => setIsEditing(false)}
						className="cancel-update-btn"
						size="xs">
						Cancel
					</Button>
				</form>
			)}
		</>
	);
};

export default CourseThreadItem;
