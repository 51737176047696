import React from 'react';

import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
	@media (prefers-reduced-motion: no-preference) {
		animation-name: ${fadeIn};
		animation-fill-mode: backwards;
	}
`;

interface FadeInProps {
	duration?: number;
	delay?: number;
	children: React.ReactNode;
	[key: string]: any;
}

const FadeIn = ({ duration = 300, delay = 100, children, ...delegated }: FadeInProps) => (
	<Wrapper
		{...delegated}
		style={{
			...(delegated.style || {}),
			animationDuration: `${duration}ms`,
			animationDelay: `${delay}ms`,
		}}>
		{children}
	</Wrapper>
);

export default FadeIn;
