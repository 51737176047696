import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { GetAllDisciplinesQuery, GetAllDivisionsQuery } from '@/graphql/graphql';
import { courseGrades } from '@/Shared/Data/StaticData';
import { StyledCheckboxLabel, StyledCheckbox } from '@/Shared/StyledElements';
import { themeSelectStyles } from '@/utils';

import { FormErrorMessage } from '../FormErrorMessage';

import { StyledPanel } from './CreateNewCoursePanelStyles';
import { addNewCourse } from './mutations';

interface CreateNewCoursePanelProps {
	closeAccordion: () => void;
	divisions: GetAllDivisionsQuery['dev_divisions'];
	disciplines: GetAllDisciplinesQuery['dev_disciplines'];
}

interface FormValues {
	disciplineId: string;
	divisionId: string;
	courseName: string;
	grade: string;
	isAp: boolean;
}

const CreateNewCoursePanel = ({
	closeAccordion,
	divisions,
	disciplines,
}: CreateNewCoursePanelProps) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm<FormValues>();
	const queryClient = useQueryClient();

	const { mutate: newCourseMutate, status } = useMutation({
		mutationFn: addNewCourse,
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: ['get-admin-panel-courses'],
			});
			showSuccessToast('New Course Added');
			reset();
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const isUnique = (courseName: string) => {
		const previousCourseData = queryClient.getQueryData(['get-admin-panel-courses']) as Partial<
			Course[]
		>;
		const isCourseNameUnique = previousCourseData.some(
			(c) => c?.course_name === courseName.trim()
		);
		return isCourseNameUnique;
	};

	const formattedList = (
		list: GetAllDivisionsQuery['dev_divisions'] | GetAllDisciplinesQuery['dev_disciplines']
	) => {
		return list.map((item) => {
			return {
				value: item?.name,
				label: item.name,
				id: item.id,
			};
		});
	};

	const onSubmit: SubmitHandler<FormValues> = async (courseFields) => {
		const { disciplineId, divisionId, courseName, isAp, grade } = courseFields;
		newCourseMutate({
			disciplineId,
			divisionId,
			courseName: courseName.trim(),
			grade,
			isAp,
		});
	};

	return (
		<StyledPanel>
			<form onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="editCourseName">Name</label>
				<Input
					type="text"
					id="editCourseName"
					style={{ marginBottom: 'var(--spacing-1)' }}
					placeholder="Course Name"
					{...register('courseName', {
						required: 'Course name is required',
						validate: (value) => {
							if (isUnique(value)) {
								return 'This course name is already taken';
							}
							return true;
						},
					})}
				/>
				<FormErrorMessage
					className="error-message"
					isShowing={!!errors?.courseName}
					message={errors?.courseName?.message}
				/>
				{divisions?.length !== 0 && (
					<Controller
						control={control}
						name="divisionId"
						defaultValue={divisions[0].id}
						render={({ field }) => (
							<>
								<span className="label">Division</span>
								<AsyncSelect
									styles={themeSelectStyles}
									placeholder="Division"
									defaultInputValue={divisions[0]?.name ?? ''}
									onChange={({ id }) => {
										field.onChange(id);
									}}
									isClearable={false}
									loadOptions={(inputValue) => {
										return new Promise((resolve) => {
											const array = formattedList(divisions);
											const filterResults = array.filter((x) =>
												x?.value
													?.toLowerCase()
													.includes(inputValue.toLowerCase())
											);
											resolve(filterResults);
										});
									}}
									defaultOptions={formattedList(divisions)}
									noOptionsMessage={() => 'No Divisions Found'}
								/>
							</>
						)}
					/>
				)}
				{disciplines?.length !== 0 && (
					<Controller
						control={control}
						name="disciplineId"
						defaultValue={disciplines[0].id}
						render={({ field }) => (
							<>
								<span className="label">Discipline</span>
								<AsyncSelect
									styles={themeSelectStyles}
									placeholder="Discipline"
									defaultInputValue={disciplines[0].name ?? ''}
									onChange={({ id }) => {
										field.onChange(id);
									}}
									isClearable={false}
									loadOptions={(inputValue) => {
										return new Promise((resolve) => {
											const array = formattedList(disciplines);
											const filterResults = array.filter((x) =>
												x?.value
													?.toLowerCase()
													.includes(inputValue.toLowerCase())
											);
											resolve(filterResults);
										});
									}}
									defaultOptions={formattedList(disciplines)}
									noOptionsMessage={() => 'No Disciplines Found'}
								/>
							</>
						)}
					/>
				)}
				<Controller
					control={control}
					name="grade"
					defaultValue={courseGrades[0].value}
					render={({ field }) => (
						<div style={{ marginBottom: 'var(--spacing-4)' }}>
							<span className="label">Grade</span>
							<AsyncSelect
								styles={themeSelectStyles}
								placeholder="Grade"
								defaultInputValue={field.value}
								onChange={({ value }) => field.onChange(value)}
								isClearable={false}
								loadOptions={(inputValue) => {
									return new Promise((resolve) => {
										const array = courseGrades;
										const filterResults = array.filter((x) =>
											x.value.toLowerCase().includes(inputValue.toLowerCase())
										);
										resolve(filterResults);
									});
								}}
								defaultOptions={courseGrades}
								noOptionsMessage={() => 'No Grades Found'}
							/>
						</div>
					)}
				/>
				<StyledCheckboxLabel htmlFor="panelAp">
					<StyledCheckbox
						type="checkbox"
						id="panelAp"
						defaultChecked={false}
						{...register('isAp')}
					/>
					Is AP
				</StyledCheckboxLabel>
				<Button
					type="submit"
					disabled={status === 'pending'}
					isLoading={status === 'pending'}
					onClick={handleSubmit(onSubmit)}
					size="small">
					Save
				</Button>
				<Button type="button" variant="secondary" onClick={closeAccordion} size="small">
					Close
				</Button>
			</form>
		</StyledPanel>
	);
};

export default CreateNewCoursePanel;
