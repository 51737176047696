import { SetStatus } from '@/Enums/enum';

interface StatusProps {
	approvalOne: Lowercase<keyof typeof SetStatus>;
	approvalTwo: Lowercase<keyof typeof SetStatus>;
}

const StatusCircle = ({ approvalOne, approvalTwo }: StatusProps) => {
	const currentFill: Record<SetStatus, string> = {
		[SetStatus.APPROVED]: 'var(--green-400)',
		[SetStatus.REJECTED]: 'var(--yellow-300)',
		[SetStatus.WAITING]: 'var(--c-silver)',
	};

	return (
		<div className="flex justify-center">
			<svg width="28" height="28" viewBox="-5 0 144 136" xmlns="http://www.w3.org/2000/svg">
				<path
					fill={currentFill[approvalOne]}
					stroke={currentFill[approvalOne]}
					d="M67.5,0.50181315 L67.5,135.498187 C49.0587291,135.364402 32.3760749,127.83549 20.2702923,115.729708 C8.05519485,103.51461 0.5,86.6396103 0.5,68 C0.5,49.3603897 8.05519485,32.4853897 20.2702923,20.2702923 C32.3760749,8.16450959 49.0587291,0.635598353 67.5,0.50181315 L67.5,0.50181315 Z"
				/>
				<path
					fill={currentFill[approvalTwo]}
					stroke={currentFill[approvalTwo]}
					d="M135.5,0.50181315 L135.5,135.498187 C117.058729,135.364402 100.376075,127.83549 88.2702923,115.729708 C76.0551948,103.51461 68.5,86.6396103 68.5,68 C68.5,49.3603897 76.0551948,32.4853897 88.2702923,20.2702923 C100.376075,8.16450959 117.058729,0.635598353 135.5,0.50181315 L135.5,0.50181315 Z"
					transform="translate(102.000000, 68.000000) scale(-1, 1) translate(-102.000000, -68.000000) "
				/>
			</svg>
		</div>
	);
};

export default StatusCircle;
