import { Navigate, Routes, Route, BrowserRouter, useLocation } from 'react-router-dom';
import { injectStyle } from 'react-toastify/dist/inject-style';
import styled from 'styled-components';

import AdminNavbar from '@/components/Navbar/AdminNavbar';
import Panel from '@/components/Panel';
import SidebarNav from '@/components/SidebarNav';
import TaggedWordTooltip from '@/components/TaggedWordTooltip/TaggedWordTooltip';
import { GlobalContextProvider } from '@/context/GlobalContext';
import { useSession } from '@/hooks/useAuth';
import { NotFound, ResetPassword, SignIn, ParentMap, Privacy, SetPassword } from '@/Pages/index';
import ParentAlignment from '@/Pages/ParentAlignment/ParentAlignment';

import { ErrorBoundary } from './ErrorBoundary';

const StyledDashboard = styled.div`
	position: relative;
	display: grid;
	grid-template-areas:
		'nav nav'
		'sidebar panel';
	grid-template-columns: auto 1fr;
	grid-template-rows: auto 1fr 40px;
	height: 100vh;
`;
interface PrivateRouteProps {
	children: JSX.Element;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
	const user = useSession();
	const location = useLocation();

	return user ? children : <Navigate to="/login" state={{ from: location.pathname }} />;
};

const Admin = () => (
	<StyledDashboard>
		<AdminNavbar />
		<SidebarNav />
		<Panel />
	</StyledDashboard>
);

function App() {
	injectStyle();

	return (
		<ErrorBoundary>
			<GlobalContextProvider>
				<BrowserRouter>
					<Routes>
						<Route path="/login" element={<SignIn />} />
						<Route path="/privacy-policy" element={<Privacy />} />
						<Route path="/" element={<ParentAlignment />} />
						<Route path="/reset-password" element={<ResetPassword />} />
						<Route path="/set-password" element={<SetPassword />} />
						<Route path="/course-map" element={<ParentMap />} />
						<Route
							path="/dashboard/*"
							element={
								<PrivateRoute>
									<Admin />
								</PrivateRoute>
							}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</BrowserRouter>
				<TaggedWordTooltip />
			</GlobalContextProvider>
		</ErrorBoundary>
	);
}

export default App;
