import styled from 'styled-components';

import { media } from '@/Shared/theme';

const StyledIcon = styled.div`
	align-items: center;
	color: var(--label-text);
	cursor: pointer;
	display: flex;

	svg {
		transition: color 0.15s ease-in;
	}

	span {
		display: none;
		margin-left: var(--spacing-2);
		${media.condensed} {
			display: inline;
		}
	}
`;

const MapIcon = () => (
	<StyledIcon>
		<svg
			width="34"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512">
			<path d="M256 93.3c-14 0-27.5 4.2-39 12.2a7.6 7.6 0 00-2 10.5c2.4 3.4 7 4.3 10.5 2 9-6.3 19.5-9.6 30.5-9.6a54.2 54.2 0 010 108.7 54.2 54.2 0 01-47.9-79.4c1.9-3.7.5-8.3-3.2-10.2-3.7-1.9-8.2-.5-10 3.2A69.3 69.3 0 00256 232.1c37.9 0 68.8-31 68.8-69.4 0-38.3-30.9-69.4-68.9-69.4z" />
			<path d="M511.4 501.6l-73.8-178.7a7.5 7.5 0 00-6.9-4.6h-73.5c14.3-19.5 26.2-39 35.5-58a223.6 223.6 0 0024.8-97.6C417.5 73 345.1 0 256 0S94.5 73 94.5 162.7c0 31 8.3 63.8 24.8 97.6 9.3 19 21.2 38.5 35.5 58H81.3c-3 0-5.8 1.8-7 4.6L24.2 444.7A7.6 7.6 0 0031 455c3 0 5.7-1.8 6.9-4.7l7.2-17.3H96l-26.4 63.8h-51l7.7-18.5a7.6 7.6 0 00-4-9.8 7.5 7.5 0 00-9.8 4l-12 29c-.9 2.3-.6 5 .8 7a7.5 7.5 0 006.2 3.4h497a7.6 7.6 0 006.9-10.4zm-402-338.9c0-81.4 65.8-147.6 146.6-147.6s146.6 66.2 146.6 147.6c0 34.2-11.8 88.7-66.3 158.3l-.1.2-8.7 10.7c-30 36-60.2 62.4-71.5 71.8a574.4 574.4 0 01-80.2-82.6l-.1-.1c-54.5-69.6-66.3-124.1-66.3-158.3zM86.3 333.3h50.9l-18.6 45H67.7l18.6-45zM130 497l46.7-113.1 133 113.1H130zm203 0L178.5 365.6a7.4 7.4 0 00-11.7 2.9l-53.1 128.4h-28l28.3-68.5c1-2.3.7-5-.7-7a7.5 7.5 0 00-6.2-3.4H51.3l10.2-24.6h62.1c3 0 5.7-1.9 6.9-4.7l22.9-55.4h13l6.8 8.5a564.2 564.2 0 0078.2 77.5 7.4 7.4 0 009.2 0c.9-.7 12.7-10 29.6-26H375c4.2 0 7.5-3.3 7.5-7.5s-3.3-7.5-7.5-7.5h-69.2a578.2 578.2 0 0040-45h80l18.6 45h-39.5c-4 0-7.4 3.4-7.4 7.5s3.3 7.6 7.4 7.6h45.7l10.2 24.6h-154c-3.2 0-6 2-7 4.9-1.2 3-.3 6.3 2 8.3L377 497h-44zm66.7 0l-73-63.8h140.2l26.4 63.8h-93.6z" />
		</svg>
		<span>Course Map</span>
	</StyledIcon>
);

export default MapIcon;
