import colorJS from 'color';
import styled from 'styled-components';

const StyledTag = styled.div.attrs(({ color = '#000' }) => ({
	style: {
		backgroundColor: `${colorJS(color).fade(0.8)}`,
		color: `${color}`,
	},
}))`
	border-radius: 6px;
	display: flex;
	display: inline-block;
	font-family: var(--font-bold);
	font-size: 14px;
	margin-right: var(--spacing-2);
	padding: 6px var(--spacing-4);
`;

interface TagProps {
	color: string;
	text: string;
	className?: any;
}

const Tag = ({ className = 'tag', color = '#000', text }: TagProps) => {
	return (
		<StyledTag className={className} color={color}>
			{text}
		</StyledTag>
	);
};

export default Tag;
