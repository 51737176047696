import { useContext, useEffect, useState } from 'react';

import debounce from 'just-debounce-it';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Controller, FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { StrictModeDroppable } from '@/components/StrictModeDroppable';
import TiptapEditor from '@/components/TipTapEditor/TiptapEditor';
import { EditorType } from '@/Enums/enum';
import useSaveDraft from '@/hooks/useSaveDraft';
import { DragIcon, TrashCanIcon } from '@/icons/index';
import { StyledH6, StyledP } from '@/Shared/Typography/typography';
import { useCourseEditStore } from '@/stores/courseEditStore';
import { DropResultEntity } from '@/types/source';

import CourseDiff from '../CourseDiff';
import CourseEditContext from '../CourseEditContext';

import CourseButtonGroup from './CourseButtonGroup';
import CourseSubs from './CourseSubs';
import { StyledUnit, StyledUnitDetails, StyledUnitGroup } from './CourseUnits.Styles';

interface CourseUnitsFormProps {
	register: UseFormRegister<FieldValues>;
	inputFocusRef: { current: boolean };
}

const CourseUnitsForm = ({ register, inputFocusRef }: CourseUnitsFormProps) => {
	const {
		removeFocus,
		removeSkill,
		canEdit,
		courseData,
		courseDraftData,
		isApprovalMode,
		updateFocusOrder,
		saveDraft,
		updateFocus,
	} = useContext(CourseEditContext);
	const { control } = useForm();
	const coursesFocuses = courseData.courses_focuses as CourseFocusEntity[];
	const { courses_focuses: coursesDraftFocuses } = courseDraftData;

	const { dynamicDoc, docTypes, dynamicProvider, providers, updateCommentTypes } =
		useCourseEditStore();

	const removeSetFocus = (id: string) => {
		removeFocus(id);
		inputFocusRef.current = false;
	};

	const renderFocusChildren = (focus: CourseFocusEntity | undefined) => {
		const getChildren = (skill: FocusSkillEntity[]) => {
			if (skill?.length === 0 || !skill) {
				return '';
			}
			return skill?.map((item: FocusSkillEntity) => `${item.skill.skill_text}</br>`);
		};

		const filterTypes = (entity: FocusSkillEntity[] | undefined, type: string) => {
			return entity?.filter((unit) => unit.skill.skill_type === type) ?? [];
		};

		if (!focus?.focus) return '';

		const question = filterTypes(focus?.focus?.focuses_skills, 'question');
		const skill = filterTypes(focus?.focus?.focuses_skills, 'skill');
		const topic = filterTypes(focus?.focus?.focuses_skills, 'topic');

		const results = `
		Title: </br>${focus?.focus?.focus_title ?? ''}</br></br>
		Questions: </br>${getChildren(question)}</br>
		Skills: </br>${getChildren(skill)}</br>
		Topics: </br>${getChildren(topic)}</br>`;

		return results;
	};

	const updateUnitTitle = debounce((parentId: string, value: string) => {
		const idx = coursesDraftFocuses?.findIndex((item) => item.courses_focuses_id === parentId);
		if (idx !== undefined && coursesDraftFocuses) {
			const clone = [...coursesDraftFocuses];
			clone[idx].focus.focus_title = value;
			updateFocus(clone);
		}
	}, 300);

	const [isCommented, setIsCommented] = useState(false);
	// once a comment is added, state is updated and the draft is saved
	const saveAfterCommenting = (parentId: string, value: string) => {
		const idx = coursesDraftFocuses?.findIndex((item) => item.courses_focuses_id === parentId);
		if (idx !== undefined && coursesDraftFocuses) {
			const clone = [...coursesDraftFocuses];
			clone[idx].focus.focus_title = value;
			updateFocus(clone);
		}
		setIsCommented(!isCommented);
	};
	// once a comment is added, save a draft using hook
	useSaveDraft(isCommented, saveDraft, setIsCommented);

	const onDragEnd = (result: DropResultEntity) => {
		const focusesClone = [...(coursesDraftFocuses ?? [])];
		updateFocusOrder(focusesClone, result);
	};

	useEffect(() => {
		coursesDraftFocuses?.forEach((focus) => {
			const id = focus.courses_focuses_id;
			dynamicDoc(id, EditorType.UNITS);
			dynamicProvider(id, EditorType.UNITS);
		});
		updateCommentTypes([EditorType.UNITS]);
	}, [dynamicDoc, dynamicProvider, coursesDraftFocuses, updateCommentTypes]);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<StrictModeDroppable droppableId={uuidv4()}>
				{(provided) => (
					<StyledUnitGroup ref={provided.innerRef} {...provided.droppableProps}>
						{(coursesDraftFocuses ?? [])
							.sort(
								(a: CourseFocusEntity, b: CourseFocusEntity) =>
									a.focus.focus_order - b.focus.focus_order
							)
							.map((unit: CourseFocusEntity, unitIdx: number) => {
								const { courses_focuses_id: courseFocusId } = unit;
								const {
									focus_title: focusTitle,
									focuses_skills: focusSkills,
									focus_order: focusOrder,
									focus_id: focusId,
								} = unit.focus;
								return (
									<Draggable
										isDragDisabled={isApprovalMode || !canEdit}
										draggableId={focusId}
										index={unitIdx}
										key={focusId}>
										{(dragProvider, snapshot) => (
											<StyledUnit
												data-testid={`unit-of-focus-${unitIdx}`}
												{...dragProvider.draggableProps}
												className={snapshot.isDragging ? 'dragging' : ''}
												ref={dragProvider.innerRef}>
												{!isApprovalMode && canEdit && (
													<div
														className="drag-icon-wrapper"
														{...dragProvider.dragHandleProps}>
														<DragIcon />
													</div>
												)}
												<div className="single-unit">
													{!isApprovalMode && (
														<>
															{docTypes[
																`${courseFocusId}${EditorType.UNITS}`
															] && (
																<>
																	<Controller
																		control={control}
																		name={`title-${focusId}`}
																		defaultValue={focusTitle}
																		render={({ field }) => {
																			return (
																				<TiptapEditor
																					content={
																						field.value
																					}
																					onChange={(
																						e
																					) => {
																						updateUnitTitle(
																							courseFocusId,
																							e
																						);
																					}}
																					placeholder="Add course unit of focus..."
																					type={`${EditorType.UNITS}-${courseFocusId}`}
																					isEditable={
																						canEdit ||
																						!isApprovalMode
																					}
																					id={
																						courseFocusId
																					}
																					commentAddedCallback={(
																						value
																					) =>
																						saveAfterCommenting(
																							courseFocusId,
																							value
																						)
																					}
																					providerRef={
																						providers[
																							`${courseFocusId}${EditorType.UNITS}`
																						]
																					}
																					documentRef={
																						docTypes[
																							`${courseFocusId}${EditorType.UNITS}`
																						]
																					}
																				/>
																			);
																		}}
																	/>
																</>
															)}
															<input
																readOnly
																hidden
																defaultValue={JSON.stringify({
																	focusId,
																	courseFocusId,
																	focusTitle: `${coursesDraftFocuses?.[unitIdx].focus.focus_title}`,
																	focusOrder: focusOrder ?? 0,
																})}
																{...register(
																	`focusBlob-${focusId}`
																)}
															/>
														</>
													)}
													{canEdit && !isApprovalMode && (
														<TrashCanIcon
															passedEvent={() =>
																removeSetFocus(courseFocusId)
															}
														/>
													)}
												</div>
												<CourseButtonGroup
													courseFocusId={courseFocusId}
													inputFocusRef={inputFocusRef}
												/>
												<div className="diff-container">
													<CourseDiff
														title="Title"
														original={renderFocusChildren(
															coursesFocuses[unitIdx]
														)}
														draft={renderFocusChildren(
															coursesDraftFocuses?.[unitIdx]
														)}
													/>
												</div>
												<StyledUnitDetails>
													{!isApprovalMode &&
														focusSkills?.length !== 0 && (
															<StyledH6 ml="16px" mb="16px">
																Highlights
															</StyledH6>
														)}
													{focusSkills?.map((skill: FocusSkillEntity) => {
														const { focuses_skills_id: focusSkillId } =
															skill;
														const {
															skill_text: skillText,
															skill_id: skillId,
															skill_type: skillType,
														} = skill.skill;

														const courseSubProps = {
															courseFocusId,
															focusSkillId,
															skillId,
															skillText,
															skillType,
															isApprovalMode,
															canEdit,
															removeSkill,
															register,
															inputFocusRef,
														};

														return (
															<CourseSubs
																key={`skill-${focusSkillId}-${skillId}`}
																{...courseSubProps}
															/>
														);
													})}
												</StyledUnitDetails>
												<input
													readOnly
													hidden
													value={courseFocusId}
													{...register(`id-${focusId}`)}
												/>
											</StyledUnit>
										)}
									</Draggable>
								);
							})}
						{provided.placeholder}
						{isApprovalMode && coursesDraftFocuses?.length === 0 && (
							<StyledP mb="32px">No units of focus present.</StyledP>
						)}
					</StyledUnitGroup>
				)}
			</StrictModeDroppable>
		</DragDropContext>
	);
};

export default CourseUnitsForm;
