const SearchIcon = ({ classList }: { classList?: string }) => (
	<svg
		width="16"
		viewBox="0 1 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={classList}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.999 10.002a5 5 0 10-.995.995.75.75 0 00.151.217l3.44 3.44a.75.75 0 001.06-1.06l-3.439-3.44a.747.747 0 00-.217-.152zM7 11a4 4 0 100-8 4 4 0 000 8z"
			fill="#8F8E94"
		/>
	</svg>
);

export default SearchIcon;
