import styled from 'styled-components';

import { CloseIcon } from '@/icons';

export const StyledCommentPlaceholder = styled.div`
	background-color: var(--comment-placeholder-color);
	border-radius: 6px;
	color: var(--body-text);
	height: 100%;
	padding: var(--spacing-4);
	width: 100%;
`;

export const StyledComments = styled.div`
	.comment-container {
		background-color: var(--comment-color);
		border-radius: 6px;
		color: var(--body-text);
		display: flex;
		flex-direction: column;
		margin-bottom: var(--spacing-2);
		padding: var(--spacing-2);
		position: relative;
		right: 0;
		transition: background-color ease-in-out 0.3s;

		&.is-active {
			background-color: var(--active-comment-color);
		}
	}
	.comment-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.comment {
		display: flex;
		flex-direction: column;
		margin-bottom: var(--spacing-2);
	}

	small {
		font-size: 1.1rem;
		font-style: italic;
	}

	.comment-reply {
		background-color: #f8f9fc;
		border-left: 2px solid;
		border-radius: 0 4px 4px 0;
		padding: var(--spacing-2);
	}

	.reply-from {
		border-top: 1px solid rgba(50, 50, 50, 0.1);
		margin-top: 16px;
		padding-top: 16px;

		textarea {
			margin-bottom: var(--spacing-2);
			resize: vertical;
		}
	}

	span {
		font-size: 1.3rem;
	}

	time {
		font-size: 1.2rem;
	}

	span {
		font-family: var(--font-bold);
	}
`;

export const StyledCloseIcon = styled(CloseIcon as any)`
	background-color: var(--tab-group-color);
	padding: var(--spacing-2);
`;
