const PDFIcon = () => {
	return (
		<svg width="30.5px" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.9 4.7H6.4c-.4 0-.7.2-.7.6v4.2c0 .5.3.7.7.7.3 0 .6-.2.6-.7V8.4h1c1 0 1.7-.8 1.7-1.9 0-1.2-.9-1.8-1.8-1.8Zm-.3 2.6H7V5.8h.6c.5 0 .9.3.9.7 0 .4-.3.8-.9.8ZM12.4 4.7h-1.5c-.3 0-.6.2-.6.6v4.2c0 .4.3.6.6.6h1.5c1.3 0 2.5-1 2.5-2.7 0-1.4-1-2.7-2.5-2.7ZM12.2 9h-.7V5.8h.7c1 0 1.4.7 1.4 1.6 0 1-.5 1.6-1.4 1.6ZM18 5.8c.4 0 .6-.2.6-.5 0-.4-.2-.6-.6-.6h-1.9c-.3 0-.6.2-.6.6v4.2c0 .5.3.7.6.7.4 0 .7-.2.7-.7V8h1c.5 0 .7-.3.7-.6 0-.4-.2-.6-.6-.6h-1.1v-1H18ZM15.6 2a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8Z"
				fill="#fff"
			/>
			<path
				d="M.3 3C.3 1.3 1.6.1 3.1.1h8.1a.9.9 0 0 1 0 1.8H3a1 1 0 0 0-1 1v8.8c0 .5.4 1 1 1H21c.5 0 1-.5 1-1V2.9c0-.5-.5-1-1-1H20a.9.9 0 0 1 0-1.7h.9c1.5 0 2.7 1.2 2.7 2.7v8.9c0 1.5-1.2 2.7-2.7 2.7H3a2.8 2.8 0 0 1-2.8-2.7V2.9Z"
				fill="#fff"
			/>
		</svg>
	);
};

export default PDFIcon;
