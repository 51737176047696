import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient, request } from 'graphql-request';
import pMap from 'p-map';

import GlobalContext from '@/context/GlobalContext';
import { SetStatus } from '@/Enums/enum';
import {
	GetCourseQuery,
	GetUsersQuery,
	Submissions,
	UpdateCourseMutation,
} from '@/graphql/graphql';
import { getGetCurrentUser } from '@/queries';
import { prefix, renameAndDestructure } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
const removeCommentTags = (text: string) => {
	const regex =
		/<i(?=\s)(?!(?:[^>"\\']|"[^"]*"|\\'[^\\']*\\')*?(?<=\s)(?:term|range)\s*=)(?!\s*>)\s+(?:".*?"|\\'.*?\\'|[^>]*?)+>|<\/i>/gm;

	return text.replace(regex, '');
};

const CONCURRENT_PROMISES = 3;

export async function massApproveSubmissions(querySubmissionData: Submissions[] | undefined) {
	if (!querySubmissionData) {
		return;
	}
	const query = gql`
        query GetCurrentCourses($courseId: uuid!) {
            ${prefix}courses_by_pk(course_id: $courseId) {
                course_name
                course_id
                course_description
                course_resource_info
                course_prereq
                course_extras
                course_division
                submission_id
                course_draft
                is_saved_draft
                last_updated
                submission {
                    admin_approval
                    dept_approval
                }
                courses_outcomes {
                    courses_outcomes_id
                    outcome {
                        outcome_order
                        outcome_text
                        outcome_id
                    }
                }
                courses_resources {
                    course_resources_id
                    resource {
                        resource_author
                        resource_detail
                        resource_isbn
                        resource_id
                        resource_title
                        resource_type
                    }
                }
                courses_focuses {
                    courses_focuses_id
                    focus {
                        focus_title
                        focus_type
                        focus_order
                        focus_id
                        focuses_skills {
                            focuses_skills_id
                            skill {
                                skill_id
                                skill_text
                                skill_type
                            }
                        }
                    }
                }
            }
        }
    `;

	await pMap(
		querySubmissionData,
		async ({ course }) => {
			const courseId = course?.course_id;
			await request(`${import.meta.env.VITE_HASURA_ENDPOINT}`, query, {
				courseId,
			}).then(async (data) => {
				const { courses_by_pk: course } = renameAndDestructure(data, prefix);
				const { submission } = course;
				if (
					submission.admin_approval === SetStatus.APPROVED &&
					submission.dept_approval === SetStatus.APPROVED
				) {
					return true;
				}

				const publishedCourse = course;
				const draftCourse = JSON.parse(removeCommentTags(course.course_draft) ?? '{}');

				const deleteOutcomesMutation = gql`
                mutation deleteOutcomesMutation($outcomeId: uuid!) {
                    ${prefix}delete_outcomes(where: { outcome_id: { _eq: $outcomeId } }) {
                        affected_rows
                    }
                }
            `;
				const createOutcomeMutation = gql`
                mutation OutcomeMutation($outcomeText: String, $outcomeOrder: Int) {
                    ${prefix}insert_outcomes_one(
                        object: { outcome_text: $outcomeText, outcome_order: $outcomeOrder }
                    ) {
                        outcome_id
                    }
                }
            `;
				const createCourseOutcomeMutation = gql`
                mutation CourseOutcomeMutation($courseId: uuid, $outcomeId: uuid) {
                    ${prefix}insert_courses_outcomes(
                        objects: { course_id: $courseId, outcome_id: $outcomeId }
                    ) {
                        affected_rows
                    }
                }
            `;

				const deletePublishedOutcomes = async () => {
					const outcomes = publishedCourse.courses_outcomes || [];
					await pMap(
						outcomes,
						async ({ outcome }: any) => {
							if (outcome.outcome_id) {
								await graphQLClient.request(deleteOutcomesMutation, {
									outcomeId: outcome.outcome_id,
								});
							}
						},
						{ concurrency: CONCURRENT_PROMISES }
					);
				};

				if (publishedCourse.courses_outcomes?.length > 0) {
					await deletePublishedOutcomes();
				}

				const publishDraftOutcomes = async () => {
					const outcomes = draftCourse.courses_outcomes || [];
					await pMap(
						outcomes,
						async ({ outcome }: any) => {
							const { outcome_text: outcomeText, outcome_order: outcomeOrder = 0 } =
								outcome;
							const result = await graphQLClient.request(createOutcomeMutation, {
								outcomeText,
								outcomeOrder,
							});
							const { insert_outcomes_one: insertedOutcome } = renameAndDestructure(
								result,
								prefix
							);
							await graphQLClient.request(createCourseOutcomeMutation, {
								outcomeId: insertedOutcome.outcome_id,
								courseId,
							});
						},
						{ concurrency: CONCURRENT_PROMISES }
					);
				};

				if (draftCourse?.courses_outcomes?.length > 0) {
					await publishDraftOutcomes();
				}

				const createResourceMutation = gql`
                mutation ResourceMutation(
                    $resourceAuthor: String
                    $resourceDetail: String
                    $resourceIsbn: String
                    $resourceType: String
                    $resourceTitle: String
                ) {
                    ${prefix}insert_resources_one(
                        object: {
                            resource_author: $resourceAuthor
                            resource_detail: $resourceDetail
                            resource_isbn: $resourceIsbn
                            resource_type: $resourceType
                            resource_title: $resourceTitle
                        }
                    ) {
                        resource_id
                    }
                }
            `;
				const createCourseResourceMutation = gql`
                mutation CourseResourceMutation($courseId: uuid, $resourceId: uuid) {
                    ${prefix}insert_courses_resources(
                        objects: { course_id: $courseId, resource_id: $resourceId }
                    ) {
                        affected_rows
                    }
                }
            `;
				const deleteResourceMutation = gql`
                mutation DeleteResourceMutation($resourceId: uuid!) {
                    ${prefix}delete_resources(where: { resource_id: { _eq: $resourceId } }) {
                        affected_rows
                    }
                }
            `;

				const deletePublishedResources = async () => {
					const resources = publishedCourse.courses_resources || [];
					await pMap(
						resources,
						async ({ resource }: any) => {
							if (resource.resource_id) {
								await graphQLClient.request(deleteResourceMutation, {
									resourceId: resource.resource_id,
								});
							}
						},
						{ concurrency: CONCURRENT_PROMISES }
					);
				};

				if (publishedCourse.courses_resources?.length > 0) {
					await deletePublishedResources();
				}

				const publishDraftResources = async () => {
					const resources = draftCourse.courses_resources || [];
					await pMap(
						resources,
						async ({ resource }: any) => {
							const {
								resource_author: resourceAuthor,
								resource_detail: resourceDetail,
								resource_isbn: resourceIsbn,
								resource_type: resourceType,
								resource_title: resourceTitle,
							} = resource;
							const result = await graphQLClient.request(createResourceMutation, {
								resourceAuthor,
								resourceDetail,
								resourceIsbn,
								resourceType,
								resourceTitle,
							});
							const { insert_resources_one: insertedResource } = renameAndDestructure(
								result,
								prefix
							);
							await graphQLClient.request(createCourseResourceMutation, {
								resourceId: insertedResource.resource_id,
								courseId,
							});
						},
						{ concurrency: CONCURRENT_PROMISES }
					);
				};

				if (draftCourse?.courses_resources?.length > 0) {
					await publishDraftResources();
				}

				const createFocusMutation = gql`
                mutation FocusMutation($focusTitle: String, $focusOrder: Int) {
                    ${prefix}insert_focuses_one(
                        object: { focus_title: $focusTitle, focus_order: $focusOrder }
                    ) {
                        focus_id
                    }
                }
            `;
				const createSkillMutation = gql`
                mutation SkillMutation($skillText: String, $skillType: String) {
                    ${prefix}insert_skills_one(object: { skill_text: $skillText, skill_type: $skillType }) {
                        skill_id
                    }
                }
            `;
				const createFocusSkillMutation = gql`
                mutation FocusSkillMutation($skillId: uuid, $focusId: uuid) {
                    ${prefix}insert_focuses_skills_one(object: { skill_id: $skillId, focus_id: $focusId }) {
                        focuses_skills_id
                    }
                }
            `;
				const deleteFocusMutation = gql`
                mutation DeleteFocusMutation($focusId: uuid!) {
                    ${prefix}delete_focuses(where: { focus_id: { _eq: $focusId } }) {
                        affected_rows
                    }
                }
            `;
				const deleteSkillMutation = gql`
                mutation DeleteSkillMutation($skillId: uuid!) {
                    ${prefix}delete_skills(where: { skill_id: { _eq: $skillId } }) {
                        affected_rows
                    }
                }
            `;
				const deleteFocusSkillMutation = gql`
                mutation DeleteSkillMutation($focusSkillId: uuid!) {
                    ${prefix}delete_focuses_skills(where: { focuses_skills_id: { _eq: $focusSkillId } }) {
                        affected_rows
                    }
                }
            `;
				const createCourseFocusesMutation = gql`
                mutation CourseFocusesMutation($courseId: uuid, $focusId: uuid) {
                    ${prefix}insert_courses_focuses_one(
                        object: { course_id: $courseId, focus_id: $focusId }
                    ) {
                        focus_id
                    }
                }
            `;

				const deletePublishedFocuses = async () => {
					const focuses = publishedCourse.courses_focuses || [];
					await pMap(
						focuses,
						async ({ focus }: any) => {
							const focusSkills = focus.focuses_skills || [];
							await pMap(
								focusSkills,
								async ({ skill, focuses_skills_id: focusSkillId }: any) => {
									if (focusSkillId) {
										await graphQLClient.request(deleteFocusSkillMutation, {
											focusSkillId,
										});
									}
									if (skill?.skill_id) {
										await graphQLClient.request(deleteSkillMutation, {
											skillId: skill.skill_id,
										});
									}
								},
								{ concurrency: CONCURRENT_PROMISES }
							);

							if (focus.focus_id) {
								await graphQLClient.request(deleteFocusMutation, {
									focusId: focus.focus_id,
								});
							}
						},
						{ concurrency: CONCURRENT_PROMISES }
					);
				};

				if (publishedCourse.courses_focuses?.length > 0) {
					await deletePublishedFocuses();
				}

				const publishDraftFocuses = async () => {
					const focuses = draftCourse.courses_focuses || [];
					await pMap(
						focuses,
						async ({ focus }: any) => {
							const { focus_order: focusOrder, focus_title: focusTitle } = focus;
							const result = await graphQLClient.request(createFocusMutation, {
								focusTitle,
								focusOrder,
							});
							const { insert_focuses_one: insertedFocus } = renameAndDestructure(
								result,
								prefix
							);

							await graphQLClient.request(createCourseFocusesMutation, {
								focusId: insertedFocus.focus_id,
								courseId,
							});

							const focusSkills = focus.focuses_skills || [];
							await pMap(
								focusSkills,
								async ({ skill }: any) => {
									if (skill) {
										const { skill_text: skillText, skill_type: skillType } =
											skill;
										const skillDefaultType =
											skill.skill_type === '' || skill.skill_type === null
												? 'topic'
												: skillType;
										const result = await graphQLClient.request(
											createSkillMutation,
											{ skillText, skillType: skillDefaultType }
										);
										const { insert_skills_one: insertedSkill } =
											renameAndDestructure(result, prefix);
										await graphQLClient.request(createFocusSkillMutation, {
											skillId: insertedSkill.skill_id,
											focusId: insertedFocus.focus_id,
										});
									}
								},
								{ concurrency: CONCURRENT_PROMISES }
							);
						},
						{ concurrency: CONCURRENT_PROMISES }
					);
				};

				if (draftCourse?.courses_focuses?.length > 0) {
					await publishDraftFocuses();
				}

				const courseBasicMutation = gql`
                mutation updateCourse(
                    $courseId: uuid!
                    $courseDescription: String
                    $courseExtras: String
                    $coursePrereq: String
                    $courseResourceInfo: String
                ) {
                    ${prefix}update_courses_by_pk(
                        pk_columns: { course_id: $courseId }
                        _set: {
                            course_description: $courseDescription
                            course_resource_info: $courseResourceInfo
                            course_extras: $courseExtras
                            course_prereq: $coursePrereq
                        }
                    ) {
                        course_id
                    }
                }
            `;
				const {
					course_description: courseDescription,
					course_resource_info: courseResourceInfo,
					course_extras: courseExtras,
					course_prereq: coursePrereq,
				} = draftCourse;

				await graphQLClient.request(courseBasicMutation, {
					courseId,
					courseDescription,
					courseResourceInfo,
					courseExtras,
					coursePrereq,
				});

				const queryCurrentCourse = gql`
					query currentCourse($courseId: uuid!) {
						${prefix}courses_by_pk(course_id: $courseId) {
							course_name
							course_id
							course_description
							course_resource_info
							course_prereq
							course_extras
							course_division
							course_draft
							courses_outcomes {
								courses_outcomes_id
								outcome {
									outcome_order
									outcome_text
									outcome_id
								}
							}
							courses_resources {
								course_resources_id
								resource {
									resource_author
									resource_detail
									resource_isbn
									resource_id
									resource_title
									resource_type
								}
							}
							courses_focuses {
								courses_focuses_id
								focus {
									focus_title
									focus_type
									focus_order
									focus_id
									focuses_skills {
										focuses_skills_id
										skill {
												skill_id
												skill_text
												skill_type
										}
									}
								}
							}
						}
					}
			`;
				const result = await graphQLClient.request<GetCourseQuery>(queryCurrentCourse, {
					courseId,
				});
				const { courses_by_pk: refetchedCourse } = renameAndDestructure(result, prefix) as {
					courses_by_pk: GetCourseQuery['dev_courses_by_pk'];
				};
				const newDraft = JSON.stringify(refetchedCourse);
				const courseNewDraftMutation = gql`
					mutation newDraftMutation($courseDraft: String!, $courseId: uuid!) {
							${prefix}update_courses_by_pk(
									pk_columns: { course_id: $courseId }
									_set: { course_draft: $courseDraft }
							) {
									course_id
							}
					}
				`;
				await graphQLClient.request(courseNewDraftMutation, {
					courseDraft: newDraft,
					courseId,
				});

				const courseMutation = gql`
					mutation updateCourse {
						${prefix}update_submissions(
							where: { course_id: { _eq: "${courseId}" } }
							_set: { admin_approval: ${SetStatus.APPROVED}, dept_approval: ${SetStatus.APPROVED} }
						) {
							returning {
									admin_approval
									dept_approval
							}
						}
					}
			`;

				const finalResult = await graphQLClient.request<UpdateCourseMutation>(
					courseMutation
				);
				const response = renameAndDestructure(finalResult, prefix);
				return response;
			});
		},
		{ concurrency: CONCURRENT_PROMISES }
	);
}

export function useGetAllSubmissions() {
	const { user } = { ...useContext(GlobalContext) };

	return useQuery({
		queryKey: ['get-all-subs'],

		queryFn: async () => {
			const fid = await (user as any)?.uid;
			graphQLClient.setHeader('content-type', `application/json`);

			const usersResult = await graphQLClient.request<GetUsersQuery>(getGetCurrentUser, {
				id: fid,
			});
			const { users } = renameAndDestructure(usersResult, prefix) as {
				users: GetUsersQuery['dev_users'];
			};
			const auth = getAuth();
			const token = await auth.currentUser?.getIdToken();

			graphQLClient.setHeader('authorization', `Bearer ${token}`);
			graphQLClient.setHeader('x-hasura-role', users[0].user_role);
			const uid = users[0].user_id;

			if (users[0].user_role === 'admin') {
				const adminResult = await graphQLClient.request<Array<Submissions>>(
					gql`
						query GetSubmissions($fid: String) {
							${prefix}submissions(order_by: { course: { course_name: asc } }) {
								submission_id
								admin_approval
								dept_approval
								course {
									course_name
									course_id
									courses_users {
										user_id
										course_id
										user {
											user_id
											user_first
											user_last
											user_firebase_id
											dept_chair {
												user_firebase_id
												user_id
											}
										}
									}
								}
							}
						}
					`
				);
				const { submissions } = renameAndDestructure(adminResult, prefix) as {
					submissions: Array<Submissions>;
				};
				return submissions;
			}

			if (users[0].user_role === 'dept') {
				const deptResult = await graphQLClient.request<Array<Submissions>>(
					gql`
						query GetSubmissions($fid: String, $deptId: String) {
							${prefix}submissions(
								order_by: { course: { course_name: asc } }
								where: {
									_or: [
										{
											course: {
												courses_users: {user_id: {_eq: "${uid}"}}
											}
										}
										{
											course: {
												courses_users: {user: {dept_chair: {user_id: {_eq: "${uid}"}}}}
											}
										}
									]
								}
							) {
								submission_id
								admin_approval
								dept_approval
								course {
									course_name
									course_id
									courses_users {
										user_id
										course_id
										user {
											user_id
											user_first
											user_last
											user_firebase_id
											dept_chair {
												user_firebase_id
												user_id
											}
										}
									}
								}
							}
						}
					`
				);
				const { submissions } = renameAndDestructure(deptResult, prefix) as {
					submissions: Array<Submissions>;
				};
				return submissions;
			}

			const result = await graphQLClient.request<Array<Submissions>>(
				gql`
					query GetSubmissions {
						${prefix}submissions(
							order_by: { course: { course_name: asc } }
							where: {
								course: {
									courses_users: {
										user_id: {_eq: "${uid}"}
									}
								}
							}
						) {
							submission_id
							admin_approval
							dept_approval
							course {
								course_name
								course_id
								courses_users {
									user_id
									course_id
									user {
										user_id
										user_first
										user_last
										user_firebase_id
										dept_chair {
											user_firebase_id
											user_id
										}
									}
								}
							}
						}
					}
				`
			);
			const { submissions } = renameAndDestructure(result, prefix) as {
				submissions: Array<Submissions>;
			};
			return submissions;
		},

		staleTime: Infinity,
		refetchOnMount: 'always',
	});
}

// TODO: add button to remove all submissions
// ? query to remove all submissions
// mutation RemoveAllSubmissions {
// 	update_courses(where: {submission_id: {_is_null: false}}, _set: {submission_id: null}) {
// 	  affected_rows
// 	}
// 	delete_submissions(where: {}) {
// 	  affected_rows
// 	}
//   }
