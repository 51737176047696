import { useEffect, useState } from 'react';

import Checkbox from '@/components/Checkbox';
import CourseTags from '@/components/CourseTags';
import Loader from '@/components/Loader';
import { GetModalCoursesQuery, GetUsersQuery } from '@/graphql/graphql';
import { SearchIcon } from '@/icons/index';
import { StyledSearch } from '@/Shared/StyledElements';
import { StyledH5, StyledP } from '@/Shared/Typography/typography';

import { useGetModalCourses } from '../UserContainers';

import { StyledModalPanel } from './UserModalPanelStyles';

interface UserModalPanelProps {
	panelTitle: string;
	selectedCourses: NonNullable<GetUsersQuery['dev_users']>[0]['courses_users'];
	passedEvent?: (a: any) => any;
}

interface CheckboxChangeEvt {
	course: CoursesEntity;
	e: React.ChangeEvent;
}

const UserModalPanel = ({ panelTitle, selectedCourses, passedEvent }: UserModalPanelProps) => {
	const [filteredList, setFilteredList] = useState<
		GetModalCoursesQuery['dev_courses'] | undefined
	>([]);
	const [panelList, setPanelList] = useState<GetModalCoursesQuery['dev_courses'] | undefined>([]);
	const { data: coursesData, isFetched } = useGetModalCourses();

	useEffect(() => {
		setFilteredList(coursesData);
		setPanelList(coursesData);
	}, [coursesData]);

	const filterCourses = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.persist();
		const filterValue = e.currentTarget.value.toLowerCase();
		const list = panelList?.filter((course) => {
			const { course_name: courseName } = course;
			return courseName.toLowerCase().includes(filterValue);
		});
		if (filterValue === '') {
			setFilteredList(panelList);
		} else {
			setFilteredList(list);
		}
	};

	const handleChange = (e: CheckboxChangeEvt) => {
		if (passedEvent) {
			passedEvent((prevState: CoursesEntity[]) => {
				const isExistingCourse = prevState.find(
					(c: CoursesEntity) => c.course_id === e.course.course_id
				);

				if (isExistingCourse) {
					return [
						...prevState.filter(
							(c: CoursesEntity) => c.course_id !== e.course.course_id
						),
					];
				}

				if (!prevState) {
					return [
						{
							course_name: e.course.course_name,
							course_id: e.course.course_id,
						},
					];
				}

				return [
					...prevState,
					{ course_name: e.course.course_name, course_id: e.course.course_id },
				];
			});
		}
	};

	if (!isFetched) return <Loader size="small" />;

	return (
		<StyledModalPanel>
			<StyledH5 mb="0">{panelTitle}</StyledH5>
			<StyledSearch>
				<SearchIcon classList="absolute left-3 top-1/2 -translate-y-1/2" />
				<input placeholder="Search..." type="text" onChange={(e) => filterCourses(e)} />
			</StyledSearch>
			<div className="scroll-container">
				{filteredList &&
					filteredList.map((course: CoursesEntity) => (
						<Checkbox
							passedEvent={(e: React.ChangeEvent) => handleChange({ e, course })}
							key={course.course_id}
							dataId={course.course_id}
							isChecked={selectedCourses.map((course) => course.course_id)}
							inline={false}>
							<CourseTags text={course.course_name} />
						</Checkbox>
					))}
				{filteredList?.length === 0 && <StyledP>No matching courses found.</StyledP>}
			</div>
		</StyledModalPanel>
	);
};

export default UserModalPanel;
