import { Fragment } from 'react';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import { capitalize } from '@/utils';

import { StyledPdfTable } from './CoursePdf.Styles';

export const downLoadPDF = (imageUrl: string) => {
	// eslint-disable-next-line new-cap
	const doc = new jsPDF('p', 'mm', 'a4');

	const img = new Image();
	img.src = imageUrl;

	if (imageUrl) {
		const format = imageUrl.split('.')?.pop()?.toUpperCase() ?? '';
		doc.addImage(img, format, 13, 5, 20, 0);
	}

	const settings = {
		useCss: true,
		includeHiddenHtml: true,
		styles: {
			minCellWidth: 30,
		},
	};

	autoTable(doc, {
		startY: imageUrl ? 30 : 10,
		html: '#table',
		...settings,
	});

	autoTable(doc, {
		html: '#table2',
		...settings,
		styles: {
			minCellWidth: 35,
		},
	});

	autoTable(doc, {
		html: '#table3',
		...settings,
	});

	window.open(URL.createObjectURL(doc.output('blob')));

	// ?? Should we automatically download the pdf?
	// const formattedName = courseName
	// 	.toLowerCase()
	// 	.replace(/[$&+,:;=?@#|'<>.^*()%!-]|\s+/g, '-')
	// 	.replace(/---/g, '-')
	// 	.replace(/--/g, '-');
	// doc.save(formattedName);
};

interface CoursePdfProps {
	courseDraftData: Course;
	courseName: string;
}

export const CoursePdf = ({ courseDraftData, courseName }: CoursePdfProps) => {
	// regex to remove HTML tags
	const removeHTMLTags = (str: string) => {
		const stripped = str?.replace(/<[^>]*>?/gm, '');

		if (stripped === '' || !str) return '-';
		return stripped;
	};

	return (
		<StyledPdfTable>
			<table id="table">
				<tbody>
					<tr>
						<td className="pdf-course-title">{courseName}</td>
					</tr>
					<tr>
						<th align="left">Description</th>
					</tr>
					<tr>
						<td>{removeHTMLTags(courseDraftData.course_description)}</td>
					</tr>
					<tr>
						<th align="left">Prerequisites</th>
					</tr>
					<tr>
						<td>{removeHTMLTags(courseDraftData.course_prereq)}</td>
					</tr>
					<tr>
						<th align="left">Additional Information</th>
					</tr>
					<tr>
						<td>{removeHTMLTags(courseDraftData.course_extras)}</td>
					</tr>
					<tr>
						<th align="left">Course Outcomes</th>
					</tr>
					{courseDraftData.courses_outcomes?.length ? (
						courseDraftData.courses_outcomes?.map(({ outcome }, index) => {
							return (
								<Fragment key={outcome.outcome_id}>
									<tr>
										<td className="group">Outcome {index + 1}</td>
									</tr>
									<tr>
										<td>{removeHTMLTags(outcome.outcome_text)}</td>
									</tr>
								</Fragment>
							);
						})
					) : (
						<tr>
							<td>-</td>
						</tr>
					)}
					<tr>
						<th align="left">Resources</th>
					</tr>
					<tr>
						<td>{removeHTMLTags(courseDraftData.course_resource_info)}</td>
					</tr>
				</tbody>
			</table>
			<table id="table2" className="resources-table">
				<tbody>
					<tr>
						<th align="left">Title</th>
						<th align="left">Author</th>
						<th align="left">Detail</th>
						<th align="left">ISBN</th>
					</tr>
					{courseDraftData.courses_resources?.length ? (
						courseDraftData.courses_resources?.map(({ resource }) => {
							return (
								<tr key={resource.resource_id}>
									<td>{removeHTMLTags(resource.resource_title)}</td>
									<td>{removeHTMLTags(resource.resource_author)}</td>
									<td>{removeHTMLTags(resource.resource_detail)}</td>
									<td>{removeHTMLTags(resource.resource_isbn)}</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
						</tr>
					)}
				</tbody>
			</table>
			<table id="table3">
				<tbody>
					<tr>
						<th align="left">Units of Focus</th>
					</tr>
					{courseDraftData.courses_focuses?.length ? (
						courseDraftData.courses_focuses?.map(({ focus }) => {
							return (
								<Fragment key={focus.focus_id}>
									<tr>
										<td className="group">
											{removeHTMLTags(focus.focus_title)}
										</td>
									</tr>
									{focus.focuses_skills?.map(({ skill }) => {
										return (
											<tr key={skill.skill_id}>
												<td className="indent-2">
													{capitalize(skill.skill_type)}:{' '}
													{removeHTMLTags(skill.skill_text)}
												</td>
											</tr>
										);
									})}
								</Fragment>
							);
						})
					) : (
						<tr>
							<td>-</td>
						</tr>
					)}
				</tbody>
			</table>
		</StyledPdfTable>
	);
};
