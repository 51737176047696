import { useMemo } from 'react';

import { Routes, Route } from 'react-router-dom';

import CourseMap from '@/components/CourseMap/CourseMap';
import { ToastNotification } from '@/components/ToastNotification';
import { useUserRole } from '@/hooks/useAuth';
import CourseEdit from '@/Pages/Courses/CourseEdit';
import { CourseEditProvider } from '@/Pages/Courses/CourseEditContext';
import Settings from '@/Pages/Settings/Settings';
import { GlobalAdminStyle } from '@/Shared/StyledElements';

import {
	Users,
	Courses,
	Submissions,
	Stats,
	Divisions,
	CourseAlignment,
	NotFound,
	EditTags,
	Disciplines,
	Privacy,
} from '../Pages/index';

interface PlatformRoute {
	path: string;
	element: React.ReactNode;
	children?: PlatformRoute[];
}

const Panel = () => {
	const { data: userRoleData } = useUserRole('always');

	const routes = useMemo(() => {
		if (userRoleData?.user_role === 'admin') {
			return [
				{
					path: 'courses/',
					element: <Courses />,
				},
				{
					path: 'courses/:courseId',
					element: (
						<CourseEditProvider>
							<CourseEdit />
						</CourseEditProvider>
					),
				},
				{
					path: '/users',
					element: <Users />,
				},
				{
					path: '/tags',
					element: <EditTags />,
				},
				{
					path: '/disciplines',
					element: <Disciplines />,
				},
				{
					path: '/divisions',
					element: <Divisions />,
				},
				{
					path: '/stats',
					element: <Stats />,
				},
				{
					path: '/submissions',
					element: <Submissions />,
				},
				{
					path: '/submissions/:courseId',
					element: (
						<CourseEditProvider>
							<CourseEdit />
						</CourseEditProvider>
					),
				},
				{
					path: '/course-alignment',
					element: <CourseAlignment />,
				},
				{
					path: '/course-map',
					element: <CourseMap />,
				},
				{
					path: '/privacy-policy',
					element: <Privacy />,
				},
				{
					path: '/settings',
					element: <Settings />,
				},
			];
		}
		return [
			{
				path: '/courses/',
				element: <Courses />,
			},
			{
				path: 'courses/:courseId',
				element: (
					<CourseEditProvider>
						<CourseEdit />
					</CourseEditProvider>
				),
			},
			{
				path: '/submissions',
				element: <Submissions />,
			},
			{
				path: '/submissions/:courseId',
				element: (
					<CourseEditProvider>
						<CourseEdit />
					</CourseEditProvider>
				),
			},
			{
				path: '/privacy-policy',
				exact: true,
				element: <Privacy />,
			},
			{
				path: '/course-alignment',
				element: <CourseAlignment />,
			},
		];
	}, [userRoleData?.user_role]) as PlatformRoute[];

	if (userRoleData?.user_role === undefined) {
		return null;
	}

	return (
		<>
			<GlobalAdminStyle />
			<div
				id="panel"
				className="bg-white rounded-md shadow-[0px_1px_0px_rgba(0,15,25,0.05),0px_8px_16px_rgba(0,15,25,0.05)] mt-10 mr-4 px-6 py-3 max-w-[1500px] overflow-hidden relative">
				<Routes>
					{routes.map((route) => (
						<Route key={route.path} path={route.path} element={route.element} />
					))}
					<Route path="*" element={<NotFound />} />
				</Routes>
				<ToastNotification />
			</div>
		</>
	);
};

export default Panel;
