import React, { useEffect, useState, useContext } from 'react';

import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import logo from '@/assets/logo.svg';
import { BoxedIconMessage } from '@/components/BoxedIconMessage';
import Checkbox from '@/components/Checkbox';
import { FormErrorMessage } from '@/components/FormErrorMessage';
import PasswordInput from '@/components/PasswordInput/PasswordInput';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import GlobalContext from '@/context/GlobalContext';
import { isLocalStorageAvailable } from '@/utils';

import {
	StyledBG,
	StyledForm,
	StyledFormFooter,
	StyledFeatherBackgroundTop,
	StyledFeatherBackgroundBottom,
	StyledCopy,
} from './SignInStyles';

interface FormValues {
	email: string;
	password: string;
}

const SignIn = () => {
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = useForm<FormValues>();
	const { updateAuthState } = useContext(GlobalContext);
	const currentYear = new Date().getFullYear();
	const navigate = useNavigate();
	const [messageState, setMessageState] = useState({
		message: '',
		messageColor: 'var(--red-200)',
		hasError: false,
	});

	useEffect(() => {
		const abortController = new AbortController();
		const auth = getAuth();

		onAuthStateChanged(auth, async (user) => {
			if (user) {
				const token = await user.getIdToken();
				const idTokenResult = await user.getIdTokenResult();
				const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

				if (hasuraClaim) {
					updateAuthState({ user, token });
				} else {
					// Check if refresh is required.
					const databaseRef = getDatabase();
					const metadataRef = ref(databaseRef, `metadata/${user.uid}/refreshTime`);
					onValue(metadataRef, async (data) => {
						if (!data.exists) return;
						// Force refresh to pick up the latest custom claims changes.
						const token = await user.getIdToken(true);
						updateAuthState({ user, token });
					});
				}
			} else {
				updateAuthState({});
			}
		});

		return () => {
			abortController.abort();
		};
	}, [updateAuthState]);

	const location = useLocation();
	const getPath = () => {
		const locationStateFrom = (location.state as Record<string, string>)?.from;
		return locationStateFrom || '/dashboard/courses';
	};

	const getLocalPreferences = () => {
		const preferences = localStorage.getItem('elevatePreferences') || '{}';

		return JSON.parse(preferences);
	};

	const isEmailSaved = () => {
		if (isLocalStorageAvailable()) {
			const isSaved = getLocalPreferences()?.isSaved || false;
			return isSaved;
		}
		return false;
	};

	const onSubmit: SubmitHandler<FormValues> = async (signInFormData) => {
		const { email, password } = signInFormData;
		const preferences = getLocalPreferences();
		const trimmedEmail = email.trim();

		if (isEmailSaved()) {
			const updatedPreferences = { ...preferences, email: trimmedEmail };
			localStorage.setItem('elevatePreferences', JSON.stringify(updatedPreferences));
		}
		const auth = getAuth();
		try {
			await signInWithEmailAndPassword(auth, trimmedEmail, password);
			setMessageState({
				...messageState,
				message: '',
			});
			navigate(getPath());
		} catch (error) {
			const errorCode = (error as Record<string, string>).code;
			let errorMsg = 'Something went wrong. Please try again.';

			if (errorCode === 'auth/wrong-password' || errorCode === 'auth/user-not-found') {
				errorMsg = 'Wrong email or password.';
			}
			if (errorCode === 'auth/network-request-failed') {
				errorMsg = 'Your network connection may be down. Please try again.';
			}
			setMessageState({
				message: errorMsg,
				messageColor: 'var(--red-200)',
				hasError: true,
			});
		}
	};

	const setInitialEmail = () => {
		if (isLocalStorageAvailable()) {
			const email = getLocalPreferences()?.email || '';
			return email;
		}

		return '';
	};

	const saveEmailAddress = (e: React.SyntheticEvent) => {
		const { checked } = e.target as HTMLInputElement;

		if (checked) {
			const preferences = {
				email: '',
				isSaved: checked,
			};
			localStorage.setItem('elevatePreferences', JSON.stringify(preferences));
		} else {
			localStorage.removeItem('elevatePreferences');
		}
	};

	const navigateToResetPassword = (e: React.MouseEvent) => {
		e.preventDefault();
		navigate('/reset-password');
	};

	return (
		<StyledBG>
			<StyledFeatherBackgroundTop />
			<StyledFeatherBackgroundBottom />
			<Link to="/" className="logo" aria-label="Home">
				<img src={`${logo}`} alt="Elevate logo" />
			</Link>
			<StyledForm onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="email">Email Address</label>
				<Input
					type="text"
					id="email"
					{...register('email', {
						required: 'Email is required',
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
							message: 'Invalid email address',
						},
					})}
					defaultValue={setInitialEmail()}
				/>
				<FormErrorMessage
					className="error-message"
					isShowing={!!errors?.email}
					message={errors.email?.message}
				/>
				<PasswordInput
					labelText="Password"
					registerOptions={register('password', {
						required: 'Password is required',
						minLength: {
							value: 8,
							message: 'Email must be at least 8 characters',
						},
					})}
					errors={errors}
				/>
				{/* only show button if local storage permissions are set */}
				{isLocalStorageAvailable() && (
					<Checkbox
						dataId="remember-user"
						isChecked={isEmailSaved()}
						passedEvent={saveEmailAddress}
						inline={false}>
						Remember Email Address
					</Checkbox>
				)}
				<StyledFormFooter>
					<Button
						type="submit"
						onClick={handleSubmit(onSubmit)}
						isLoading={isSubmitting}
						className="w-[max-content]"
						disabled={isSubmitting}>
						{isSubmitting ? 'Signing In' : 'Sign In'}
					</Button>
					<a href="/reset-password" onClick={navigateToResetPassword}>
						Forgot Password?
					</a>
				</StyledFormFooter>
				{messageState.message !== '' && (
					<BoxedIconMessage
						message={messageState.message}
						messageColor={messageState.messageColor}
						hasError={messageState.hasError}
					/>
				)}
			</StyledForm>
			<StyledCopy>Copyright All Rights Reserved &#169; {currentYear} | Elevate</StyledCopy>
		</StyledBG>
	);
};

export default SignIn;
