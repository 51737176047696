import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { useSession } from '@/hooks/useAuth';
import { StyledH4, StyledP } from '@/Shared/Typography/typography';

import { StyledSvg } from './NotFoundStyles';

const NotFound = () => {
	const user = useSession();
	const navigate = useNavigate();

	const setNavigation = () => {
		if (user === null) {
			navigate('/');
		}
		navigate('/dashboard/courses');
	};

	const setButtonText = () => {
		if (user === null) {
			return 'Sign In';
		}
		return 'Try Again';
	};

	return (
		<StyledSvg>
			<svg viewBox="0 0 774 312" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M.8 238v-14L153.2 26.8h20.4v195.6h45.6V238H.8zM156.4 48.4L22 222.4h134.4v-174zM253.909 166.4c0-46.133 6.667-81.6 20-106.4 13.334-25.067 37.867-37.6 73.6-37.6 35.467 0 59.867 12.533 73.2 37.6 13.334 24.8 20 60.267 20 106.4 0 46.667-6.666 82.667-20 108-13.333 25.067-37.733 37.6-73.2 37.6-35.733 0-60.266-12.533-73.6-37.6-13.333-25.333-20-61.333-20-108zm169.2 0c0-26.667-2-49.2-6-67.6-3.733-18.4-11.2-32.933-22.4-43.6-10.933-10.933-26.666-16.4-47.2-16.4-20.533 0-36.4 5.467-47.6 16.4-10.933 10.667-18.4 25.2-22.4 43.6-3.733 18.4-5.6 40.933-5.6 67.6 0 27.2 1.867 50.133 5.6 68.8 4 18.4 11.467 33.067 22.4 44 11.2 10.933 27.067 16.4 47.6 16.4 20.534 0 36.267-5.467 47.2-16.4 11.2-10.933 18.667-25.6 22.4-44 4-18.667 6-41.6 6-68.8zM478.534 238v-14l152.4-197.2h20.4v195.6h45.6V238H478.534zm155.6-189.6l-134.4 174h134.4v-174z"
					fill="#000"
				/>
				<g clipPath="url(#clip0)">
					<path
						d="M218.662 69.587v7.143l-46.591 40.809-5.571-44.636V23.03c29.244.908 52.162 21.656 52.162 46.556z"
						fill="#FC604B"
						stroke="#FC604B"
						strokeWidth="4"
					/>
					<path
						d="M172.054 122.207l47.108-41.262v60.344l-47.108 41.301v-60.383z"
						fill="#4AC4E2"
						stroke="#4AC4E2"
						strokeWidth="3"
					/>
					<path
						d="M220.662 141.969v37.575c0 26.832-12.527 33.808-50.108 66.32V185.9l50.108-43.931z"
						fill="#03C5AF"
					/>
					<path
						d="M162.5 71.18c-5.849.38-11.333 2.422-16.179 5.732V47.34c0-6.872 2.136-13.02 5.497-17.409 2.89-3.773 6.625-6.185 10.682-6.785V71.18z"
						fill="#FFA68C"
						stroke="#FFA68C"
						strokeWidth="4"
					/>
					<path
						d="M143.157 267.686c-1.678 1.645-1.538 4.194.307 5.696a6507.69 6507.69 0 0116.348 13.328l2.755-8.671a6492.84 6492.84 0 00-13.037-10.616c-1.844-1.502-4.701-1.386-6.373.263zM189.06 269.41c2.291 1.989 2.277 5.204-.023 7.186-6.544 5.63-13.822 11.9-20.38 17.58l-4.157-10.781a7671.013 7671.013 0 0116.253-14.004c2.299-1.982 6.022-1.976 8.307.019z"
						fill="#03C5AF"
					/>
					<path
						d="M141.952 83.97l.004-.002c.901-.648 1.721-1.25 2.485-1.81 2.356-1.731 4.17-3.064 6.156-4.097 1.774-.922 3.665-1.58 6.118-1.87V239.04l-2.966-3.217c-10.443-11.323-17.63-19.116-22.473-26.459-5.819-8.822-8.169-16.9-8.169-29.819v-57.342c0-16.086 7.553-30.14 18.845-38.231z"
						fill="#9190B4"
						stroke="#9190B4"
						strokeWidth="6"
					/>
					<path
						d="M165.5 312c-5.247 0-9.5-1.969-9.5-4.399V76.665c0-2.43 4.253-4.665 9.5-4.665s9.5 2.236 9.5 4.665v230.936c0 2.43-4.253 4.399-9.5 4.399z"
						fill="#484969"
					/>
				</g>
				<g clipPath="url(#clip1)">
					<path
						d="M697.662 69.587v7.143l-46.591 40.809-5.571-44.636V23.03c29.244.908 52.162 21.656 52.162 46.556z"
						fill="#FC604B"
						stroke="#FC604B"
						strokeWidth="5"
					/>
					<path
						d="M651.054 122.207l47.108-41.262v60.344l-47.108 41.301v-60.383z"
						fill="#4AC4E2"
						stroke="#4AC4E2"
						strokeWidth="3"
					/>
					<path
						d="M699.662 141.969v37.575c0 26.832-12.527 33.808-50.108 66.32V185.9l50.108-43.931z"
						fill="#03C5AF"
					/>
					<path
						d="M641.5 71.18c-5.849.38-11.333 2.422-16.179 5.732V47.34c0-6.872 2.136-13.02 5.497-17.409 2.89-3.773 6.625-6.185 10.682-6.785V71.18z"
						fill="#FFA68C"
						stroke="#FFA68C"
						strokeWidth="4"
					/>
					<path
						d="M690.083 232.378c2.285 1.995 2.269 5.21-.037 7.186-.228.197-22.951 19.684-40.371 34.755l-4.157 3.59v-14.372a12978.243 12978.243 0 0136.259-31.191c2.306-1.976 6.023-1.963 8.306.032z"
						fill="#4AC4E2"
					/>
					<path
						d="M668.06 269.41c2.291 1.989 2.277 5.204-.023 7.186-6.544 5.63-13.822 11.9-20.38 17.58l-4.157-10.781a7671.013 7671.013 0 0116.253-14.004c2.299-1.982 6.022-1.976 8.307.019z"
						fill="#03C5AF"
					/>
					<path
						d="M620.952 83.97l.004-.002c.901-.648 1.721-1.25 2.485-1.81 2.356-1.731 4.17-3.064 6.156-4.097 1.774-.922 3.665-1.58 6.118-1.87V239.04l-2.966-3.217c-10.443-11.323-17.63-19.116-22.473-26.459-5.819-8.822-8.169-16.9-8.169-29.819v-57.342c0-16.086 7.553-30.14 18.845-38.231z"
						fill="#9190B4"
						stroke="#9190B4"
						strokeWidth="6"
					/>
					<path
						d="M644.5 312c-5.247 0-9.5-1.969-9.5-4.399V76.665c0-2.43 4.253-4.665 9.5-4.665s9.5 2.236 9.5 4.665v230.936c0 2.43-4.253 4.399-9.5 4.399z"
						fill="#484969"
					/>
				</g>
				<path
					d="M738.496 85l-8.996 4.053c-6.424 2.894-6.972 7.798-11.228 19.135l13.864-6.245L738.496 85z"
					fill="#03C5AF"
				/>
				<path
					d="M237.602 5.24l-1.928.869-9.402 18.313 17.018-3.985 11.216-8.735c-2.912-6.462-10.48-9.356-16.904-6.462zM729.602 32.24l-1.928.869-9.402 18.313 17.018-3.985 11.216-8.735c-2.912-6.462-10.48-9.356-16.904-6.462z"
					fill="#FC604B"
				/>

				<path
					d="M768.076 42l-15.402 6.938-9.402 18.314 18.444-8.31L768.076 42z"
					fill="#4AC4E2"
				/>
				<defs>
					<clipPath id="clip0">
						<path fill="#fff" transform="translate(108 21)" d="M0 0h113v291H0z" />
					</clipPath>
					<clipPath id="clip1">
						<path fill="#fff" transform="translate(587 21)" d="M0 0h113v291H0z" />
					</clipPath>
				</defs>
			</svg>
			<StyledH4>Blown in the wrong direction?</StyledH4>
			<StyledP>
				It looks like that page doesn't exist, or you don't have access to view it — please
				check the URL and try again.
			</StyledP>

			<Button type="button" onClick={setNavigation}>
				{setButtonText()}
			</Button>
		</StyledSvg>
	);
};

export default NotFound;
