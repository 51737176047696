import { gql } from 'graphql-request';

import { prefix } from './utils';

export const updateMutation = gql`
	mutation UpdateCourseMap($map: String!) {
		${prefix}update_course_map(where: { isDraft: { _eq: false } }, _set: { course_map_json: $map }) {
			affected_rows
		}
	}
`;

export const courseDisciplineMutation = gql`
	mutation InsertCourseDiscipline($courseId: uuid!, $disciplineId: uuid!) {
		${prefix}insert_courses_disciplines(
			objects: { discipline_id: $disciplineId, course_id: $courseId }
			on_conflict: {
				constraint: courses_disciplines_course_id_key
				update_columns: discipline_id
				where: { course_id: { _eq: $courseId } }
			}
		) {
			affected_rows
		}
	}
`;

export const courseDivisionMutation = gql`
	mutation InsertCourseDivision($courseId: uuid!, $divisionId: uuid!) {
		${prefix}insert_courses_divisions(
			objects: { division_id: $divisionId, course_id: $courseId }
			on_conflict: {
				constraint: courses_divisions_course_id_key
				update_columns: division_id
				where: { course_id: { _eq: $courseId } }
			}
		) {
			affected_rows
		}
	}
`;
