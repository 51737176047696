import styled from 'styled-components';

import { CloseIcon } from '@/icons/index';

export const StyledActionsContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;

	button:not(:last-child) {
		margin-right: var(--spacing-4);
	}
`;

export const StyledUtilsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const modalStyles: ReactModal.Styles = {
	content: {
		backgroundColor: 'var(--modal-bg)',
		border: 'none',
		inset: 'initial',
		margin: 'auto',
		maxHeight: '90vh',
		maxWidth: '900px',
		overflow: 'hidden auto',
		position: 'relative',
		width: '95%',
		height: 'auto',
	},
	overlay: {
		backgroundColor: 'var(--modal-overlay)',
		display: 'flex',
		justifyContent: 'center',
		zIndex: 1,
	},
};

export const StyledModalBody = styled.div`
	margin-bottom: var(--spacing-8);

	form {
		margin-bottom: var(--spacing-8);
	}

	.input-container {
		margin-bottom: var(--spacing-4);
	}

	.error-message {
		margin-bottom: var(--spacing-4);
		margin-top: calc(var(--spacing-4) * -1);
	}

	.preview-wrapper {
		margin-bottom: var(--spacing-6);
		margin-top: var(--spacing-6);
		max-width: 250px;
		min-height: 175px;
	}

	img {
		object-fit: contain;
		width: 100%;
	}
`;

export const StyledFooterBody = styled.div`
	text-align: right;
`;

export const StyledModalHeader = styled.div`
	align-items: center;
	box-shadow: 0px 2px 0px -1px rgb(50 50 50 / 10%);
	display: flex;
	margin-bottom: var(--spacing-8);
	padding-bottom: var(--spacing-4);
`;

export const StyledCloseIcon = styled(CloseIcon as any)`
	background-color: var(--tab-group-color);
`;

export const StyledNotification = styled.div`
	background-color: var(--warning-bg-color);
	border-radius: 6px;
	bottom: var(--spacing-4);
	color: var(--warning-text-color);
	display: flex;
	margin-bottom: var(--spacing-4);
	padding: 4px var(--spacing-4);

	svg {
		fill: var(--warning-text-color);
		margin-right: var(--spacing-4);
		width: 40px;
	}
`;

export const StyledImagePreviewContainer = styled.div`
	display: inline-flex;
	height: 175px;
	margin-bottom: var(--spacing-6);
	margin-top: var(--spacing-6);
	max-width: 250px;
	position: relative;

	img {
		object-fit: contain;
	}

	button {
		right: -40px;
	}
`;
