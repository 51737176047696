import React from 'react';

import { NoDataIcon } from '@/icons/index';

import ParentMapContent from './ParentMapContent';

interface DescriptionTabProps {
	courseDescription?: string;
	courseExtras?: string;
	coursePrereq?: string;
}

const DescriptionTab = ({ courseDescription, courseExtras, coursePrereq }: DescriptionTabProps) => {
	const contentArr = [courseDescription, courseExtras, coursePrereq];
	const isEmpty = contentArr.every((val: string | undefined) => val === null);

	if (isEmpty) {
		return (
			<div className="panel-content">
				<NoDataIcon message="This course does not have a listed description." delay={0} />
			</div>
		);
	}

	return (
		<>
			<ParentMapContent content={courseDescription} />
			<ParentMapContent content={coursePrereq} header="Prerequisites" />
			<ParentMapContent content={courseExtras} header="Additional Information" />
		</>
	);
};

export default DescriptionTab;
