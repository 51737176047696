import { driver } from 'driver.js';
import { createGlobalStyle } from 'styled-components';
import 'driver.js/dist/driver.css';

import elevateLogo from '@/assets/logo.svg';
import { useCurrentWidth } from '@/hooks/useCurrentWidth';
import { isLocalStorageAvailable } from '@/utils';

const GlobalStepsStyle = createGlobalStyle`
	.driver-popover-close-btn {
		border-radius: 50%;
		color: var(--elevate-primary);
		flex-shrink: 0;
		font-weight: 500;
		height: 25px;
		width: 25px;
		margin: 4px;

		&:focus,
		&:hover {
			background-color: var(--input-border);
		}
	}

	.driver-popover-title {
		color: var(--elevate-primary);
		font-family: var(--font-semibold);
	}

	.driver-popover {
		padding: 24px 16px;
	}

	.driver-popover-description {
		color: var(--elevate-primary);
		font-family: var(--font-regular);

		img {
			max-width: 200px;
			margin: auto;
		}
	}

	.driver-popover-navigation-btns button+button {
		margin-left: var(--spacing-4);
	}

	.driver-popover-footer button {
		border-radius: 6px;
		border: 2px solid;
		cursor: pointer;
		font-family: var(--font-semibold);
		font-size: 15px;
		line-height: 1;
		padding: var(--spacing-2) var(--spacing-4);
		text-shadow: none;
		transition: background-color 0.3s, box-shadow 0.3s, color 0.3s, border-color 0.3s;

		&:hover,
		&:focus {
			background-color: var(--wine-btn-active);
			border-color: var(--wine-btn-active);
			box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
				color: var(--c-white);
			outline: none;
		}
	}

	button.driver-popover-next-btn {
		background-color: var(--wine-btn);
		border-color: var(--wine-btn);
		color: var(--neutral-50);
		cursor: pointer;
	}

	button.driver-popover-prev-btn {
		background-color: transparent;
		border-color: var(--wine-btn);
		color: var(--wine-btn);
	}

	.has-default-height {
		display: flex !important;
		flex-direction: column;
		min-height: 275px;

		.driver-popover-footer {
			margin-top: auto;
		}
	}
`;

interface ParentAlignmentIntroProps {
	isEnabled: boolean;
	setIsEnabled: (value: boolean) => void;
}

const ParentAlignmentIntro = ({ isEnabled, setIsEnabled }: ParentAlignmentIntroProps) => {
	const windowWidth = useCurrentWidth();
	const isMobile = windowWidth < 900;

	// save user's choice to local storage
	const handleExit = () => {
		if (isLocalStorageAvailable()) {
			localStorage.setItem('elevateCourseAlignmentTutorial', 'false');
		}
		setIsEnabled(false);
	};

	const driverObj = driver({
		showProgress: true,
		nextBtnText: 'Next',
		prevBtnText: 'Previous',
		onDestroyed: () => {
			handleExit();
		},
		steps: [
			{
				popover: {
					popoverClass: 'has-default-height',
					description: `<img src=${elevateLogo}><br />Welcome to Elevate - a custom curriculum mapping software. Press next or the right arrow key to learn more.`,
					align: 'center',
				},
			},
			{
				popover: {
					popoverClass: 'has-default-height',
					title: 'Privacy Consent',
					description: `By using this application, you consent to the following: The information on this site may not be reproduced, transmitted, or copied without the express written permission of Stakk IO.`,
					align: 'center',
				},
			},
			{
				element: '.toggle-btn',
				popover: {
					title: 'Open/Close Settings',
					description: 'Use this button to open/close the alignment settings.',
					align: 'start',
					onNextClick: () => {
						const legend = document.querySelector('.course-alignment');
						if (!legend?.classList.contains('active-legend')) {
							legend?.classList.add('active-legend');
						}
						driverObj.moveNext();
					},
				},
			},
			{
				element: '.expand-btn',
				popover: {
					title: 'Expand Button',
					description: 'Use this button to expand/collapse the alignment area.',
					align: 'start',
					onNextClick: () => {
						if (isMobile) {
							const legend = document.querySelector('.course-alignment');
							legend?.classList.add('active-legend');
						}
						driverObj.moveNext();
					},
				},
			},
			{
				element: '.legend--intro',
				popover: {
					title: 'Settings',
					description:
						'Use the checkboxes to filter courses. You can also show/hide course information.',
					align: 'center',
					onNextClick: () => {
						if (isMobile) {
							const legend = document.querySelector('.course-alignment');
							legend?.classList.remove('active-legend');
						}
						driverObj.moveNext();
					},
				},
			},
			{
				element: '.alignment-container',
				popover: {
					title: 'Alignment Track',
					description:
						'All selected courses appear here. This area is scrollable both vertically and horizontally.',
					align: 'start',
				},
			},
			{
				element: isMobile ? '.search--intro input' : '.search--intro',
				popover: {
					title: 'Search Bar',
					description:
						'If you are looking for a specific course, it may be easier to find it via the search tool. Click done and give it a try!',
					align: 'center',
				},
			},
		],
	});

	if (isEnabled) {
		driverObj.drive();
	}

	return <GlobalStepsStyle />;
};

export default ParentAlignmentIntro;
