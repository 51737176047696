import { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import dompurify from 'dompurify';
import scrollIntoView from 'scroll-into-view';

import { rebuildTooltip } from '@/components/TaggedWordTooltip/TaggedWordTooltip';
import { Button } from '@/components/ui/button';
import { StyledOrderedList } from '@/Shared/StyledElements';
import { StyledH5, StyledP, StyledSubHeader } from '@/Shared/Typography/typography';
import { Details } from '@/stores/alignmentStore';
import { getFormattedDate, hasContent, sanitizeOptions } from '@/utils';

import FocusEntry from '../FocusEntry';

import { StyledCourseCard, StyledCourseCardHeader } from './CourseCard.Styles';

interface CourseCardProps {
	courseName: string;
	courseDivision: string;
	className: string;
	courseData: any;
	details: Details;
}

const OutcomeContent = ({ courseData }: any) => {
	if (!courseData?.courses_outcomes) {
		return null;
	}
	const sanitizer = dompurify.sanitize;

	return (
		<div className="outcomes-block">
			{courseData?.courses_outcomes?.length !== 0 && <StyledH5 mb="16px">Outcomes:</StyledH5>}
			<StyledOrderedList icon="bullseye">
				{courseData?.courses_outcomes
					.sort(
						(a: CoursesOutcomesEntity, b: CoursesOutcomesEntity) =>
							a.outcome.outcome_order - b.outcome.outcome_order
					)
					.map(({ outcome }: CoursesOutcomesEntity) => {
						const { outcome_id: id, outcome_text: text } = outcome;
						return (
							<li
								key={id}
								dangerouslySetInnerHTML={{
									__html: sanitizer(`${text}`, {
										...sanitizeOptions,
									}),
								}}
							/>
						);
					})}
			</StyledOrderedList>
		</div>
	);
};

const DescriptionContent = ({ courseData }: any) => {
	const sanitizer = dompurify.sanitize;

	return (
		<div className="description-block">
			{hasContent(courseData?.course_description) && (
				<>
					<StyledH5 mb="16px">Description:</StyledH5>
					<StyledP
						dangerouslySetInnerHTML={{
							__html: sanitizer(`${courseData?.course_description}`, {
								...sanitizeOptions,
							}),
						}}
					/>
				</>
			)}
			{hasContent(courseData?.course_prereq) && (
				<>
					<StyledH5 mb="16px">Prerequisites:</StyledH5>
					<StyledP
						dangerouslySetInnerHTML={{
							__html: sanitizer(`${courseData?.course_prereq}`, {
								...sanitizeOptions,
							}),
						}}
					/>
				</>
			)}
			{hasContent(courseData?.course_extras) && (
				<>
					<StyledH5 mb="16px">Additional Information:</StyledH5>
					<StyledP
						dangerouslySetInnerHTML={{
							__html: sanitizer(`${courseData?.course_extras}`, {
								...sanitizeOptions,
							}),
						}}
					/>
				</>
			)}
		</div>
	);
};

const ResourcesContent = ({ courseData }: any) => {
	const sanitizer = dompurify.sanitize;
	const mediaResources = courseData?.courses_resources?.filter(
		({ resource }: CoursesResourcesEntity) => resource.resource_type === 'media'
	);
	const textResources = courseData?.courses_resources?.filter(
		({ resource }: CoursesResourcesEntity) => resource.resource_type === 'textbook'
	);
	const hasMediaResources = mediaResources?.length !== 0;
	const hasTextResources = textResources?.length !== 0;

	return (
		<div className="resources-block">
			{hasContent(courseData?.course_resource_info) && (
				<>
					<StyledH5 mb="16px">Resources:</StyledH5>

					<StyledP
						dangerouslySetInnerHTML={{
							__html: sanitizer(`${courseData?.course_resource_info}`, {
								...sanitizeOptions,
							}),
						}}
					/>
				</>
			)}
			{hasMediaResources &&
				mediaResources?.map(({ resource }: CoursesResourcesEntity, idx: number) => {
					const {
						resource_id: id,
						resource_title: title,
						resource_author: author,
						resource_detail: detail,
					} = resource;
					return (
						<Fragment key={id}>
							{idx === 0 && <StyledH5 mb="16px">Media</StyledH5>}
							<div style={{ marginBottom: '24px' }}>
								{title && <StyledP mb="0">Title: {title}</StyledP>}
								{author && <StyledP mb="0">Author: {author}</StyledP>}
								{detail && (
									<StyledP mb="0">
										URL:{' '}
										<a
											className="media-link"
											href={detail}
											rel="noreferrer"
											target="_blank">
											Media Link
											<svg
												style={{
													marginLeft: '4px',
												}}
												fill="var(--org-color)"
												width="14"
												viewBox="0 0 512 512"
												xmlns="http://www.w3.org/2000/svg">
												<path d="M464 24H336a24 24 0 000 48h70L176 302a24 24 0 0034 34l230-230v70a24 24 0 0048 0V48a24 24 0 00-24-24z" />
												<path d="M464 248a24 24 0 00-24 24v168H72V72h168a24 24 0 0024-24 24 24 0 00-24-24H48a24 24 0 00-24 24v416a24 24 0 0024 24h416a24 24 0 0024-24V272a24 24 0 00-24-24z" />
											</svg>
										</a>
									</StyledP>
								)}
							</div>
						</Fragment>
					);
				})}
			{hasTextResources &&
				textResources?.map(({ resource }: CoursesResourcesEntity, idx: number) => {
					const {
						resource_id: id,
						resource_title: title,
						resource_isbn: isbn,
						resource_author: author,
						resource_detail: detail,
					} = resource;

					return (
						<Fragment key={id}>
							{idx === 0 && <StyledH5 mb="16px">Textbooks</StyledH5>}
							<div style={{ marginBottom: '24px' }}>
								{title && (
									<StyledP mb="0" fontSize="1.8rem">
										{title}
									</StyledP>
								)}
								{author && <StyledP mb="0">By: {author}</StyledP>}
								{detail && <StyledP mb="0">Description: {detail}</StyledP>}
								{isbn && <StyledP mb="0">ISBN: {isbn}</StyledP>}
							</div>
						</Fragment>
					);
				})}
		</div>
	);
};

const UnitsContent = ({ courseData }: any) => {
	const sanitizer = dompurify.sanitize;

	return (
		<div className="units-focus-block">
			{courseData?.courses_focuses?.length !== 0 && (
				<StyledH5 mb="16px">Units of Focus:</StyledH5>
			)}
			<StyledOrderedList icon="brain">
				{courseData?.courses_focuses
					.sort(
						(a: CourseFocusEntity, b: CourseFocusEntity) =>
							a.focus.focus_order - b.focus.focus_order
					)
					.map(({ focus }: CourseFocusEntity) => {
						const { focus_id: id, focus_title: title, focuses_skills: skills } = focus;

						return (
							<Fragment key={id}>
								<li>
									<StyledP
										mb="16px"
										dangerouslySetInnerHTML={{
											__html: sanitizer(title, { ...sanitizeOptions }),
										}}
									/>
									<div className="sub-list">
										<FocusEntry
											type="question"
											options={skills}
											title="Essential Questions"
										/>
										<FocusEntry
											type="topic"
											options={skills}
											title="Subtopics/Descriptions"
										/>
										<FocusEntry type="skill" options={skills} title="Skills" />
									</div>
								</li>
							</Fragment>
						);
					})}
			</StyledOrderedList>
		</div>
	);
};

const CourseCard = ({
	courseName,
	className,
	courseDivision,
	courseData,
	details,
}: CourseCardProps) => {
	const cardRef = useRef<any>(null);
	const [isOpen, setIsOpen] = useState(false);

	function setClasses() {
		if (isOpen) {
			return `${className} ${courseData?.course_division}-card course-${courseData?.course_id} open-card`;
		}
		return `${className} ${courseData?.course_division}-card course-${courseData?.course_id}`;
	}

	const [maxHeight, setMaxHeight] = useState(400);
	const measuredRef = useCallback((node: any) => {
		if (node !== null) {
			cardRef.current = node;
			setMaxHeight(node.parentElement.clientHeight);
		}
	}, []);

	useEffect(() => {
		rebuildTooltip();
	}, [isOpen]);

	function toggleOpen(this: any) {
		setIsOpen(!isOpen);
		if (isOpen) {
			const scrollToCard = (cardElm: Element) => {
				scrollIntoView(cardElm as HTMLElement, {
					time: 0,
					align: {
						topOffset: 0,
					},
				});
			};
			scrollToCard(cardRef.current);
		}
	}

	const showContent = () => {
		if (!isOpen && details.showDescription) {
			return <DescriptionContent courseData={courseData} />;
		}
		if (!isOpen && details.showOutcomes) {
			return <OutcomeContent courseData={courseData} />;
		}
		if (!isOpen && details.showResources) {
			return <ResourcesContent courseData={courseData} />;
		}
		if (!isOpen && details.showUnits) {
			return <UnitsContent courseData={courseData} />;
		}

		return null;
	};

	return (
		<StyledCourseCard className={setClasses()} ref={measuredRef} maxHeight={maxHeight}>
			<Button
				type="button"
				size="xs"
				onClick={() => toggleOpen()}
				className="
        absolute
        bottom-2
        right-2
        z-10
        bg-[var(--org-color)]
        hover:bg-[var(--org-color-alt)]
        focus-visible:bg-[var(--org-color-alt)]
        hover:shadow-md
        focus-visible:shadow-md
        focus-visible:outline-none
        transition-all duration-300 ease-in-out
      ">
				{isOpen ? 'View Less' : 'View More'}
			</Button>
			<div className="content">
				<StyledCourseCardHeader>
					<StyledH5 mb="0" className="card-course-name">
						{courseName}
					</StyledH5>
					<StyledSubHeader mb="0">{courseDivision}</StyledSubHeader>
					{courseData?.last_updated && (
						<time className="date" dateTime={courseData.last_updated}>
							Last Updated {getFormattedDate(courseData.last_updated)}
						</time>
					)}
				</StyledCourseCardHeader>
				{showContent()}
				{isOpen && (
					<>
						{details.showDescription && <DescriptionContent courseData={courseData} />}
						{details.showOutcomes && <OutcomeContent courseData={courseData} />}
						{details.showResources && <ResourcesContent courseData={courseData} />}
						{details.showUnits && <UnitsContent courseData={courseData} />}
					</>
				)}
			</div>
		</StyledCourseCard>
	);
};

export default CourseCard;
