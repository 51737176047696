import { Fragment, useReducer, useContext, useRef, useEffect, useState } from 'react';

import debounce from 'just-debounce-it';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import FadeIn from '@/components/animations/FadeIn';
import ButtonGroup from '@/components/ButtonGroup/ButtonGroup';
import TiptapEditor from '@/components/TipTapEditor/TiptapEditor';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { CourseResourceEnum, EditorType } from '@/Enums/enum';
import useSaveDraft from '@/hooks/useSaveDraft';
import { TrashCanIcon } from '@/icons/index';
import { StyledTextArea } from '@/Shared/StyledElements';
import { StyledH5 } from '@/Shared/Typography/typography';
import { useCourseEditStore } from '@/stores/courseEditStore';

import ApprovalCommentForm from '../CourseDescriptionTab/ApprovalCommentForm';
import CourseDiff from '../CourseDiff';
import CourseEditContext from '../CourseEditContext';

import CourseResourcePlaceholder from './CourseResourcePlaceholder';
import {
	StyledResourceGroup,
	StyledResource,
	StyledForm,
	StyledActionGroup,
} from './CourseResourcesTab.Styles';

const StyledTiptapEditor = styled(TiptapEditor)`
	margin-bottom: var(--spacing-6);
`;

const CourseResourcesTab = () => {
	const {
		courseData,
		removeResource,
		addResource,
		updateResources,
		canEdit,
		saveDraft,
		submitCourse,
		updateResourceInfo,
		courseDraftData,
		isApprovalMode,
		updateCourseReducer,
	} = useContext(CourseEditContext);
	const { courses_resources: coursesResources, course_resource_info: courseResourceInfo } =
		courseData;
	const {
		courses_resources: coursesDraftResources,
		course_resource_info: courseDraftResourceInfo,
	} = courseDraftData;
	const { updateCommentTypes, dynamicDoc, dynamicProvider, providers, docTypes } =
		useCourseEditStore();

	const ref = useRef(null);
	const inputFocusRef = useRef(false);
	const { register, handleSubmit, watch } = useForm({
		shouldUnregister: true,
	});
	const initialType = { show: CourseResourceEnum.All };

	const typeReducer = (
		state: { show: CourseResourceEnum },
		action: { type: CourseResourceEnum }
	) => {
		switch (action.type) {
			case CourseResourceEnum.Media:
				return { show: CourseResourceEnum.Media };
			case CourseResourceEnum.Textbook:
				return { show: CourseResourceEnum.Textbook };
			case CourseResourceEnum.Info:
				return { show: CourseResourceEnum.Info };
			default:
				return { show: CourseResourceEnum.All };
		}
	};

	const [state, dispatch] = useReducer(typeReducer, initialType);
	const handleResourcesUpdate = debounce(() => {
		const watchAllFields = watch();
		const formEntires = Object.entries(watchAllFields);
		const isType = (val: string, comp: string) => val.includes(comp);
		const clone: CoursesResourcesEntity[] = [];
		let sub: CoursesResourcesEntity = {
			course_resources_id: '',
			resource: {
				resource_author: '',
				resource_detail: '',
				resource_isbn: '',
				resource_id: '',
				resource_title: '',
				resource_type: '',
			},
		};

		formEntires.forEach((entry: string[]) => {
			const [prop, val] = entry;
			if (isType(prop, 'id')) {
				sub.resource.resource_id = val;
				sub.resource.resource_type = isType(prop, 'media') ? 'media' : 'textbook';
			}
			if (isType(prop, 'author')) {
				sub.resource.resource_author = val;
			}
			if (isType(prop, 'source') || isType(prop, 'description')) {
				sub.resource.resource_detail = val;
			}
			if (isType(prop, 'title')) {
				sub.resource.resource_title = val;
			}
			if (isType(prop, 'isbn')) {
				sub.resource.resource_isbn = val;
			}
			if (isType(prop, 'parent')) {
				sub.course_resources_id = val;
				clone.push(sub);
				sub = {
					course_resources_id: '',
					resource: {
						resource_author: '',
						resource_detail: '',
						resource_id: '',
						resource_isbn: '',
						resource_title: '',
						resource_type: '',
					},
				};
			}
		});

		let remainingResources: CoursesResourcesEntity[] = [];
		if (state.show === CourseResourceEnum.Media) {
			remainingResources =
				coursesDraftResources?.filter(
					(resource: CoursesResourcesEntity) =>
						resource.resource.resource_type !== CourseResourceEnum.Media
				) ?? [];
		}
		if (state.show === CourseResourceEnum.Textbook) {
			remainingResources =
				coursesDraftResources?.filter(
					(resource: CoursesResourcesEntity) =>
						resource.resource.resource_type !== CourseResourceEnum.Textbook
				) ?? [];
		}
		const combinedResources = [...remainingResources, ...clone];

		updateResources(combinedResources);
	}, 500);

	const onResourceChange = debounce((value: string) => {
		updateResourceInfo(value);
	}, 500);

	const [isCommented, setIsCommented] = useState(false);
	// once a comment is added, state is updated and the draft is saved
	const saveAfterCommenting = (value: string) => {
		updateCourseReducer(value, EditorType.RESOURCES);
		setIsCommented(!isCommented);
	};
	// once a comment is added, save a draft using hook
	useSaveDraft(isCommented, saveDraft, setIsCommented);

	const setFilterType = (type: string) =>
		coursesDraftResources?.filter(
			({ resource }: CoursesResourcesEntity) => resource.resource_type === type
		) ?? [];

	const publishedMedia =
		coursesResources?.filter(
			({ resource }: CoursesResourcesEntity) =>
				resource.resource_type === CourseResourceEnum.Media
		) ?? [];

	const draftMedia =
		coursesDraftResources?.filter(
			({ resource }: CoursesResourcesEntity) =>
				resource.resource_type === CourseResourceEnum.Media
		) ?? [];

	const publishedTextbook =
		coursesResources?.filter(
			({ resource }: CoursesResourcesEntity) =>
				resource.resource_type === CourseResourceEnum.Textbook
		) ?? [];

	const draftTextbook =
		coursesDraftResources?.filter(
			({ resource }: CoursesResourcesEntity) =>
				resource.resource_type === CourseResourceEnum.Textbook
		) ?? [];

	const handleFilter = (type: CourseResourceEnum) => {
		dispatch({ type });
	};

	const setNewResource = (type: string) => {
		addResource(type);
		inputFocusRef.current = true;
	};

	const showButtonType = (type: string) =>
		state.show === type || state.show === CourseResourceEnum.All;

	const onSubmit = () => submitCourse();

	const renderMediaDiffContent = (content: CoursesResourcesEntity) => {
		return `Title: ${content?.resource.resource_title ?? ''} </br> Author: ${
			content?.resource.resource_author ?? ''
		} </br> Source:  ${content?.resource.resource_detail ?? ''}`;
	};

	const renderTextbookDiffContent = (content: CoursesResourcesEntity) => {
		return `Title: ${content?.resource.resource_title ?? ''} </br> ISBN: ${
			content?.resource.resource_isbn ?? ''
		} </br> Author:  ${content?.resource.resource_author ?? ''}  </br> Description:  ${
			content?.resource.resource_detail ?? ''
		}`;
	};

	useEffect(() => {
		updateCommentTypes([EditorType.RESOURCES]);
		dynamicDoc(courseData.course_id, EditorType.RESOURCES);
		dynamicProvider(courseData.course_id, EditorType.RESOURCES);
	}, [courseData.course_id, dynamicDoc, dynamicProvider, updateCommentTypes]);

	return (
		<>
			{isApprovalMode && (
				<ApprovalCommentForm courseId={courseData.course_id} type={EditorType.RESOURCES} />
			)}
			<ButtonGroup filterEvent={handleFilter} types={CourseResourceEnum} />
			<FadeIn style={{ width: '100%' }}>
				<>
					<div style={{ width: '100%' }}>
						<StyledForm
							onSubmit={handleSubmit(onSubmit)}
							ref={ref}
							onChange={handleResourcesUpdate}>
							{[CourseResourceEnum.Info, CourseResourceEnum.All].includes(
								state.show
							) && (
								<>
									{!isApprovalMode &&
										docTypes[
											`${courseData.course_id}${EditorType.RESOURCES}`
										] && (
											<StyledTiptapEditor
												content={courseDraftResourceInfo}
												onChange={onResourceChange}
												placeholder="Add resource additional information..."
												type={EditorType.RESOURCES}
												isEditable={canEdit || !isApprovalMode}
												id={courseData.course_id}
												commentAddedCallback={saveAfterCommenting}
												providerRef={
													providers[
														`${courseData.course_id}${EditorType.RESOURCES}`
													]
												}
												documentRef={
													docTypes[
														`${courseData.course_id}${EditorType.RESOURCES}`
													]
												}
											/>
										)}
									<CourseDiff
										title="Resource Additional Info"
										original={courseResourceInfo}
										draft={courseDraftResourceInfo}
									/>
								</>
							)}
							{[CourseResourceEnum.Media, CourseResourceEnum.All].includes(
								state.show
							) && (
								<StyledResourceGroup>
									{setFilterType(CourseResourceEnum.Media).map(
										(resource: CoursesResourcesEntity, i: number) => {
											const { course_resources_id: courseResourceId } =
												resource;
											const {
												resource_author: resourceAuthor,
												resource_detail: resourceDetail,
												resource_title: resourceTitle,
												resource_id: resourceId,
											} = resource.resource;

											return (
												<Fragment key={courseResourceId}>
													{i === 0 ? (
														<StyledH5 mb="16px">Media</StyledH5>
													) : null}
													<StyledResource data-testid={`media-${i}`}>
														<div className="input-wrap">
															{!isApprovalMode && (
																<Input
																	type="text"
																	defaultValue={resourceTitle}
																	placeholder="Title"
																	readOnly={
																		!canEdit || isApprovalMode
																	}
																	autoFocus={
																		inputFocusRef.current
																	}
																	{...register(
																		`media-title-${courseResourceId}`
																	)}
																/>
															)}
															<CourseDiff
																title="Title"
																original={renderMediaDiffContent(
																	publishedMedia[i]
																)}
																draft={renderMediaDiffContent(
																	draftMedia[i]
																)}
															/>
															{!isApprovalMode && (
																<Input
																	type="text"
																	defaultValue={resourceAuthor}
																	placeholder="Author"
																	readOnly={
																		!canEdit || isApprovalMode
																	}
																	{...register(
																		`media-author-${courseResourceId}`
																	)}
																/>
															)}
															{!isApprovalMode && (
																<Input
																	type="url"
																	defaultValue={resourceDetail}
																	placeholder="Source"
																	readOnly={
																		!canEdit || isApprovalMode
																	}
																	{...register(
																		`media-source-${courseResourceId}`
																	)}
																/>
															)}
															{!isApprovalMode && (
																<>
																	<input
																		readOnly
																		hidden
																		value={resourceId}
																		{...register(
																			`media-id-${courseResourceId}`
																		)}
																	/>
																	<input
																		readOnly
																		hidden
																		value={courseResourceId}
																		{...register(
																			`media-parent-${courseResourceId}`
																		)}
																	/>
																</>
															)}
														</div>
														{canEdit && !isApprovalMode && (
															<TrashCanIcon
																passedEvent={() =>
																	removeResource(courseResourceId)
																}
															/>
														)}
													</StyledResource>
												</Fragment>
											);
										}
									)}
								</StyledResourceGroup>
							)}

							{[CourseResourceEnum.Textbook, CourseResourceEnum.All].includes(
								state.show
							) && (
								<StyledResourceGroup>
									{setFilterType(CourseResourceEnum.Textbook).map(
										(resource: CoursesResourcesEntity, i: number) => {
											const { course_resources_id: courseResourceId } =
												resource;
											const {
												resource_author: resourceAuthor,
												resource_detail: resourceDetail,
												resource_title: resourceTitle,
												resource_isbn: resourceISBN,
												resource_id: resourceId,
											} = resource.resource;

											return (
												<Fragment key={courseResourceId}>
													{i === 0 ? (
														<StyledH5 mb="16px">Textbooks</StyledH5>
													) : null}
													<StyledResource data-testid={`textbook-${i}`}>
														<div className="input-wrap">
															{!isApprovalMode && (
																<Input
																	type="text"
																	defaultValue={resourceTitle}
																	placeholder="Title"
																	readOnly={
																		!canEdit || isApprovalMode
																	}
																	{...register(
																		`textbook-title-${courseResourceId}`
																	)}
																	autoFocus={
																		inputFocusRef.current
																	}
																/>
															)}
															<CourseDiff
																title="Title"
																original={renderTextbookDiffContent(
																	publishedTextbook[i]
																)}
																draft={renderTextbookDiffContent(
																	draftTextbook[i]
																)}
															/>
															{!isApprovalMode && (
																<Input
																	type="text"
																	defaultValue={resourceISBN}
																	placeholder="ISBN"
																	readOnly={
																		!canEdit || isApprovalMode
																	}
																	{...register(
																		`textbook-isbn-${courseResourceId}`
																	)}
																/>
															)}
															{!isApprovalMode && (
																<Input
																	type="text"
																	defaultValue={resourceAuthor}
																	placeholder="Author"
																	readOnly={
																		!canEdit || isApprovalMode
																	}
																	{...register(
																		`textbook-author-${courseResourceId}`
																	)}
																/>
															)}
															{!isApprovalMode && (
																<StyledTextArea
																	readOnly={
																		!canEdit || isApprovalMode
																	}
																	defaultValue={resourceDetail}
																	placeholder="Description"
																	{...register(
																		`textbook-description-${courseResourceId}`
																	)}
																/>
															)}
															{!isApprovalMode && (
																<>
																	<input
																		readOnly
																		hidden
																		value={resourceId}
																		{...register(
																			`textbook-id-${courseResourceId}`
																		)}
																	/>
																	<input
																		readOnly
																		hidden
																		value={courseResourceId}
																		{...register(
																			`textbook-parent-${courseResourceId}`
																		)}
																	/>
																</>
															)}
														</div>
														{canEdit && !isApprovalMode && (
															<TrashCanIcon
																passedEvent={() =>
																	removeResource(courseResourceId)
																}
															/>
														)}
													</StyledResource>
												</Fragment>
											);
										}
									)}
								</StyledResourceGroup>
							)}
						</StyledForm>
						<CourseResourcePlaceholder
							isApprovalMode={isApprovalMode}
							state={state}
							textbookLength={setFilterType(CourseResourceEnum.Textbook).length}
							mediaLength={setFilterType(CourseResourceEnum.Media).length}
						/>
					</div>
					{canEdit && !isApprovalMode && (
						<StyledActionGroup>
							{showButtonType(CourseResourceEnum.Media) && (
								<Button
									variant="secondary"
									size="small"
									onClick={() => setNewResource(CourseResourceEnum.Media)}>
									Add New Media
								</Button>
							)}
							{showButtonType(CourseResourceEnum.Textbook) && (
								<Button
									variant="secondary"
									size="small"
									onClick={() => setNewResource(CourseResourceEnum.Textbook)}>
									Add New Textbook
								</Button>
							)}
						</StyledActionGroup>
					)}
				</>
			</FadeIn>
		</>
	);
};

export default CourseResourcesTab;
