import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';

import { AllCoursesQuery } from '@/graphql/graphql';
import { prefix, renameAndDestructure } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

const createHeader = async () => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', 'admin');
	return {
		Authorization: `Bearer ${token}`,
	};
};

const ALL_COURSES = gql`
	query AllCourses {
		${prefix}courses(order_by: { course_name: asc }) {
			course_name
			course_id
			course_division
			is_saved_draft
			is_archived
			submission {
				admin_approval
				dept_approval
			}
			courses_discipline {
				discipline {
					name
				}
			}
			courses_division {
				division {
					name
				}
			}
			courses_users {
				user_id
				course_id
				user {
					user_id
					user_first
					user_last
					dept_chair {
						user_id
					}
				}
			}
		}
	}
`;

export const getAllCourses = async () => {
	await createHeader();
	const data = await graphQLClient.request<AllCoursesQuery>(ALL_COURSES);
	const { courses } = renameAndDestructure(data, prefix) as {
		courses: AllCoursesQuery['dev_courses'];
	};

	return courses;
};
