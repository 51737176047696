import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { StyledH6 } from '@/Shared/Typography/typography';

const StyledIcon = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 50vh;
	justify-content: center;
	margin: auto;
	max-width: 300px;
	text-align: center;
	width: 100%;
`;

interface NoDataIconProps {
	message?: string;
	delay?: number;
}

const NoDataIcon = ({
	message = 'No information is available...',
	delay = 900,
}: NoDataIconProps) => {
	const [showNoData, setShowNoData] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => setShowNoData(true), delay);

		return () => clearTimeout(timer);
	});

	if (!showNoData) return null;

	return (
		<>
			<StyledIcon>
				<svg width="150" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
					<path
						fill="#737295"
						d="M432.8 190.1a86.6 86.6 0 00-120.7-75.8A127.5 127.5 0 0099 96.4a7.5 7.5 0 1011.8 9.3A112.6 112.6 0 01302 127.5a7.5 7.5 0 0010.3 3.4 71.8 71.8 0 01105.5 66 7.5 7.5 0 007.7 7.9 71.6 71.6 0 0171.5 74 59.8 59.8 0 01-19 41.6c-11.8 11-27 17.1-43.2 17.1h-64.3a123.9 123.9 0 00-123.7-120.2c-67 0-121.8 53.6-123.7 120.2h-46c-16 0-31.3-6-43-17A59.8 59.8 0 0115 276.1 71.6 71.6 0 0181.8 205a7.5 7.5 0 006.8-9 113.5 113.5 0 016.6-64 7.5 7.5 0 00-13.8-5.8 125.7 125.7 0 00-8.7 64.7A86.7 86.7 0 000 279.2a74.7 74.7 0 0023.7 52.1 77.6 77.6 0 0053.4 21.2h46.4a123.9 123.9 0 00207.6 79 7.5 7.5 0 00-10.2-11 108.9 108.9 0 11-74.2-188.2 108.9 108.9 0 0193 165 7.5 7.5 0 1012.9 7.8c9.7-16 15.6-34 17.3-52.6h64.8c20 0 39-7.5 53.4-21.2a74.7 74.7 0 0023.8-55 86.6 86.6 0 00-79.2-86.2z"
					/>
					<path
						fill="#d32f2f"
						d="M298.3 320.9L271 293.5a7.5 7.5 0 00-10.6 0L246.8 307l-13.5-13.5a7.5 7.5 0 00-10.6 0l-27.4 27.4c-3 2.9-3 7.6 0 10.6l13.5 13.5-13.5 13.5c-3 3-3 7.7 0 10.6l27.4 27.4a7.5 7.5 0 0010.6 0l13.5-13.5 13.5 13.5a7.5 7.5 0 0010.6 0l27.4-27.4c3-2.9 3-7.6 0-10.6L284.8 345l13.5-13.5c3-3 3-7.7 0-10.6zM269 350.3l13.5 13.5-16.8 16.8-13.5-13.5a7.5 7.5 0 00-10.6 0L228 380.6l-16.8-16.8 13.5-13.5c3-3 3-7.7 0-10.6l-13.5-13.5 16.8-16.8 13.5 13.5a7.5 7.5 0 0010.6 0l13.5-13.5 16.8 16.8-13.5 13.5c-3 3-3 7.7 0 10.6z"
					/>
				</svg>
				<span className="screen-reader-text">No Data Icon</span>
				<StyledH6>{message}</StyledH6>
			</StyledIcon>
		</>
	);
};

export default NoDataIcon;
