import styled from 'styled-components';

import FeatherBackground from '@/components/FeatherBackground';
import { media } from '@/Shared/theme';

export const StyledBG = styled.div`
	align-items: center;
	background-color: var(--c-white);
	background: linear-gradient(45deg, rgb(145 144 180 / 20%), var(--c-white));
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	padding: var(--spacing-6);
	position: relative;
	width: 100%;

	.logo {
		margin-top: 22vh;
		width: 200px;
		margin-bottom: var(--spacing-6);
	}
`;

export const StyledFeatherBackgroundTop = styled(FeatherBackground)`
	display: none;

	${media.smallScreen} {
		display: block;
		max-width: 560px;
		opacity: 0.5;
		position: absolute;
		right: -80px;
		top: -80px;
		width: 100%;
	}
`;

export const StyledFeatherBackgroundBottom = styled(FeatherBackground)`
	bottom: -80px;
	left: -80px;
	max-width: 560px;
	opacity: 0.5;
	position: absolute;
	width: 100%;
`;

export const StyledForm = styled.form`
	display: flex;
	flex-flow: column;
	margin-bottom: auto;
	max-width: 450px;
	width: 100%;
	position: relative;

	div {
		flex: 0 0 auto;
		margin-right: 0;
		max-width: none;
	}

	input {
		max-width: none;
		margin-bottom: var(--spacing-4);
	}

	label {
		color: var(--body-text);
		font-size: 1.4rem;
	}

	.error-message {
		margin-bottom: var(--spacing-4);
		margin-top: calc(var(--spacing-4) * -1);
	}
`;
