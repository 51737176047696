import React from 'react';

import styled from 'styled-components';

const StyledBadge = styled.span`
	background-color: ${(props) => props.color};
	border-radius: 4px;
	color: var(--body-text);
	font-family: var(--font-regular);
	font-size: 1.2rem;
	padding: 2px 8px;
`;

interface BadgeProps {
	text: string;
	style?: React.CSSProperties;
	className?: string;
	color?: string;
}

const Badge = ({ text, style, className, color = 'var(--green-200)' }: BadgeProps) => (
	<StyledBadge className={className} style={style} color={color}>
		{text}
	</StyledBadge>
);
export default Badge;
