import { UseFormRegister, FieldValues } from 'react-hook-form';

import { TrashCanIcon } from '@/icons/index';
import { StyledTextArea } from '@/Shared/StyledElements';

interface CourseSubsProps {
	courseFocusId: string;
	focusSkillId: string;
	skillId: string;
	skillText: string;
	skillType: string;
	isApprovalMode: boolean;
	canEdit: boolean;
	removeSkill: (skill: { [key: string]: string }) => void;
	register: UseFormRegister<FieldValues>;
	inputFocusRef: { current: boolean };
}

const CourseSubs = ({
	focusSkillId,
	skillId,
	canEdit,
	skillType,
	isApprovalMode,
	skillText,
	courseFocusId,
	removeSkill,
	register,
	inputFocusRef,
}: CourseSubsProps) => {
	const placeholder = () => {
		if (skillType === 'topic') {
			return 'Subtopic/Description';
		}
		if (skillType === 'question') {
			return 'Essential Question';
		}
		return 'Skill';
	};

	return (
		<div className="single-detail">
			{!isApprovalMode && (
				<>
					<StyledTextArea
						readOnly={!canEdit || isApprovalMode}
						defaultValue={skillText}
						placeholder={placeholder()}
						{...register(`skill-${focusSkillId}-${skillId}`)}
						autoFocus={inputFocusRef.current}
					/>
					<input
						readOnly
						hidden
						defaultValue={skillType}
						{...register(`skill-${focusSkillId}-${skillId}-type`)}
					/>
					<input
						readOnly
						hidden
						value={JSON.stringify({
							focusSkillId,
							parentId: courseFocusId,
							skillText: `skill-${focusSkillId}-${skillId}`,
							skillType: `skill-${focusSkillId}-${skillId}-type`,
							skillId,
						})}
						{...register(`skillBlob-${skillId}-${focusSkillId}`)}
					/>
				</>
			)}
			{canEdit && !isApprovalMode && (
				<TrashCanIcon
					passedEvent={() =>
						removeSkill({
							focusSkillId,
							courseFocusId,
						})
					}
				/>
			)}
		</div>
	);
};

export default CourseSubs;
