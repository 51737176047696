import { StrictMode } from 'react';

import * as Sentry from '@sentry/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';

import packageJson from '../package.json';

import './index.css';
import App from './App';

const queryClient = new QueryClient();
const app = document.getElementById('root') as HTMLElement;
const root = createRoot(app);

if (import.meta.env.MODE !== 'development') {
	Sentry.init({
		dsn: `https://8987181c68744e5b867fb7a692ef2304@o920799.ingest.sentry.io/4504382734860288`,
		integrations: [new Sentry.Replay()],
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.0,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		environment: `production - ${import.meta.env.VITE_ORG}`,
		tracesSampleRate: 0.2,
		release: packageJson.version,
	});
}

root.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<App />
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</StrictMode>
);
