import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';

import {
	CourseCountQuery,
	CourseMapIDsQuery,
	UnassignedCourseCountQuery,
	UnassignedUsersQuery,
	UserCountQuery,
} from '@/graphql/graphql';
import { renameAndDestructure, prefix } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);
const createHeader = async () => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();
	graphQLClient.setHeader('authorization', `Bearer ${token}`);
	graphQLClient.setHeader('x-hasura-role', 'admin');
	return {
		Authorization: `Bearer ${token}`,
	};
};

const COURSE_COUNT = gql`
	query CourseCount {
		${prefix}courses_aggregate(order_by: { course_name: asc }) {
			aggregate {
				count
			}
			nodes {
				course_id
				course_name
			}
		}
	}
`;

const COURSE_MAP_IDS = gql`
	query CourseMapIDs {
		${prefix}course_map(where: { isDraft: { _eq: false } }) {
			course_map_json
		}
	}
`;

const USER_COUNT = gql`
	query UserCount {
		${prefix}users_aggregate {
			aggregate {
				count
			}
		}
	}
`;

const UNASSIGNED_USERS = gql`
	query UnassignedUsers {
		${prefix}users(order_by: { user_first: asc }) {
			user_first
			user_last
			user_id
			user_role
			courses_users_aggregate {
				aggregate {
					count
				}
			}
		}
	}
`;

const getUsedCoursesMapIds = (tree: any) => {
	if (!tree) return null;
	const trackers = [] as any;
	if (tree.children) {
		tree.children.forEach((child: any) => {
			if (!child.isDiscipline && !child.id.includes('Spacer')) {
				trackers.push(child.tracker);
			}
			trackers.push(...(getUsedCoursesMapIds(child) ?? []));
		});
	}
	const unique = [...new Set(trackers)];
	return unique;
};

const getUnusedCourses = (coursemap: any, courses: any) => {
	const mapIDs = getUsedCoursesMapIds(coursemap);
	const courseIds = courses.map((course: any) => course.course_id);

	if (mapIDs && courseIds) {
		const idList = courseIds.filter((courseId: any) => !mapIDs.includes(courseId));

		return courses.filter((course: any) => idList.includes(course.course_id));
	}
	return null;
};

interface IUseCourseCount {
	count: number;
	coursemap: CourseMap;
	courses: Pick<Course, 'course_id' | 'course_name'>[];
	unusedCourses: Pick<Course, 'course_id' | 'course_name'>[];
}

interface CourseMap {
	id: string;
	name: string;
	tracker: string;
	children: MapDiscipline;
}

interface MapDiscipline {
	id: string;
	color: string;
	discipline: string;
	division: string;
	isDiscipline: boolean;
	name: string;
	tracker: string;
	new: boolean; // ?? Can this be removed
	children: MapCourse[];
}

interface MapCourse {
	id: string;
	discipline: string;
	division: string;
	grade: string;
	isAp: boolean;
	name: string;
	tracker: string;
	new: boolean; // ?? Can this be removed
	children: MapCourse[];
}

export const getCourseCount = async () => {
	await createHeader();
	const data = await graphQLClient.request<CourseCountQuery>(COURSE_COUNT);
	const dataRes = renameAndDestructure(data, prefix) as {
		courses_aggregate: CourseCountQuery['dev_courses_aggregate'];
	};
	const mapData = await graphQLClient.request<CourseMapIDsQuery>(COURSE_MAP_IDS);
	const mapRes = renameAndDestructure(mapData, prefix) as {
		course_map: CourseMapIDsQuery['dev_course_map'];
	};
	const coursemap = JSON.parse(mapRes.course_map[0]?.course_map_json ?? '{}');

	const res: IUseCourseCount = {
		count: dataRes.courses_aggregate?.aggregate?.count ?? 0,
		courses: dataRes.courses_aggregate.nodes,
		coursemap,
		unusedCourses: getUnusedCourses(coursemap, dataRes.courses_aggregate.nodes),
	};

	return res;
};

export const getUnassignedUsers = async () => {
	await createHeader();
	const data = await graphQLClient.request<UnassignedUsersQuery>(UNASSIGNED_USERS);
	const dataRes = renameAndDestructure(data, prefix) as {
		users: UnassignedUsersQuery['dev_users'];
	};

	const usersArray = dataRes.users.filter(
		(user: any) => user.courses_users_aggregate.aggregate.count === 0
	);
	const res = {
		users: usersArray as Pick<User, 'user_id' | 'user_first' | 'user_last' | 'user_role'>[],
	};

	return res;
};

export const getUserCount = async () => {
	await createHeader();
	const data = await graphQLClient.request<UserCountQuery>(USER_COUNT);
	const dataRes = renameAndDestructure(data, prefix) as {
		users_aggregate: UserCountQuery['dev_users_aggregate'];
	};

	const res = {
		count: dataRes.users_aggregate?.aggregate?.count ?? 0,
	};

	return res;
};

const UNASSIGNED_COURSE_COUNT = gql`
	query UnassignedCourseCount {
		${prefix}courses {
			course_name
			courses_users {
				user_id
			}
		}
	}
`;

export const getUnassignedCourses = async () => {
	await createHeader();
	const data = await graphQLClient.request<UnassignedCourseCountQuery>(UNASSIGNED_COURSE_COUNT);
	const dataRes = renameAndDestructure(data, prefix) as {
		courses: UnassignedCourseCountQuery['dev_courses'];
	};
	const res = dataRes.courses.filter((x: any) => x.courses_users.length === 0);
	return { count: res.length, courses: res };
};
