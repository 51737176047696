import { GetAllDisciplinesQuery } from '@/graphql/graphql';
import { StyledP } from '@/Shared/Typography/typography';

import { StyledNotification } from './DisciplineListStyles';

interface ConfirmationWarningProps {
	discipline: GetAllDisciplinesQuery['dev_disciplines'][0];
}

export const ConfirmationWarning = ({ discipline }: ConfirmationWarningProps) => {
	const count = discipline?.courses_disciplines?.length;
	const courseIsAre = count === 1 ? 'is' : 'are';
	const coursePluralSingular = count === 1 ? 'course' : 'courses';
	const courseThisThese = count === 1 ? 'this course' : 'these courses';

	return (
		<>
			<StyledP mt="var(--spacing-4)" mb="var(--spacing-4)">
				There {courseIsAre} currently <strong>{count}</strong> {coursePluralSingular}{' '}
				associated with this discipline. Please select another discipline for{' '}
				{courseThisThese}. Once selected, click{' '}
				<span style={{ color: 'var(--success-message)' }}>Yes</span>.
			</StyledP>
			<StyledNotification>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.5 486.5" width="20">
					<path d="M243.2 333.4c-13.6 0-25 11.4-25 25s11.4 25 25 25c13.1 0 25-11.4 24.4-24.4a24.4 24.4 0 00-24.4-25.6z" />
					<path d="M474.6 422a85.4 85.4 0 00.2-86.4L318.2 64.4a85 85 0 00-74.9-43.5 85.4 85.4 0 00-74.9 43.4L11.6 335.8a86 86 0 00.3 86.9 85.6 85.6 0 0074.7 42.9h312.8a86.1 86.1 0 0075.2-43.6zm-34-19.6a47.3 47.3 0 01-41.3 23.9H86.5a47.3 47.3 0 01-40.9-71.1L202.4 83.8a46.4 46.4 0 0141-23.7c17 0 32.3 8.9 40.8 23.8L441 355.3a46.7 46.7 0 01-.3 47z" />
					<path d="M237 157.9c-11.9 3.4-19.3 14.2-19.3 27.3l1.7 23.8 5.1 89.7a18.3 18.3 0 0018.7 17.6c10.2 0 18.2-8 18.7-18.2 0-6.2 0-12 .6-18.2l3.4-58c.6-12.4 1.7-25 2.3-37.4 0-4.5-.6-8.5-2.3-12.5a25 25 0 00-28.9-14.1z" />
				</svg>
				<span>
					Deleting a discipline impacts course alignment. Review course alignment to make
					any needed changes.
				</span>
			</StyledNotification>
		</>
	);
};
