import { HocuspocusProvider } from '@hocuspocus/provider';
import { Editor } from '@tiptap/react';
import * as Y from 'yjs';
// import { WebrtcProvider } from 'y-webrtc';
import { create } from 'zustand';

// TODO uninstall WebrtcProvider
interface State {
	updateCommentTypes: (type: string[]) => void;
	updateEditorList: (type: Record<string, Editor>) => void;
	editorList: Record<string, Editor>;
	commentTypes: string[];
	docTypes: Record<string, Y.Doc>;
	providers: Record<string, HocuspocusProvider>;
	dynamicDoc: (id: string, type: string) => Y.Doc;
	dynamicProvider: (id: string, type: string) => HocuspocusProvider;
}

export const useCourseEditStore = create<State>((set, get) => ({
	docTypes: {},
	providers: {},
	dynamicDoc: (id, type) => {
		const { docTypes } = get();
		const uuid = `${id}${type}`;

		if (docTypes[uuid]) {
			return docTypes[uuid];
		}

		set({
			docTypes: {
				...docTypes,
				[uuid]: new Y.Doc(),
			},
		});

		return docTypes[uuid];
	},
	dynamicProvider: (id, type) => {
		const { providers, docTypes } = get();
		const uuid = `${id}${type}`;

		if (!docTypes[uuid]) {
			set({
				docTypes: {
					...docTypes,
					[uuid]: new Y.Doc(),
				},
			});
		}

		if (providers[uuid]) {
			return providers[uuid];
		}

		set({
			providers: {
				...providers,
				// [uuid]: new WebrtcProvider(uuid, get().docTypes[uuid]),
				[uuid]: new HocuspocusProvider({
					url: `wss://elevate-collaboration-server.herokuapp.com`,
					document: get().docTypes[uuid],
					name: uuid,
					broadcast: true, // syncs within other tabs. Set to false to test local bugs
				}),
			},
		});

		return providers[uuid];
	},
	commentTypes: [],
	updateCommentTypes: (type) => {
		set({ commentTypes: type });
	},
	editorList: {},
	updateEditorList: (editorRef) => {
		const { editorList } = get();

		set({
			editorList: {
				...editorList,
				...editorRef,
			},
		});
	},
}));

export const useCourseStore = create((set: any, get: any) => ({
	workingCopy: {} as any,
	courseId: '',
	editorList: {},
	updateEditorList: (editorRef: any) => {
		const { editorList } = get();

		set({
			editorList: {
				...editorList,
				...editorRef,
			},
		});
	},
	setCourseId: (id: string) => {
		set({ courseId: id });
	},
	setWorkingCopy: (copy: any) => {
		const updatedUnits = copy.courses_focuses.map((focus: CourseFocusEntity) => {
			return { focus, mergedContent: null };
		});
		const newCopy = { ...copy, courses_focuses: updatedUnits };
		set({ workingCopy: newCopy });
	},
}));
