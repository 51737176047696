/* eslint-disable react/jsx-no-constructed-context-values */
import {
	useState,
	useEffect,
	createContext,
	useCallback,
	useRef,
	Fragment,
	useContext,
	useReducer,
} from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Editor } from '@tiptap/core';
import emailjs from 'emailjs-com';
import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { showErrorToast, showSuccessToast } from '@/components/ToastNotification';
import GlobalContext from '@/context/GlobalContext';
import { EditorType, SetStatus } from '@/Enums/enum';
import {
	AddNewSubmissionsMutation,
	GetUsersQuery,
	UpdateCourseSubIdMutation,
} from '@/graphql/graphql';
import { useCurrentUser, useUserRole } from '@/hooks/useAuth';
import { useCourseLessonsStore } from '@/stores/lessonStore';
import { DropResultEntity } from '@/types/source';
import { createEmailSubmissionLink, prefix, renameAndDestructure } from '@/utils';

import {
	existingSub,
	setDraftFrag,
	updateDraftFrag,
	updateLessonPlans,
	updateSubStatusFrag,
	useGetAllAdmin,
	useGetCurrentCourses,
} from './CourseEditContainers';

interface ICourseEditContext {
	addFocus(): void;
	addOutcome(): void;
	addResource(a: any): void;
	addSkill(a: string, b: string): void;
	canEdit: boolean;
	courseData: Course;
	courseDraftData: Course;
	currentUserData: any;
	editorRefs: React.MutableRefObject<Map<string, Editor>>;
	isAlignment: boolean;
	isApprovalMode: boolean;
	isCourseOwner(a: any, b: any): void;
	isFetched: boolean;
	isFetchedAfterMount: boolean;
	isPendingApproval(): boolean;
	lessonPlans: any;
	removeFocus(a: any): void;
	removeOutcome(a: any): void;
	removeResource(a: any): void;
	removeSkill(a: any): void;
	saveDraft(): Promise<void>;
	submitCourse(): void;
	updateAdditionalInfo(a: any): void;
	updateCourseReducer(a: any, b: any): void;
	updateDescription(a: any): void;
	updateDraftStatus(a: any, b?: any): void;
	updateEditorRef(key: string, editor: Editor): void;
	updateFocus(a: any): void;
	updateFocusOrder(a: any, b: any): void;
	updateOutcome(a: any): void;
	updateOutcomeOrder(a: any, b: any): void;
	updatePrereq(a: any): void;
	updateResourceInfo(a: any): void;
	updateResources(a: any): void;
	updatePlans(plan: LessonSection[], courseId: string): void;
}

interface CourseUrlParams {
	[key: string]: string;
}

const CourseEditContext = createContext({} as ICourseEditContext);

type FocusAction =
	| { type: 'ADD_FOCUS' }
	| { type: 'ADD_SKILL'; payload: { focusId: string; skillType: string } }
	| { type: 'UPDATE_FOCUS'; payload: { courses_focuses: CourseFocusEntity[] } }
	| { type: 'UPDATE_SKILL'; payload: { focusId: string; skillId: string; text: string } }
	| { type: 'REMOVE_FOCUS'; payload: { focusId: string } }
	| { type: 'REMOVE_SKILL'; payload: { focusSkillId: string; courseFocusId: string } }
	| { type: 'REORDER_FOCUSES'; payload: { focuses: CourseFocusEntity[] } }
	| { type: 'SET_FOCUSES'; payload: { courses_focuses: CourseFocusEntity[] } };

function focusReducer(state: Course, action: FocusAction): Course {
	switch (action.type) {
		case 'SET_FOCUSES':
			return {
				...state,
				courses_focuses: action.payload.courses_focuses,
			};

		case 'ADD_FOCUS': {
			const maxOrder =
				state.courses_focuses?.reduce(
					(max, focus) => Math.max(max, focus.focus.focus_order),
					-1
				) ?? -1;

			return {
				...state,
				courses_focuses: [
					...(state.courses_focuses ?? []),
					{
						courses_focuses_id: uuidv4(),
						focus: {
							focus_id: uuidv4(),
							focus_order: maxOrder + 1,
							focus_type: '',
							focus_title: '',
							focuses_skills: [],
						},
					},
				],
			};
		}

		case 'ADD_SKILL': {
			const { focusId, skillType } = action.payload;
			return {
				...state,
				courses_focuses:
					state.courses_focuses?.map((focus) =>
						focus.courses_focuses_id === focusId
							? {
									...focus,
									focus: {
										...focus.focus,
										focuses_skills: [
											...(focus.focus.focuses_skills ?? []),
											{
												focuses_skills_id: uuidv4(),
												skill: {
													skill_id: uuidv4(),
													skill_text: '',
													skill_type: skillType,
												},
											},
										],
									},
							  }
							: focus
					) ?? [],
			};
		}

		case 'REMOVE_SKILL': {
			const { focusSkillId, courseFocusId } = action.payload;

			return {
				...state,
				courses_focuses: state.courses_focuses?.map((focus) =>
					focus.courses_focuses_id === courseFocusId
						? {
								...focus,
								focus: {
									...focus.focus,
									focuses_skills:
										focus.focus.focuses_skills?.filter(
											(skill) => skill.focuses_skills_id !== focusSkillId
										) ?? [],
								},
						  }
						: focus
				),
			};
		}

		case 'REMOVE_FOCUS': {
			return {
				...state,
				courses_focuses: state.courses_focuses?.filter(
					(focus) => focus.courses_focuses_id !== action.payload.focusId
				),
			};
		}

		case 'UPDATE_FOCUS': {
			// Create a map of current focus orders from state
			const currentOrders = new Map(
				state.courses_focuses?.map((focus) => [
					focus.courses_focuses_id,
					focus.focus.focus_order,
				])
			);

			// Update incoming focuses with correct orders from state
			const updatedFocuses = action.payload.courses_focuses.map((focus) => ({
				...focus,
				focus: {
					...focus.focus,
					// Use the order from state, or keep current if not found
					focus_order:
						currentOrders.get(focus.courses_focuses_id) ?? focus.focus.focus_order,
				},
			}));

			// Sort by the preserved orders
			const sortedFocuses = updatedFocuses.sort(
				(a, b) => a.focus.focus_order - b.focus.focus_order
			);

			return {
				...state,
				courses_focuses: sortedFocuses,
			};
		}

		case 'REORDER_FOCUSES': {
			return {
				...state,
				courses_focuses: action.payload.focuses,
			};
		}

		default:
			return state;
	}
}

type OutcomeAction =
	| { type: 'ADD_OUTCOME' }
	| { type: 'UPDATE_OUTCOME'; payload: { id: string; debouncedText: string } }
	| { type: 'SET_OUTCOMES'; payload: { courses_outcomes: CoursesOutcomesEntity[] } }
	| { type: 'REMOVE_OUTCOME'; payload: { id: string } }
	| { type: 'REORDER_OUTCOMES'; payload: { outcomes: CoursesOutcomesEntity[] } };

function outcomeReducer(state: Course, action: OutcomeAction): Course {
	switch (action.type) {
		case 'SET_OUTCOMES':
			return {
				...state,
				courses_outcomes: action.payload.courses_outcomes,
			};

		case 'ADD_OUTCOME':
			return {
				...state,
				courses_outcomes: [
					...(state.courses_outcomes ?? []),
					{
						courses_outcomes_id: uuidv4(),
						outcome: {
							outcome_id: uuidv4(),
							outcome_order: state.courses_outcomes?.length ?? 0,
							outcome_text: '',
						},
					},
				],
			};

		case 'UPDATE_OUTCOME':
			return {
				...state,
				courses_outcomes:
					state.courses_outcomes?.map((outcome) =>
						outcome.outcome.outcome_id === action.payload.id
							? {
									...outcome,
									outcome: {
										...outcome.outcome,
										outcome_text: action.payload.debouncedText,
									},
							  }
							: outcome
					) ?? [],
			};

		case 'REMOVE_OUTCOME':
			return {
				...state,
				courses_outcomes: state.courses_outcomes?.filter(
					(outcome) => outcome.courses_outcomes_id !== action.payload.id
				),
			};

		case 'REORDER_OUTCOMES':
			return {
				...state,
				courses_outcomes: action.payload.outcomes,
			};

		default:
			return state;
	}
}

type ResourceAction =
	| { type: 'ADD_RESOURCE'; payload: { type: string } }
	| { type: 'UPDATE_RESOURCE'; payload: { resources: CoursesResourcesEntity[] } }
	| { type: 'REMOVE_RESOURCE'; payload: { id: string } }
	| { type: 'SET_RESOURCES'; payload: { courses_resources: CoursesResourcesEntity[] } };

function resourceReducer(state: Course, action: ResourceAction): Course {
	switch (action.type) {
		case 'SET_RESOURCES':
			return {
				...state,
				courses_resources: action.payload.courses_resources,
			};

		case 'ADD_RESOURCE':
			return {
				...state,
				courses_resources: [
					...(state.courses_resources ?? []),
					{
						course_resources_id: uuidv4(),
						resource: {
							resource_id: uuidv4(),
							resource_type: action.payload.type,
							resource_title: '',
							resource_author: '',
							resource_detail: '',
							resource_isbn: '',
						},
					},
				],
			};

		case 'UPDATE_RESOURCE':
			return {
				...state,
				courses_resources: action.payload.resources,
			};

		case 'REMOVE_RESOURCE':
			return {
				...state,
				courses_resources: state.courses_resources?.filter(
					(resource) => resource.course_resources_id !== action.payload.id
				),
			};

		default:
			return state;
	}
}

type CourseAction =
	| FocusAction
	| OutcomeAction
	| ResourceAction
	| { type: 'UPDATE_RESOURCE_INFO'; payload: string }
	| { type: 'UPDATE_DESCRIPTION'; payload: string }
	| { type: 'UPDATE_PREREQ'; payload: string }
	| { type: 'UPDATE_ADDITIONAL_INFO'; payload: string }
	| { type: 'UPDATE_COURSE_EXTRAS'; payload: string }
	| { type: 'SET_COURSE_DRAFT'; payload: string }
	| { type: 'SET_COURSE_DATA'; payload: { courseData: Course } };

function courseReducer(state: Course, action: CourseAction): Course {
	switch (action.type) {
		// Focus actions
		case 'ADD_FOCUS':
		case 'ADD_SKILL':
		case 'UPDATE_FOCUS':
		case 'SET_FOCUSES':
		case 'REMOVE_FOCUS':
		case 'REMOVE_SKILL':
		case 'REORDER_FOCUSES':
			return focusReducer(state, action);

		// Outcome actions
		case 'ADD_OUTCOME':
		case 'UPDATE_OUTCOME':
		case 'REMOVE_OUTCOME':
		case 'SET_OUTCOMES':
		case 'REORDER_OUTCOMES':
			return outcomeReducer(state, action);

		// Resource actions
		case 'ADD_RESOURCE':
		case 'UPDATE_RESOURCE':
		case 'REMOVE_RESOURCE':
		case 'SET_RESOURCES':
			return resourceReducer(state, action);

		// Resource Info
		case 'UPDATE_RESOURCE_INFO':
			return {
				...state,
				course_resource_info: action.payload,
			};

		case 'UPDATE_DESCRIPTION':
			return {
				...state,
				course_description: action.payload,
			};

		case 'UPDATE_PREREQ':
			return {
				...state,
				course_prereq: action.payload,
			};

		case 'UPDATE_COURSE_EXTRAS':
			return {
				...state,
				course_extras: action.payload,
			};

		case 'SET_COURSE_DRAFT':
			return {
				...state,
				course_draft: action.payload,
			};

		default:
			return state;
	}
}

const CourseEditProvider = ({ ...props }: any) => {
	const { children } = props;
	const { pathname } = useLocation();

	const isAlignment = pathname.includes('course-alignment');
	const { data: adminUsers } = useGetAllAdmin();
	const { currentUser } = useContext(GlobalContext);

	const [courseData, setCourseData] = useState<any>();
	const [courseDraftData, dispatch] = useReducer(courseReducer, {
		courses_focuses: [],
		courses_outcomes: [],
		course_draft: '',
		course_resource_info: '',
		courses_resources: [],
		course_description: '',
		course_name: '',
		course_id: '',
		course_extras: '',
		course_prereq: '',
		course_discipline: '',
		course_division: '',
		working_copy: '',
		is_ap: false,
		grade: '',
		courses_users: [],
		is_saved_draft: false,
		last_updated: '',
	});
	const { data: userRoleData } = useUserRole();
	const [searchParams] = useSearchParams();
	const isApprovalMode = !!searchParams.get('isApprovalMode');
	const params = useParams<CourseUrlParams>();

	const {
		data: courseInfo,
		isFetchedAfterMount,
		isFetched: isCourseFetched,
	} = useGetCurrentCourses(userRoleData, params.courseId);

	const isFetched = isCourseFetched;
	const [courseSubId, setCourseSubId] = useState('');
	const subIdRef = useRef(courseData?.submission_id ?? '');
	// TODO: look into
	// ?? can I use current user instead?
	const { data: currentUserData } = useCurrentUser();
	const updateDescription = useCallback((editorData: string) => {
		dispatch({ type: 'UPDATE_DESCRIPTION', payload: editorData });
	}, []);

	const editorRefs = useRef(new Map<string, Editor>());

	const updateEditorRef = (key: string, editor: Editor) => {
		editorRefs.current.set(key, editor);
	};

	function isCourseOwner(
		courseUsers: CourseUserEntity[],
		user: GetUsersQuery['dev_users'][0] | undefined
	) {
		if (!user) return false;

		const canEdit =
			courseUsers.some((c) => c?.user?.user_id === user?.user_id) ||
			user?.user_role === 'admin';
		const isDept = courseUsers.some((c) => c?.user?.dept_chair?.user_id === user?.user_id);

		return isDept || canEdit;
	}

	const canEdit = isCourseOwner(courseData?.courses_users ?? [], currentUserData as any);

	const isPendingApproval = useCallback(() => {
		if (courseInfo?.submission) {
			return Object.values(courseInfo.submission).every(
				(status) => status !== SetStatus.APPROVED
			);
		}
		return false;
	}, [courseInfo?.submission]);

	const updateResourceInfo = useCallback((editorData: string) => {
		dispatch({ type: 'UPDATE_RESOURCE_INFO', payload: editorData });
	}, []);

	const updatePrereq = useCallback((inputVal: string) => {
		dispatch({ type: 'UPDATE_PREREQ', payload: inputVal });
	}, []);

	const updateAdditionalInfo = useCallback((editorData: string) => {
		dispatch({ type: 'UPDATE_COURSE_EXTRAS', payload: editorData });
	}, []);

	const addSubmission = async (variables: { courseId: string }) => {
		const endpoint = `${import.meta.env.VITE_HASURA_ENDPOINT}`;
		const auth = getAuth();
		const token = await auth.currentUser?.getIdToken();
		const graphQLClient = new GraphQLClient(endpoint, {
			headers: {
				Authorization: `Bearer ${token}`,
				'x-hasura-role': userRoleData?.user_role ?? '',
			},
		});
		const subMutation = gql`
			mutation AddNewSubmissions($courseId: uuid!) {
				${prefix}insert_submissions_one(object: { course_id: $courseId }) {
					submission_id
				}
			}
		`;

		const submissionData = await graphQLClient.request<AddNewSubmissionsMutation>(
			subMutation,
			variables
		);

		const { insert_submissions_one: subInsert } = renameAndDestructure(
			submissionData,
			prefix
		) as {
			insert_submissions_one: { submission_id: string };
		};

		const courseSubIdMutation = gql`
			mutation UpdateCourseSubId($courseId: uuid!, $subId: uuid!) {
				${prefix}update_courses(
					where: { course_id: { _eq: $courseId } }
					_set: { submission_id: $subId }
				) {
					affected_rows
				}
			}
		`;

		subIdRef.current = subInsert?.submission_id;
		setCourseSubId(subInsert?.submission_id);
		const subVars = {
			courseId: variables.courseId,
			subId: subInsert?.submission_id,
		};

		const result = await graphQLClient.request<UpdateCourseSubIdMutation>(
			courseSubIdMutation,
			subVars
		);
		const response = renameAndDestructure(result, prefix);
		return response;
	};

	const updateDraft = async (variables: {
		courseId: string;
		courseDraft: string;
		isDraft?: boolean;
	}) =>
		updateDraftFrag({
			variables,
			userRole: userRoleData?.user_role ?? '',
		});

	const queryClient = useQueryClient();

	const setDraftStatus = async (variables: {
		courseId: string;
		subId: string;
		status: string;
		approveAll: boolean;
	}) =>
		setDraftFrag({
			variables,
			draftCourse: courseDraftData,
			publishedCourse: courseData,
			userRole: userRoleData?.user_role,
		});

	const { mutate: updateDraftStatusMutate } = useMutation({
		mutationFn: setDraftStatus,
		mutationKey: ['submission-status-mutation'],

		onSuccess: (_, variables: any) => {
			const { status } = variables;

			const { course_name: courseName } = courseData;
			// const previousCourseData = queryClient.getQueryData([
			// 	'get-current-course',
			// ]) as Partial<Course>;

			// previousCourseData.is_saved_draft = false;
			// previousCourseData.submission = {
			// 	admin_approval: SetStatus.APPROVED,
			// 	dept_approval: SetStatus.APPROVED,
			// };

			// update query cache
			// queryClient.setQueryData('get-current-course', previousCourseData);

			// send email to course owner/s when status changes
			// statuses: approved or changes requested
			courseData.courses_users?.forEach((user: Partial<CourseUserEntity>) => {
				if (!user.user) return;
				const { user_email: email } = user.user;

				if (import.meta.env.MODE !== 'development' && email) {
					emailjs.send(
						`${import.meta.env.VITE_EMAILJS_SERVICE_ID}`,
						'template_0b5w8ea',
						{
							courseName,
							emailRecipient: email,
							courseUrl: createEmailSubmissionLink(window.location.href),
						},
						`${import.meta.env.VITE_EMAILJS_USER_ID}`
					);
				}
			});

			queryClient.invalidateQueries({
				queryKey: ['get-all-courses'],
			});
			queryClient.invalidateQueries({
				queryKey: ['get-current-course'],
			});
			queryClient.invalidateQueries({
				queryKey: ['get-all-subs'],
			});
			queryClient.refetchQueries({ queryKey: ['get-all-alignment-courses'] });

			if (status === 'rejected') {
				showSuccessToast(`Course Draft Changes Requested`);
			} else {
				showSuccessToast(`Course Draft Approved`);
			}
		},

		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const updateDraftStatus = (status: string, approveAll = false) => {
		const subId = courseSubId ?? courseData?.submission_id;
		const { course_id: courseId } = courseData;
		updateDraftStatusMutate({ courseId, subId, status, approveAll });
	};

	const { mutate: plansMutate } = useMutation({
		mutationFn: updateLessonPlans,
		mutationKey: ['save-course-lesson'],
		onSuccess: () => {
			showSuccessToast('Lesson Plans Saved');
		},
		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const updatePlans = (plans: LessonSection[], courseId: string) => {
		plansMutate({ plans, courseId });
	};

	const { mutate: saveDraftMutate } = useMutation({
		mutationFn: updateDraft,
		mutationKey: ['save-course-draft'],

		onSuccess: () => {
			showSuccessToast('Course Draft Saved');

			queryClient.invalidateQueries({
				queryKey: ['get-all-courses'],
			});
			const previousCourseData = queryClient.getQueryData([
				'get-current-course',
			]) as Partial<Course>;

			previousCourseData.is_saved_draft = true;
			// update query cache
			queryClient.setQueryData(['get-current-course'], previousCourseData);
		},

		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const { mutate: draftMutate } = useMutation({
		mutationFn: updateDraft,

		onSuccess: (results: any) => {
			queryClient.invalidateQueries({
				queryKey: ['get-all-courses'],
			});

			const previousCourseData = queryClient.getQueryData([
				'get-current-course',
			]) as Partial<Course>;

			previousCourseData.is_saved_draft = false;
			previousCourseData.submission = {
				admin_approval: SetStatus.WAITING,
				dept_approval: SetStatus.WAITING,
			};
			// update query cache
			queryClient.setQueryData(['get-current-course'], previousCourseData);
			const isSubmitted = results?.update_courses_by_pk?.submission_id;
			subIdRef.current = isSubmitted;
			setCourseSubId(isSubmitted);
			if (isSubmitted) {
				showSuccessToast('Course Draft Resubmitted');
			} else {
				showSuccessToast('Course Draft Submitted');
			}

			const { course_name: courseName } = courseData;
			const firstName = currentUser?.user_first ?? '';
			const lastName = currentUser?.user_last ?? '';
			const submitterName = `${firstName} ${lastName}`;

			// send email to dept chair when user submits course for review
			if (currentUser?.dept_chair?.user_email && process.env.NODE_ENV !== 'development') {
				emailjs.send(
					`${import.meta.env.VITE_EMAILJS_SERVICE_ID}`,
					'template_51oe9lq',
					{
						submitterName,
						courseName,
						courseUrl: createEmailSubmissionLink(window.location.href),
						emailRecipient: currentUser.dept_chair.user_email,
					},
					`${import.meta.env.VITE_EMAILJS_USER_ID}`
				);
			}

			// send email to all admins when course is submitted
			if (process.env.NODE_ENV !== 'development') {
				emailjs.send(
					`${import.meta.env.VITE_EMAILJS_SERVICE_ID}`,
					'template_51oe9lq',
					{
						submitterName,
						courseName,
						courseUrl: createEmailSubmissionLink(window.location.href),
						emailRecipient: adminUsers
							?.map((user) => user.user_email)
							.filter(
								(email) =>
									email !== currentUser?.dept_chair?.user_email &&
									email !== currentUser?.user_email
							)
							.toString(),
					},
					`${import.meta.env.VITE_EMAILJS_USER_ID}`
				);
			}

			queryClient.invalidateQueries({
				queryKey: ['get-all-subs'],
			});
		},

		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const updateSubStatus = async (variables: { courseId: string }) =>
		updateSubStatusFrag({
			variables,
			userRole: userRoleData?.user_role,
		});

	const getSubId = useCallback(
		async (variables: { courseId: string }) =>
			existingSub({
				variables,
				userRole: userRoleData?.user_role,
			}),
		[userRoleData?.user_role]
	);

	const { mutate: subStatusMutate } = useMutation({
		mutationFn: updateSubStatus,

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['get-all-subs'],
			});
			queryClient.invalidateQueries({
				queryKey: ['get-current-course'],
			});
		},

		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const { mutate: subMutate } = useMutation({
		mutationFn: addSubmission,

		onError: () => {
			showErrorToast('Oh no, something went wrong... Please try again.');
		},
	});

	const saveDraft = useCallback(async () => {
		const clone = { ...courseDraftData };
		const { course_id: courseId } = courseData;

		saveDraftMutate({ courseId, courseDraft: JSON.stringify(clone), isDraft: true });
	}, [courseDraftData, saveDraftMutate, courseData]);

	const submitCourse = useCallback(async () => {
		const { course_id: courseId } = courseData;
		const hasSubmission = await getSubId({ courseId });

		if (hasSubmission) {
			subStatusMutate({ courseId });
			const clone = { ...courseDraftData } as { course_draft?: any };
			delete clone.course_draft;
			draftMutate({ courseId, courseDraft: JSON.stringify(clone) });
		} else {
			const clone = { ...courseDraftData } as { course_draft?: any };
			delete clone.course_draft;
			draftMutate({ courseId, courseDraft: JSON.stringify(clone) });
			subMutate({ courseId });
		}
	}, [courseData, subMutate, courseDraftData, draftMutate, subStatusMutate, getSubId]);

	const updateOutcomeOrder = (outcomeArr: CoursesOutcomesEntity[], result: DropResultEntity) => {
		const { destination, source } = result;
		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const sortedArr = outcomeArr.sort(
			(a: CoursesOutcomesEntity, b: CoursesOutcomesEntity) =>
				a.outcome.outcome_order - b.outcome.outcome_order
		);

		const match = sortedArr.splice(source.index, 1)[0];
		sortedArr.splice(destination.index, 0, match);

		const updatedOutcomes = outcomeArr.map((outcome: CoursesOutcomesEntity, index: number) => {
			outcome.outcome.outcome_order = index;
			return outcome;
		});
		dispatch({ type: 'REORDER_OUTCOMES', payload: { outcomes: updatedOutcomes } });
	};

	const updateOutcome = useCallback((data: any) => {
		dispatch({ type: 'UPDATE_OUTCOME', payload: data });
	}, []);

	const updateFocusOrder = (focusArr: CourseFocusEntity[], result: DropResultEntity) => {
		const { destination, source } = result;
		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const sortedArr = focusArr.sort(
			(a: CourseFocusEntity, b: CourseFocusEntity) =>
				a.focus.focus_order - b.focus.focus_order
		);

		const match = sortedArr.splice(source.index, 1)[0];
		sortedArr.splice(destination.index, 0, match);

		const updatedFocuses = focusArr.map((focus: CourseFocusEntity, index: number) => {
			focus.focus.focus_order = index;
			return focus;
		});
		dispatch({ type: 'REORDER_FOCUSES', payload: { focuses: updatedFocuses } });
	};

	const removeOutcome = (id: string) => {
		dispatch({ type: 'REMOVE_OUTCOME', payload: { id } });
	};

	const addOutcome = () => {
		dispatch({ type: 'ADD_OUTCOME' });
	};

	const removeResource = (id: string) => {
		dispatch({ type: 'REMOVE_RESOURCE', payload: { id } });
	};

	const addResource = (type: string) => {
		dispatch({ type: 'ADD_RESOURCE', payload: { type } });
	};

	const updateResources = (resources: CoursesResourcesEntity[]) => {
		dispatch({ type: 'UPDATE_RESOURCE', payload: { resources } });
	};

	const addFocus = useCallback(() => {
		dispatch({ type: 'ADD_FOCUS' });
	}, []);

	const removeFocus = (id: string) => {
		dispatch({ type: 'REMOVE_FOCUS', payload: { focusId: id } });
	};

	const updateFocus = (currentFocus: CourseFocusEntity[]) => {
		dispatch({ type: 'UPDATE_FOCUS', payload: { courses_focuses: currentFocus } });
	};

	const addSkill = useCallback((focusId: string, skillType: string) => {
		dispatch({ type: 'ADD_SKILL', payload: { focusId, skillType } });
	}, []);

	const removeSkill = (data: { focusSkillId: string; courseFocusId: string }) => {
		dispatch({ type: 'REMOVE_SKILL', payload: data });
	};

	const updateCourseReducer = (state: any, action: any) => {
		switch (action) {
			case EditorType.DESCRIPTION:
				return updateDescription(state);
			case EditorType.EXTRAINFO:
				return updateAdditionalInfo(state);
			case EditorType.OUTCOME:
				return updateOutcome(state);
			case EditorType.RESOURCES:
				return updateResourceInfo(state);
			default:
				return state;
		}
	};

	const lessonPlans: any = [
		{
			id: uuidv4(),
			title: '',
			introduction: '',
			lessons: '',
			objectives: '',
			resources: '',
			enrichment: '',
			supportMaterial: '',
			bellringer: '',
			hasAdvanced: true,
		},
	];

	const { setLessonPlans } = useCourseLessonsStore();
	useEffect(() => {
		subIdRef.current = courseData?.submission_id ?? '';
		setCourseData(courseInfo);
		setLessonPlans(JSON.parse(courseData?.course_lesson_plans ?? '[]'));
	}, [courseInfo, courseData, setLessonPlans]);

	useEffect(() => {
		setCourseData(courseInfo);
	}, [courseInfo, courseData]);

	useEffect(() => {
		if (courseInfo) {
			const courseVersion =
				courseInfo.is_saved_draft && !isApprovalMode
					? courseInfo.working_copy
					: courseInfo.course_draft;
			const parsedCourseVersion = JSON.parse(courseVersion ?? '{}');
			dispatch({
				type: 'UPDATE_DESCRIPTION',
				payload: parsedCourseVersion?.course_description,
			});
			dispatch({
				type: 'UPDATE_PREREQ',
				payload: parsedCourseVersion?.course_prereq,
			});
			dispatch({
				type: 'UPDATE_COURSE_EXTRAS',
				payload: parsedCourseVersion?.course_extras,
			});
			dispatch({
				type: 'SET_OUTCOMES',
				payload: { courses_outcomes: parsedCourseVersion?.courses_outcomes ?? [] },
			});
			dispatch({
				type: 'SET_FOCUSES',
				payload: { courses_focuses: parsedCourseVersion?.courses_focuses ?? [] },
			});
			dispatch({
				type: 'SET_RESOURCES',
				payload: { courses_resources: parsedCourseVersion?.courses_resources ?? [] },
			});
			dispatch({
				type: 'UPDATE_RESOURCE_INFO',
				payload: parsedCourseVersion?.course_resource_info,
			});
		}
	}, [courseInfo, isApprovalMode]);

	return (
		<CourseEditContext.Provider
			value={{
				addFocus,
				addOutcome,
				addResource,
				addSkill,
				canEdit,
				courseData,
				courseDraftData,
				currentUserData,
				editorRefs,
				isAlignment,
				isApprovalMode,
				isCourseOwner,
				isFetched,
				isFetchedAfterMount,
				isPendingApproval,
				lessonPlans,
				removeFocus,
				removeOutcome,
				removeResource,
				removeSkill,
				saveDraft,
				submitCourse,
				updateAdditionalInfo,
				updateCourseReducer,
				updateDescription,
				updateDraftStatus,
				updateEditorRef,
				updateFocus,
				updateFocusOrder,
				updateOutcome,
				updateOutcomeOrder,
				updatePrereq,
				updateResourceInfo,
				updateResources,
				updatePlans,
			}}>
			<Fragment key={courseData?.course_id}>{children}</Fragment>
		</CourseEditContext.Provider>
	);
};

export default CourseEditContext;

export { CourseEditProvider };
