import { useContext } from 'react';

import { useIsMutating } from '@tanstack/react-query';

import { Button } from '@/components/ui/button';
import { SetStatus } from '@/Enums/enum';
import { useUserRole } from '@/hooks/useAuth';
import CourseEditContext from '@/Pages/Courses/CourseEditContext';

const CourseFooter = () => {
	const isMutatingPosts = useIsMutating({ mutationKey: ['submission-status-mutation'] });
	const { updateDraftStatus } = useContext(CourseEditContext);
	const { data: userRoleData } = useUserRole();

	return (
		<>
			<Button
				variant="secondary"
				disabled={isMutatingPosts !== 0}
				isLoading={isMutatingPosts !== 0}
				type="button"
				className="ml-auto mr-4"
				onClick={() => updateDraftStatus(SetStatus.REJECTED)}>
				Request Changes
			</Button>
			<Button
				disabled={isMutatingPosts !== 0}
				isLoading={isMutatingPosts !== 0}
				type="button"
				onClick={() => updateDraftStatus(SetStatus.APPROVED)}>
				Approve
			</Button>
			{userRoleData?.user_role === 'admin' && (
				<Button
					disabled={isMutatingPosts !== 0}
					isLoading={isMutatingPosts !== 0}
					type="button"
					onClick={() => updateDraftStatus(SetStatus.APPROVED, true)}
					className="ml-4">
					Approve All
				</Button>
			)}
		</>
	);
};

export default CourseFooter;
