import dompurify from 'dompurify';

import { NoDataIcon } from '@/icons/index';
import { StyledOrderedList } from '@/Shared/StyledElements';
import { StyledP } from '@/Shared/Typography/typography';
import { sanitizeOptions } from '@/utils';

interface OutcomeTabProps {
	courseOutcome: CoursesOutcomesEntity | any;
}

const OutcomeTab = ({ courseOutcome }: OutcomeTabProps) => {
	const sanitizer = dompurify.sanitize;

	if (!courseOutcome || courseOutcome?.length === 0) {
		return (
			<div className="panel-content">
				<NoDataIcon message="This course does not have any listed outcomes." delay={0} />
			</div>
		);
	}

	return (
		<div className="panel-content">
			<StyledOrderedList icon="bullseye">
				{courseOutcome
					?.sort(
						(a: CoursesOutcomesEntity, b: CoursesOutcomesEntity) =>
							a.outcome.outcome_order - b.outcome.outcome_order
					)
					.map(({ outcome }: CoursesOutcomesEntity) => (
						<li key={outcome.outcome_id}>
							<StyledP
								dangerouslySetInnerHTML={{
									__html: sanitizer(outcome.outcome_text, {
										...sanitizeOptions,
									}),
								}}
							/>
						</li>
					))}
			</StyledOrderedList>
		</div>
	);
};

export default OutcomeTab;
