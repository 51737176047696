import { useRef, useContext, useCallback, useState } from 'react';

import { BubbleMenu, Editor } from '@tiptap/react';
import colorJS from 'color';
import Select, { SingleValue, StylesConfig } from 'react-select';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { EditorType } from '@/Enums/enum';
import CourseEditContext from '@/Pages/Courses/CourseEditContext';
import { useGetTags } from '@/Pages/TagList/TagListContainers';
import { StyledTextArea } from '@/Shared/StyledElements';

import { StyledTiptapMenuBar } from './TipTapEditor.Styles';

interface TiptapMenuBarProps {
	editor: Editor;
	type: string;
}

interface OptionType {
	color: string;
	id: string;
}

interface TagSelection {
	id: string;
	color: string;
	value: string;
}

const TiptapMenuBar = ({ editor, type }: TiptapMenuBarProps) => {
	const { courseData } = useContext(CourseEditContext);
	const { data: tagData } = useGetTags();

	const [inputText, setInputText] = useState('');
	const [iframeInputText, setIframeInputText] = useState('');
	const isLinkActive = useRef(false);
	const isIframeActive = useRef(false);

	const toggleLinkMenu = () => {
		if (!editor.state.selection.empty) {
			editor.chain().focus();
			isLinkActive.current = !isLinkActive.current;
		}
	};

	const toggleIframeMenu = () => {
		setIframeInputText('');
		editor.chain().focus();
		isIframeActive.current = !isIframeActive.current;
	};

	const setLink = () => {
		const trimmedInput = inputText.trim();
		if (trimmedInput !== '') {
			const url = !/^https?:\/\//i.test(trimmedInput)
				? `http://${trimmedInput}`
				: trimmedInput;

			editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url, target: '_blank' })
				.run();
		}
		const { to } = editor.view.state.selection;
		editor.commands.setTextSelection({ from: to, to });
		isLinkActive.current = false;
		setInputText('');
	};

	const setIframe = () => {
		const trimmedInput = iframeInputText.trim();
		if (trimmedInput !== '') {
			editor.chain().focus().extendMarkRange('link').setVideo(trimmedInput).run();
		}
		isIframeActive.current = false;
		setIframeInputText('');
	};

	if (editor.isActive('link')) {
		isLinkActive.current = true;
	}

	const handleTagging = useCallback(
		(tag: SingleValue<TagSelection>) => {
			editor.chain().focus().setTag().run();
			editor.commands.updateAttributes('tagged', {
				'data-tip': tag?.id,
				title: tag?.value,
				class: 'tagged-word',
				style: `background-color: ${colorJS(tag?.color).alpha(0.2).toString()}`,
			});
		},
		[editor]
	);

	// remove tags that are tagged useable within this discipline
	const filteredTags = tagData?.filter((tag) => {
		const name = courseData?.courses_discipline?.discipline.name ?? '';
		if (name !== '' && tag?.tag_discipline?.includes(name)) {
			return true;
		}
		if (tag.tag_discipline === '') {
			return true;
		}
		return false;
	});

	// format tags for react-select
	const options = filteredTags?.map((tag) => ({
		value: tag.tag_name,
		id: tag.tag_id,
		color: tag.tag_color,
		label: (
			<div className="select-label">
				<svg viewBox="0 0 100 100" width="15" xmlns="http://www.w3.org/2000/svg">
					<circle cx="50" cy="50" r="50" fill={tag.tag_color} />
				</svg>
				<span style={{ marginLeft: '5px' }}>{tag.tag_name}</span>
			</div>
		),
	}));

	const clearFormatting = () => {
		editor.chain().focus().unsetAllMarks().run();
		// reset focus on editor
		editor.chain().setTextSelection({ from: 0, to: 0 }).run();
	};

	const customStyles: StylesConfig<OptionType, false> = {
		option: (provided, state) => {
			return {
				...provided,
				color: '#333',
				backgroundColor: state.isFocused ? 'hsl(217, 68%, 96%)' : 'white',
			};
		},
		control: (provided) => ({
			...provided,
			marginRight: 'var(--spacing-4)',
			minWidth: '250px',
			backgroundColor: 'hsl(217, 68%, 96%)',
			borderColor: 'hsl(217, 68%, 96%)',
		}),
		menuList: (provided) => ({
			...provided,
			maxHeight: '200px',
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition };
		},
	};

	return (
		<>
			<BubbleMenu
				tippyOptions={{
					placement: 'bottom',
					hideOnClick: true,
					onHide: () => {
						if (editor.view.state.selection) {
							const { to } = editor.view.state.selection;
							editor.commands.setTextSelection({ from: to, to });
							editor.commands.focus();
							isLinkActive.current = false;
						}
					},
					onShow: () => {
						if (editor.isActive('link')) {
							setInputText(editor.getAttributes('link').href);
						}
					},
				}}
				editor={editor}
				shouldShow={({ editor }) => {
					return (
						!editor.state.selection.empty &&
						isLinkActive.current &&
						!editor.isActive('video')
					);
				}}>
				<section>
					<Input
						placeholder="Add a link..."
						value={inputText}
						onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
							setInputText(e.target.value);
						}}
						type="url"
						autoFocus
						className="mb-2"
					/>
					<Button type="button" size="xs" className="mr-2" onClick={setLink}>
						Apply
					</Button>
					<Button variant="secondary" type="button" size="xs" onClick={toggleLinkMenu}>
						Cancel
					</Button>
				</section>
			</BubbleMenu>
			<BubbleMenu
				tippyOptions={{
					placement: 'auto-end',
					hideOnClick: true,
					onHide: () => {
						if (editor.view.state.selection) {
							editor.commands.focus();
							isIframeActive.current = false;
						}
					},
				}}
				editor={editor}
				shouldShow={() => {
					const selectedIframeSrc = editor.getAttributes('video')?.src;

					if (selectedIframeSrc) {
						const trimmedSrc = selectedIframeSrc.replace(
							`&origin=${window.location.origin}`,
							''
						);
						setIframeInputText(trimmedSrc);
					}
					return isIframeActive.current || editor.isActive('video');
				}}>
				<section>
					<StyledTextArea
						placeholder="Add a video..."
						value={iframeInputText}
						onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
							setIframeInputText(e.target.value);
						}}
						autoFocus
						style={{ marginBottom: '4px' }}
					/>
					<Button type="button" size="xs" onClick={setIframe} className="mr-2">
						Apply
					</Button>
					<Button variant="secondary" type="button" size="xs" onClick={toggleIframeMenu}>
						Cancel
					</Button>
				</section>
			</BubbleMenu>
			<StyledTiptapMenuBar>
				{type !== EditorType.STANDARD && (
					<>
						<Select
							styles={customStyles}
							placeholder="Select Tag"
							onChange={(value) => handleTagging(value as SingleValue<TagSelection>)}
							options={options}
							isSearchable
						/>
					</>
				)}

				<button
					type="button"
					title="Link"
					aria-label="Link"
					onClick={toggleLinkMenu}
					className={
						editor.isActive('link') ? 'is-active editor-button' : 'editor-button'
					}>
					<svg width="15" viewBox="0 0 469.3 469.3" xmlns="http://www.w3.org/2000/svg">
						<path d="M354 313a21 21 0 0 1-15-37l57-56A104 104 0 1 0 250 74l-57 56a21 21 0 1 1-30-30l57-57a146 146 0 0 1 207 207l-57 57c-5 4-10 6-16 6zM146 469A146 146 0 0 1 43 220l57-57a21 21 0 1 1 30 30l-57 57a104 104 0 0 0 146 146l57-57a21 21 0 1 1 31 31l-57 56c-28 28-64 43-104 43zm0 0" />
						<path d="M149 341a21 21 0 0 1-15-36l171-171a21 21 0 1 1 30 30L164 335c-4 4-9 6-15 6zm0 0" />
					</svg>
				</button>
				<button
					className="editor-button"
					type="button"
					aria-label="Remove Link"
					title="Remove Link"
					onClick={() => editor.chain().focus().unsetLink().run()}>
					<svg width="15" viewBox="0 0 469.3 469" xmlns="http://www.w3.org/2000/svg">
						<path d="M368 299a21 21 0 0 1-15-36l43-43c20-20 31-46 31-73a104 104 0 0 0-177-74l-43 43a21 21 0 1 1-31-30l44-43a147 147 0 0 1 207 207l-44 43c-4 4-9 6-15 6zM146 470A147 147 0 0 1 43 220l43-43a21 21 0 1 1 30 30l-43 43a104 104 0 0 0 146 147l44-44a21 21 0 1 1 30 30l-43 44c-28 27-64 43-104 43zm0 0" />
						<path d="M280 211a21 21 0 0 1-15-36l40-41a21 21 0 1 1 30 31l-40 40c-4 4-10 6-15 6zM149 342a21 21 0 0 1-15-37l40-40a21 21 0 1 1 31 30l-41 40c-4 4-9 7-15 7zM448 470c-5 0-11-3-15-7L6 37A21 21 0 1 1 36 6l427 427a21 21 0 0 1-15 37zm0 0" />
					</svg>
				</button>
				<button
					type="button"
					title="Add Video"
					aria-label="Add Video"
					className={
						editor.isActive('video') ? 'is-active editor-button' : 'editor-button'
					}
					onClick={toggleIframeMenu}>
					<svg viewBox="0 0 24 24" width="15" xmlns="http://www.w3.org/2000/svg">
						<path d="M23.25 0h-6.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5zM23.25 3h-6.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5zM23.25 6h-6.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5zM23.25 9h-6.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5zM23.25 12h-6.5a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5zM23.25 15H.75a.75.75 0 0 0 0 1.5h22.5a.75.75 0 0 0 0-1.5zM23.25 18H.75a.75.75 0 0 0 0 1.5h22.5a.75.75 0 0 0 0-1.5zM23.25 21H.75a.75.75 0 0 0 0 1.5h22.5a.75.75 0 0 0 0-1.5zM1.75 13.5h10.5c.96 0 1.75-.79 1.75-1.75v-10C14 .79 13.21 0 12.25 0H1.75C.79 0 0 .79 0 1.75v10c0 .96.79 1.75 1.75 1.75zM4 9.5V4c0-.26.13-.5.36-.64.22-.13.49-.15.72-.03l5.5 2.75c.26.13.42.39.42.67s-.16.54-.42.67l-5.5 2.75c-.1.05-.22.08-.33.08-.14 0-.27-.04-.39-.11A.742.742 0 0 1 4 9.5z" />
					</svg>
				</button>

				<button
					type="button"
					title="Bold"
					aria-label="Bold"
					onClick={() => editor.chain().focus().toggleBold().run()}
					className={
						editor.isActive('bold') ? 'is-active editor-button' : 'editor-button'
					}>
					<svg width="15" viewBox="-42 0 426 426.7" xmlns="http://www.w3.org/2000/svg">
						<path d="M280 198A111 111 0 0 0 208 0H22a21 21 0 1 0 0 43h21v341H22a21 21 0 1 0 0 43h197a123 123 0 0 0 61-229zM208 43a69 69 0 0 1 0 138H86V43zm11 341H86V224h133a80 80 0 0 1 0 160zm0 0" />
					</svg>
				</button>
				<button
					type="button"
					title="Italic"
					aria-label="Italic"
					onClick={() => editor.chain().focus().toggleItalic().run()}
					className={
						editor.isActive('italic') ? 'is-active editor-button' : 'editor-button'
					}>
					<svg width="15" viewBox="-106 0 426 426.7" xmlns="http://www.w3.org/2000/svg">
						<path d="M192 0h-64a21 21 0 1 0 0 43h4L37 384H22a21 21 0 1 0 0 43h64a21 21 0 1 0 0-43h-4l95-341h15a21 21 0 1 0 0-43zm0 0" />
					</svg>
				</button>
				<button
					type="button"
					title="Underline"
					aria-label="Underline"
					onClick={() => editor.chain().focus().toggleUnderline().run()}
					className={
						editor.isActive('underline') ? 'is-active editor-button' : 'editor-button'
					}>
					<svg width="15" viewBox="-21 0 426 426.7" xmlns="http://www.w3.org/2000/svg">
						<path d="M363 0h-85a21 21 0 1 0 0 43h21v170a107 107 0 0 1-213 0V43h21a21 21 0 1 0 0-43H22a21 21 0 1 0 0 43h21v170a150 150 0 0 0 299 0V43h21a21 21 0 1 0 0-43zM342 384H43a21 21 0 1 0 0 43h299a21 21 0 1 0 0-43zm0 0" />
					</svg>
				</button>
				<button
					type="button"
					title="Strikethrough"
					aria-label="Strikethrough"
					onClick={() => editor.chain().focus().toggleStrike().run()}
					className={
						editor.isActive('strike') ? 'is-active editor-button' : 'editor-button'
					}>
					<svg width="15" viewBox="-42 0 426 426.7" xmlns="http://www.w3.org/2000/svg">
						<path d="M320 192H171c-47 0-85-33-85-75 0-41 38-74 85-74s85 33 85 74a21 21 0 1 0 43 0C299 53 242 0 171 0S43 53 43 117c0 29 11 55 29 75H22a21 21 0 1 0 0 43h149c47 0 85 33 85 74 0 42-38 75-85 75s-85-33-85-75a21 21 0 1 0-43 0c0 65 57 118 128 118s128-53 128-118c0-28-11-54-29-74h50a21 21 0 1 0 0-43zm0 0" />
					</svg>
				</button>
				<button
					type="button"
					title="Bullet List"
					aria-label="Bullet List"
					onClick={() => editor.chain().focus().toggleBulletList().run()}
					className={
						editor.isActive('bullet') ? 'is-active editor-button' : 'editor-button'
					}>
					<svg width="15" viewBox="0 -43 512 512" xmlns="http://www.w3.org/2000/svg">
						<path d="M85.33 42.67a42.66 42.66 0 1 1-85.33 0 42.66 42.66 0 0 1 85.33 0zM85.33 213.33a42.67 42.67 0 1 1-85.33 0 42.67 42.67 0 0 1 85.33 0zM85.33 384A42.67 42.67 0 1 1 0 384a42.67 42.67 0 0 1 85.33 0zM170.67 64h320c11.8 0 21.33-9.54 21.33-21.33s-9.54-21.34-21.33-21.34h-320c-11.8 0-21.34 9.54-21.34 21.34S158.87 64 170.67 64zM490.67 192h-320a21.32 21.32 0 1 0 0 42.67h320c11.8 0 21.33-9.54 21.33-21.34S502.46 192 490.67 192zM490.67 362.67h-320c-11.8 0-21.34 9.53-21.34 21.33s9.54 21.33 21.34 21.33h320c11.8 0 21.33-9.53 21.33-21.33s-9.54-21.33-21.33-21.33zm0 0" />
					</svg>
				</button>
				<button
					type="button"
					title="Ordered List"
					aria-label="Ordered List"
					onClick={() => editor.chain().focus().toggleOrderedList().run()}
					className={
						editor.isActive('listItem') ? 'is-active editor-button' : 'editor-button'
					}>
					<svg width="15" viewBox="0 -10 490.67 490" xmlns="http://www.w3.org/2000/svg">
						<path d="M48 171H16a16 16 0 0 0 0 32h32c2.95 0 5.33 2.4 5.33 5.33v5.34A5.33 5.33 0 0 1 48 219H37.33A37.37 37.37 0 0 0 0 256.33V283a16 16 0 0 0 16 16h53.33a16 16 0 0 0 0-32H32v-10.67c0-2.94 2.4-5.33 5.33-5.33H48a37.37 37.37 0 0 0 37.33-37.33v-5.34A37.37 37.37 0 0 0 48 171zM48 341.67H16a16 16 0 0 0 0 32h32a5.33 5.33 0 0 1 5.33 5.33v5.33a5.33 5.33 0 0 1-5.33 5.34H26.67a16 16 0 0 0 0 32H48a5.33 5.33 0 0 1 5.33 5.33v5.33a5.33 5.33 0 0 1-5.33 5.34H16a16 16 0 0 0 0 32h32a37.37 37.37 0 0 0 37.33-37.34V427c0-7.94-2.54-15.27-6.78-21.33a37.08 37.08 0 0 0 6.78-21.34V379A37.37 37.37 0 0 0 48 341.67zM16 32.33h16v80a16 16 0 0 0 32 0v-96a16 16 0 0 0-16-16H16a16 16 0 0 0 0 32zM149.33 85.67h320c11.8 0 21.34-9.54 21.34-21.34S481.13 43 469.33 43h-320C137.53 43 128 52.54 128 64.33s9.54 21.34 21.33 21.34zM469.33 213.67h-320c-11.8 0-21.33 9.53-21.33 21.33s9.54 21.33 21.33 21.33h320c11.8 0 21.34-9.53 21.34-21.33s-9.54-21.33-21.34-21.33zM469.33 384.33h-320c-11.8 0-21.33 9.54-21.33 21.34S137.54 427 149.33 427h320a21.32 21.32 0 1 0 0-42.67zm0 0" />
					</svg>
				</button>
				<button
					className="editor-button"
					type="button"
					title="Clear Formatting"
					aria-label="Clear Formatting"
					onClick={clearFormatting}>
					<svg
						width="15"
						viewBox="0 0 426 426"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="m229 265-23 132c-2 10-10 17-20 17h-10c-12 0-22-11-20-24l30-168L14 50c-8-8-8-21 0-29l7-7c8-8 21-8 29 0l362 362c8 8 8 21 0 29l-7 7c-8 8-21 8-29 0L229 265Zm-15-135c-4-4-6-10-6-16l6-52h-64c-3 0-6-1-8-3l-30-30c-6-6-1-17 7-17h275c11 0 20 9 20 20v10c0 11-9 20-20 20H265l-9 65c-2 16-22 23-34 11l-8-8Z"
							fill="#000"
						/>
					</svg>
				</button>
			</StyledTiptapMenuBar>
		</>
	);
};

export default TiptapMenuBar;
