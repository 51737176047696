import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { version } from '@/../package.json';

import { media } from '@/Shared/theme';

const StyledCopy = styled.div`
	background: rgba(255, 255, 255, 0.8);
	color: var(--body-text);
	font-size: 1.2rem;
	padding: var(--spacing-2) var(--spacing-6) var(--spacing-2);
	text-align: right;
	z-index: 4;

	${media.smallScreen} {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 5%, var(--c-white)) 100%;
	}

	small {
		display: block;
	}

	a {
		color: var(--org-color);
	}
`;

const PrivacyTagLine = () => {
	const copyrightYear = new Date().getFullYear();
	const { pathname } = useLocation();

	if (pathname.includes('dashboard')) {
		return null;
	}

	return (
		<>
			<StyledCopy>
				<small>
					Copyright All Rights Reserved &copy; {copyrightYear} Stakk IO | v{version} |{' '}
					<Link to="/privacy-policy">Privacy Policy</Link>
				</small>
			</StyledCopy>
		</>
	);
};

export default PrivacyTagLine;
