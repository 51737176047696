import { gql } from 'graphql-request';

import { prefix } from './utils';

export const getGetCurrentUser = gql`
	query GetCurrentUser($id: String) {
		${prefix}users(where: { user_firebase_id: { _eq: $id } }) {
			user_id
			user_email
			user_first
			user_last
			user_role
			dept_chair {
				user_email
			}
		}
	}	
`;
