import styled from 'styled-components';

export const StyledCourseHeader = styled.div`
	margin-bottom: var(--spacing-4);
	width: 100%;

	.title {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}

	.date {
		color: var(--body-text);
		font-family: var(--font-regular);
		font-size: 1.3rem;
		font-style: italic;
		font-weight: 100;
	}
`;
