import { format } from 'date-fns';
import { StylesConfig } from 'react-select';

export const prefix = `${import.meta.env.VITE_ORG_PREFIX}`;

/**
 * Helper function to rename and destructure object properties based on a org prefix.
 * @param obj The object whose properties are to be renamed and destructured.
 * @param prefix The org prefix to filter properties by.
 * @returns An object containing the renamed properties.
 */
export function renameAndDestructure<T extends Record<string, any>>(
	obj: T,
	prefix: string
): Partial<Record<keyof T, any>> {
	const renamedProperties: Partial<Record<keyof T, any>> = {};

	Object.entries(obj).forEach(([key, value]) => {
		if (key.startsWith(prefix)) {
			// Remove the prefix from the key and add it to the new object
			const propertyName = key.substring(prefix.length) as keyof T;
			renamedProperties[propertyName] = value;
		}
	});

	return renamedProperties;
}

export const sanitizeOptions = {
	ALLOWED_ATTR: ['class'],
	ADD_ATTR: [
		'data-tip',
		'src',
		'class',
		'style',
		'title',
		'target',
		'allow',
		'allowfullscreen',
		'frameborder',
		'scrolling',
		'href',
	],
	ALLOWED_TAGS: ['iframe', 'br', 's', 'u', 'strong', 'em', 'span', 'a', 'p', 'ul', 'ol', 'li'],
};

export const capitalize = (str: string) => {
	if (!str || str.length === 0) {
		return '';
	}
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getFormattedDate = (date: string | null) => {
	// replace - with / on date otherwise it will return previous day
	return date ? format(new Date(date.replace(/-/g, '/')), 'LLL d, yyyy') : null;
};

export const isObjectEmpty = <T extends object>(obj: T) => {
	return Object.keys(obj).length === 0;
};

export const isLocalStorageAvailable = () => {
	try {
		if (typeof localStorage !== 'undefined') {
			return true;
		}
	} catch (e) {
		return false;
	}
	return false;
};

export const themeSelectStyles: StylesConfig<any, false> = {
	option: (provided, state) => {
		let color = 'white';
		if (state.isSelected) {
			color = 'hsl(217, 68%, 96%)';
		}
		if (state.isFocused) {
			color = 'hsl(217, 68%, 96%)';
		}

		return {
			...provided,
			'&:active': {
				backgroundColor: 'hsl(218, 11%, 76%)',
			},
			color: '#333',
			borderColor: state.isFocused ? 'var(--elevate-primary)' : 'none',
			backgroundColor: color,
		};
	},
	control: (provided, state) => ({
		...provided,
		boxShadow: state.isFocused ? '0 0 0 1px var(--elevate-primary)' : 'none',
		borderColor: state.isFocused ? 'var(--elevate-primary)' : 'var(--input-border)',
		'&:hover': {
			borderColor: state.isFocused ? 'var(--elevate-primary)' : 'none',
		},
		height: '40px',
		borderRadius: '6px',
		svg: {
			width: '18px',
			height: '18px',
			background: 'rgb(239, 239, 239)',
			fill: '#000',
			borderRadius: '50%',
			'&:hover': {
				background: 'var(--elevate-primary)',
				fill: '#fff',
			},
		},
	}),
	menu: (provided) => ({
		...provided,
		borderRadius: '6px',
	}),
	menuList: (provided) => ({
		...provided,
		maxHeight: '200px',
	}),
	container: (provided) => ({
		...provided,
		width: '100%',
		'.css-art2ul-ValueContainer2': {
			padding: 'var(--spacing-1) var(--spacing-2)',
		},
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return { ...provided, opacity, transition };
	},
};

// check if string has content by removing all tags except iframe
// and checking if it's empty
export const hasContent = (str: string | undefined) =>
	str !== null && str?.replace(/<(?!\/?(iframe)\b)[^>]+>/gm, '') !== '';

// Function to create an email submission link based on the provided URL
export function createEmailSubmissionLink(url: string) {
	// Replace '/courses' with '/submissions' in the URL
	let submissionUrl = `${url.replace('/courses', '/submissions')}]`;

	// Check if the submission URL contains an anchor (#)
	if (submissionUrl.includes('#')) {
		// If it does, remove everything after the '#' symbol
		submissionUrl = submissionUrl.split('#')[0];
	}

	// Append the query parameter 'isApprovalMode=true' to the submission URL
	return `${submissionUrl}?isApprovalMode=true`;
}
