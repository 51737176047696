import { SubmitHandler, useForm } from 'react-hook-form';

import { FormErrorMessage } from '@/components/FormErrorMessage';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useCourseLessonsStore } from '@/stores/lessonStore';

import { StyledLessonPlanForm, StyledLessonPlanPanel } from './CourseLessonPlans.Styles';
import LessonPlanGroup from './LessonPlanGroup';

interface FormValues {
	groupTitle: string;
}

const LessonPlans = () => {
	const { lessons, addLessonSection } = useCourseLessonsStore();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormValues>();

	const onSubmit: SubmitHandler<FormValues> = ({ groupTitle }) => {
		const title = groupTitle.trim();
		addLessonSection(title);
		reset();
	};

	return (
		<>
			<StyledLessonPlanPanel>
				<StyledLessonPlanForm onSubmit={handleSubmit(onSubmit)}>
					<Input
						type="text"
						placeholder="Add lesson plan title..."
						{...register('groupTitle', {
							required: 'Title is required',
							validate: (value) => {
								if (value.trim() === '') {
									return 'Title is required';
								}
								return true;
							},
						})}
					/>
					<FormErrorMessage
						className="error-message"
						isShowing={!!errors?.groupTitle}
						message={errors.groupTitle?.message}
					/>
					<Button variant="secondary" size="small" type="submit">
						Add Group
					</Button>
				</StyledLessonPlanForm>
				{lessons.map((lesson) => (
					<LessonPlanGroup key={lesson.id} group={lesson} />
				))}
			</StyledLessonPlanPanel>
		</>
	);
};

export default LessonPlans;
