import styled from 'styled-components';

export const StyledUnit = styled.div`
	box-shadow: 0 1px rgba(72 73 105 / 20%);
	display: grid;
	grid-template-columns: 32px auto;
	grid-template-rows: auto auto;
	margin-bottom: var(--spacing-6);
	margin-right: var(--spacing-4);
	padding-bottom: var(--spacing-4);
	position: relative;
	width: 100%;

	&.dragging {
		box-shadow: none;
	}

	.drag-icon-wrapper {
		display: flex;
	}

	.drag-icon-wrapper + .single-unit {
		grid-column: 2;
	}

	.single-unit {
		align-items: center;
		display: flex;
		gap: var(--spacing-4);
		margin-bottom: var(--spacing-4);
		min-width: 100%;

		&:empty {
			display: none;
		}

		.tiptap {
			width: 100%;
		}

		.input-container {
			flex: auto;
			max-width: none;
			width: 100%;
		}
	}

	.ProseMirror {
		min-height: auto;
	}

	.actions {
		grid-column: 2;
		grid-row: 2;
		margin-left: var(--spacing-4);
	}

	.diff-container {
		grid-column: 1/3;
	}

	// p tags are removed from diff container
	// hack to fix alignment of diff container content
	p + br + br {
		display: none;
	}
`;

export const StyledUnitGroup = styled.div`
	& ${StyledUnit}:nth-last-child(1) {
		box-shadow: none;
		padding-bottom: 0;
	}
`;

export const StyledUnitDetails = styled.div`
	display: flex;
	flex-direction: column;
	grid-column: 2/3;
	margin-top: var(--spacing-4);

	.approval-mode & {
		grid-column: 1/3;
	}

	.single-detail {
		align-items: center;
		display: grid;
		grid-column-gap: var(--spacing-4);
		grid-template-columns: 1fr 100px;
		justify-items: baseline;
		margin-bottom: var(--spacing-4);
		margin-left: var(--spacing-4);
		max-width: 800px;
	}

	.diff-wrap {
		margin-bottom: var(--spacing-4);
		margin-left: var(--spacing-4);
	}

	textarea {
		resize: vertical;
	}
`;
