import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { initializeApp, getApp, getApps } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { GraphQLClient } from 'graphql-request';

import { GetUsersQuery } from '@/graphql/graphql';
import { getGetCurrentUser } from '@/queries';
import { prefix, renameAndDestructure } from '@/utils';

const graphQLClient = new GraphQLClient(`${import.meta.env.VITE_HASURA_ENDPOINT}`);

export const useSession = () => {
	const auth = getAuth();
	const user = auth.currentUser;

	return user;
};

export const useAuth = () => {
	if (!getApps()?.length) {
		initializeApp({
			apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
			authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
			databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
			projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
			storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
		});
	} else {
		getApp();
	}

	const [userRef, setUserRef] = useState(() => {
		const auth = getAuth();
		const user = auth.currentUser;

		return {
			initializing: !user,
			user,
		};
	});

	function onChange(user: any) {
		setUserRef({ initializing: false, user });
	}

	useEffect(() => {
		const auth = getAuth();
		// listen for auth state changes
		const unsubscribe = onAuthStateChanged(auth, (user) => onChange(user));

		// unsubscribe to the listener when unmounting
		return () => unsubscribe();
	}, []);

	return userRef;
};

export const useUserRole = (refetchOnMount: boolean | 'always' = true) => {
	if (!getApps()?.length) {
		initializeApp({
			apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
			authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
			databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
			projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
			storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
		});
	} else {
		getApp();
	}
	return useQuery({
		queryKey: ['get-current-user-role'],

		queryFn: async () => {
			const auth = await getAuth();
			const user = auth.currentUser;
			graphQLClient.setHeader('content-type', `application/json`);

			const result = await graphQLClient.request<GetUsersQuery>(getGetCurrentUser, {
				id: user?.uid,
			});
			const { users } = renameAndDestructure(result, prefix) as {
				users: GetUsersQuery['dev_users'];
			};
			return users[0];
		},

		staleTime: Infinity,
		refetchOnMount,
	});
};

export const currentUser = async () => {
	if (!getApps()?.length) {
		initializeApp({
			apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
			authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
			databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
			projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
			storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
		});
	} else {
		getApp();
	}
	const auth = await getAuth();
	const user = auth.currentUser;
	const data = await graphQLClient.request<GetUsersQuery>(getGetCurrentUser, { id: user?.uid });
	const { users } = renameAndDestructure(data, prefix) as {
		users: GetUsersQuery['dev_users'];
	};

	return users?.[0];
};

export const useCurrentUser = (enabled = true) => {
	if (!getApps()?.length) {
		initializeApp({
			apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
			authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
			databaseURL: import.meta.env.VITE_FIREBASE_DB_URL,
			projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
			storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
			messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
		});
	} else {
		getApp();
	}

	return useQuery({
		queryKey: ['get-current-user'],

		queryFn: async () => {
			const auth = await getAuth();
			const user = auth.currentUser ?? '';
			graphQLClient.setHeader('content-type', `application/json`);

			if (!user) return null;

			const results = await graphQLClient.request<GetUsersQuery>(getGetCurrentUser, {
				id: user?.uid,
			});
			const { users } = renameAndDestructure(results, prefix) as {
				users: GetUsersQuery['dev_users'];
			};
			return users?.[0];
		},

		staleTime: Infinity,
		enabled,
	});
};
