import { useState } from 'react';

import { httpsCallable, getFunctions } from 'firebase/functions';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import logo from '@/assets/logo.svg';
import { BoxedIconMessage } from '@/components/BoxedIconMessage';
import { FormErrorMessage } from '@/components/FormErrorMessage';
import { ToastNotification } from '@/components/ToastNotification';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { StyledP } from '@/Shared/Typography/typography';

import {
	StyledBG,
	StyledFeatherBackgroundTop,
	StyledFeatherBackgroundBottom,
	StyledForm,
} from './ResetPasswordStyles';

interface FormValues {
	email: string;
}

const ResetPassword = () => {
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = useForm<FormValues>();
	const [messageState, setMessageState] = useState({
		message: '',
		messageColor: 'var(--green-300)',
		hasError: false,
	});

	const onSubmit: SubmitHandler<FormValues> = async (resetFormData) => {
		const { email } = resetFormData;
		const firebaseFunction = getFunctions();
		const sendPasswordResetEmail = httpsCallable(firebaseFunction, 'sendPasswordResetEmail');

		try {
			const { data: resetLink } = await sendPasswordResetEmail({
				email: email.trim(),
			});

			if (resetLink === '') {
				throw new Error('Network response was not ok');
			}

			const url = new URL(`${resetLink}`);
			const oobCode = url.searchParams.get('oobCode');
			const apiKey = url.searchParams.get('apiKey');

			const resetPasswordLink = `${
				window.location.origin
			}/set-password?oobCode=${oobCode}&apiKey=${apiKey}&email=${email.trim()}`;

			const emailData = {
				service_id: 'service_3pf8c05',
				template_id: 'template_wco2nkp',
				user_id: 'user_MTnrIn1vgu5DZSpVYUo53',
				template_params: {
					emailRecipient: email.trim(),
					resetPasswordLink,
				},
			};

			// send user an email to finish setting up their account
			const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(emailData),
			});

			if (response.status !== 200) {
				throw new Error('Network response was not ok');
			}

			setMessageState({
				message: `We've sent the password reset instructions to your email—just give it a few minutes. Check your inbox, and if it's not there, take a peek in your spam folder.`,
				messageColor: 'var(--green-300)',
				hasError: false,
			});
			setTimeout(() => {
				setMessageState({
					...messageState,
					message: '',
					hasError: false,
				});
			}, 8000);
		} catch (error: any) {
			if (error.code === 'auth/user-not-found') {
				setMessageState({
					message:
						'The email entered does not have an associated account. Please double check your email or contact your administrator.',
					messageColor: 'var(--red-200)',
					hasError: true,
				});
				return;
			}

			setMessageState({
				message: 'Something went wrong. Please try again later.',
				messageColor: 'var(--red-200)',
				hasError: true,
			});
		}
	};

	return (
		<StyledBG>
			<StyledFeatherBackgroundTop />
			<StyledFeatherBackgroundBottom />
			<img src={`${logo}`} alt="Elevate logo" className="logo" />
			<StyledForm onSubmit={handleSubmit(onSubmit)} data-testid="form">
				<StyledP mb="var(--spacing-4)">
					Forgot your account's password or having trouble logging in? Enter your email
					address and we'll send you a recovery link.
				</StyledP>
				<StyledP mb="var(--spacing-8)">
					If you remember your password, please click <Link to="/login">here</Link> to
					login.
				</StyledP>
				<label htmlFor="email">Email Address</label>
				<Input
					type="text"
					id="email"
					data-testid="email"
					{...register('email', {
						required: 'Email is required',
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
							message: 'Invalid email address',
						},
					})}
				/>
				<FormErrorMessage
					className="error-message"
					isShowing={!!errors?.email}
					message={errors.email?.message}
				/>
				<Button
					type="submit"
					disabled={isSubmitting}
					isLoading={isSubmitting}
					className="w-[max-content]">
					Send Email
				</Button>
				{messageState.message !== '' && (
					<BoxedIconMessage
						message={messageState.message}
						messageColor={messageState.messageColor}
						hasError={messageState.hasError}
					/>
				)}
			</StyledForm>
			<ToastNotification />
		</StyledBG>
	);
};

export default ResetPassword;
