import { useContext } from 'react';

import { useIsMutating } from '@tanstack/react-query';

import { Button } from '@/components/ui/button';
import { useCourseLessonsStore } from '@/stores/lessonStore';

import CourseEditContext from '../CourseEditContext';

import { StyledLessonPlanFooter } from './CourseLessonPlans.Styles';

const LessonPlanFooter = () => {
	const { addLesson, lessons } = useCourseLessonsStore();
	const { courseData, updatePlans } = useContext(CourseEditContext);
	const isDisabled = lessons.length === 0;
	const isMutatingLessons = useIsMutating({ mutationKey: ['save-course-lesson'] });

	return (
		<StyledLessonPlanFooter>
			<Button variant="secondary" type="button" onClick={addLesson} disabled={isDisabled}>
				Add Lesson Plan
			</Button>
			<Button
				type="button"
				isLoading={isMutatingLessons !== 0}
				disabled={isMutatingLessons !== 0}
				onClick={() => updatePlans(lessons, courseData.course_id)}>
				Save Planning
			</Button>
		</StyledLessonPlanFooter>
	);
};

export default LessonPlanFooter;
