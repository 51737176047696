/* eslint-disable no-underscore-dangle */
import * as d3 from 'd3';

import { MapMargin, NodeEntity } from './coursemap.types';

export const rectNode = { width: 200, height: 110, extWidth: 250 };

// Collapse the node and all it's children
export function collapseTree(d: NodeEntity) {
	if (d.children) {
		d._children = d.children;
		d._children.forEach(collapseTree);
		d.children = null;
	}
}

// Expand the node and all it's children
export function expandTree(d: NodeEntity) {
	const children = d.children ? d.children : d._children;
	if (d._children) {
		d.children = d._children;
		d._children = null;
	}
	if (children) {
		children.forEach((n: NodeEntity) => {
			expandTree(n);
		});
	}
}

export function click(d: NodeEntity, callback: (d: NodeEntity) => void, isAdmin: boolean) {
	if (isAdmin) {
		return;
	}
	if (d.children) {
		d._children = d.children;
		d.children = null;
	} else {
		d.children = d._children;
		d._children = null;
		expandTree(d);
	}
	callback(d);
}

/**
 * Creates the pan limits for the map based on the number of node children, margins, client width, and admin status.
 *
 * @param length - The number of node children.
 * @param margin - The map margin configuration.
 * @param clientWidth - The width of the client viewport.
 * @param isAdmin - The flag indicating whether the user is an admin.
 * @returns The pan limits as an array of coordinate bounds.
 */
export function createPanLimits(
	length: number,
	margin: MapMargin,
	clientWidth: number,
	isAdmin: boolean
) {
	const nodeChildren = length;
	const margins = margin.left + margin.right;
	const xMaxPan = nodeChildren * clientWidth + margins;
	const yMaxPan = (nodeChildren / 2) * clientWidth - margins;

	const noBounds = [
		[-Infinity, -Infinity],
		[Infinity, Infinity],
	] as [[number, number], [number, number]];

	const limitedBounds = [
		[-clientWidth, -yMaxPan],
		[xMaxPan, yMaxPan],
	] as [[number, number], [number, number]];

	return isAdmin ? noBounds : limitedBounds;
}

interface RootCoords {
	name: string;
	x: number;
	y: number;
	parent: any;
}
/**
 * Creates a curved (diagonal) path from parent to the child nodes in a tree layout.
 *
 * @param s - The source node coordinates.
 * @param d - The destination node coordinates.
 * @returns The SVG path string for the diagonal connection.
 */
export function diagonal(s: RootCoords | NodeEntity, d: RootCoords | NodeEntity) {
	let path = `M ${s.y} ${s.x} C ${(s.y + d.y) / 2} ${s.x},${s.y + 10} ${d.x},${d.y} ${d.x}`;

	const name = (s as NodeEntity).data?.name ?? (s as RootCoords).name;
	if (name.includes('Spacer')) {
		path = `M ${s.y + 0} ${s.x} L ${s.y} ${s.x} C ${(s.y + d.y) / 2} ${s.x},${s.y + 10} ${
			d.x
		},${d.y} ${d.x}`;

		if (
			(d as NodeEntity).data.name.includes('Spacer') &&
			(s as NodeEntity).data.children?.length === 0
		) {
			path = `M ${s.y + rectNode.width} ${s.x} L ${s.y} ${s.x} C ${(s.y + d.y) / 2} ${s.x},${
				s.y + 10
			} ${d.x},${d.y} ${d.x}`;
		}
	}

	return path;
}

// ? add for straight lines replace('C', 'L');
/**
 * Creates the SVG path string for drawing connections between course map nodes.
 *
 * @param d - The data object representing a node.
 * @returns The SVG path string for the connection.
 */
export const createCourseMapPaths = (d: any) => {
	if (d.name.includes('Spacer') && d.parent.y !== d.y) {
		return `M${d.y},${d.x}C${(d.y + d.parent.y) / 2},
		${d.x} ${(d.y + d.parent.y) / 2 - rectNode.extWidth + 50},
		${d.parent.x} ${d.parent.y - 150},${d.parent.x}`;
	}
	if (d.parent.y === d.y) {
		return `M${d.y - rectNode.width / 2},${d.x} L${d.parent.y - rectNode.width / 2},${
			d.parent.x
		}`;
	}
	return `M${d.y},${d.x}C${(d.y + d.parent.y) / 2},
		${d.x} ${(d.y + d.parent.y) / 2 - rectNode.extWidth},
		${d.parent.x} ${d.parent.y},${d.parent.x}`;
};

/**
 * Positions and adjusts the text elements for discipline nodes in a hierarchical layout.
 *
 * @param text - The text elements to position and adjust.
 * @param height - The height of the container element.
 */
export function wrapDiscipline(text: d3.Selection<any, NodeEntity, any, any>, height: number) {
	text.attr('dominant-baseline', 'middle');
	// eslint-disable-next-line func-names
	text.each(function (this: any, d: NodeEntity) {
		const isDiscipline = d.data?.isDiscipline;
		if (isDiscipline || d.depth === 0) {
			const currentText = d3.select(this);
			const childCount = currentText.node().childElementCount;
			const offset = childCount < 2 ? -14 : 0;
			const textHeight = currentText.node().getClientRects()[0].height;
			const y = (height / 2 + textHeight / 2) / childCount;
			currentText.attr('y', y + offset);
			currentText.selectAll('tspan').attr('y', y + offset);
		}
	});
}

/**
 * Wraps text within a specified width by creating multiple lines.
 *
 * @param text - The text elements to wrap.
 * @param width - The maximum width for each line of text.
 */
export function wrap(text: d3.Selection<SVGTextElement, any, any, any>, width: number) {
	// Iterate over each text element
	// eslint-disable-next-line func-names
	text.each(function (this: any) {
		const text = d3.select(this);
		const words = text.text().split(/\s+/);
		let line: string[] = [];
		let lineNumber = 0;
		const lineHeight = 1.3;
		const x = text.attr('x');
		const y = text.attr('y');
		const dy = 0;
		let tspan: any = text
			.text(null)
			.append('tspan')
			.attr('x', x)
			.attr('y', y)
			.attr('dy', `${dy}em`);

		// Iterate over each word in the text
		for (let i = 0; i < words.length; i += 1) {
			line.push(words[i]);
			tspan.text(line.join(' '));
			// If the width of the current line exceeds the specified width
			if (tspan?.node().getComputedTextLength() > width) {
				line.pop();
				tspan.text(line.join(' '));
				line = [words[i]];
				// Create a new line by appending a new 'tspan' element
				tspan = text
					.append('tspan')
					.attr('x', x)
					.attr('y', y)
					.attr('dy', `${(lineNumber += 1 * lineHeight + dy)}em`)
					.text(words[i]);
			}
		}
	});
}

export function isEmpty(obj: string) {
	return Object.keys(JSON.parse(obj)).length === 0;
}
