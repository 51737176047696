import { useContext, useState, useEffect } from 'react';

import { collection, where, getFirestore, query, onSnapshot } from 'firebase/firestore';

import { Button } from '@/components/ui/button';
import { StyledTextArea } from '@/Shared/StyledElements';
import { useCourseEditStore } from '@/stores/courseEditStore';
import { prefix } from '@/utils';

import CourseEditContext from '../CourseEditContext';

import { StyledComments, StyledCommentPlaceholder } from './CourseComments.Styles';
import CourseThreadItem from './CourseThreadItem';
import { sortComments, replyToComment } from './utils';

const CourseComments = () => {
	const { courseData, currentUserData, isApprovalMode } = useContext(CourseEditContext);
	const { commentTypes } = useCourseEditStore();
	const [comments, setComments] = useState<UserComment[]>([]);

	useEffect(() => {
		const getComments = async (types: string[]) => {
			const db = getFirestore();
			const msgPath = `${prefix}/comments`;
			const commentFilter = isApprovalMode ? 'submission' : 'general';

			const q = query(
				collection(db, `${msgPath}/${courseData.course_id}`),
				where('commentType', '==', commentFilter)
			);
			onSnapshot(q, (snap) => {
				if (!snap.empty) {
					const data = snap.docs.map((doc) => {
						return { uid: doc.id, ...doc.data() };
					}) as UserComment[];
					// filter out comments that are not in the current editor
					const filteredComments = data.filter((x) => {
						const regex = new RegExp(types.join('|'));
						return regex.test(x.editorType);
					});
					setComments(sortComments(filteredComments));
				}
				if (snap.empty) {
					setComments([]);
				}
			});
		};

		getComments(commentTypes);

		return () => {
			setComments([]);
		};
	}, [commentTypes, courseData.course_id, isApprovalMode]);

	if (comments.length === 0 || !currentUserData) {
		return <StyledCommentPlaceholder>No Comments...</StyledCommentPlaceholder>;
	}

	const {
		user_first: firstName = '',
		user_last: lastName = '',
		user_id: userId = '',
		user_role: userRole = '',
	} = currentUserData;

	return (
		<StyledComments>
			{comments &&
				comments.map((comment: UserComment) => (
					<div
						key={comment.id}
						data-comment-source={`comment-${comment.id}`}
						className="comment-container">
						<div className="comment">
							<CourseThreadItem
								reply={comment}
								comment={comment}
								courseId={courseData.course_id}
								userId={userId}
								userRole={userRole}
							/>
						</div>

						{comment.thread?.map((reply: UserComment) => (
							<div className="comment comment-reply" key={reply.id}>
								<CourseThreadItem
									reply={reply}
									comment={comment}
									courseId={courseData.course_id}
									userId={userId}
									userRole={userRole}
								/>
							</div>
						))}
						<form className="reply-from">
							<StyledTextArea placeholder="Reply to comment..." name="comment" />
							<Button
								type="button"
								onClick={(e) =>
									replyToComment(
										e,
										comment,
										`${firstName} ${lastName}`,
										courseData.course_id,
										userId
									)
								}
								variant="secondary"
								className="reply-btn"
								size="xs">
								Reply
							</Button>
						</form>
					</div>
				))}
		</StyledComments>
	);
};

export default CourseComments;
