import { Button } from '../ui/button';

export default function AppUpdateNotification() {
	const handleOpenUpdates = () => {
		window.open('https://headwayapp.co/elevate-updates', '_blank', 'noopener,noreferrer');
	};

	return (
		<>
			<Button
				className="update-notification mb-4"
				onClick={handleOpenUpdates}
				variant="secondary"
				aria-label="Open updates in new tab">
				<span id="app-update-indicator" />
				<span className="update-notification-label">Latest Updates</span>
			</Button>
		</>
	);
}
