import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';

interface LessonsState {
	lessons: LessonSection[];
	removeLesson: (groupId: string, lessonId: string) => void;
	removeGroup: (groupId: string) => void;
	addLessonSection: (title: string) => void;
	addLesson: () => void;
	setLessonPlans: (plans: LessonSection[]) => void;
	updateLesson: (value: string, lesson: Lesson | LessonSection, prop: string) => void;
	currentGroupIndex: string;
	setGroupIndex: (index: string) => void;
	currentIndex: number;
	setIndex: (index: number) => void;
}

export const useCourseLessonsStore = create<LessonsState>((set: any, get: any) => ({
	lessons: [
		{
			id: uuidv4(),
			title: 'Sample 21',
			lessons: [
				{
					id: uuidv4(),
					title: 'Welcome',
					introduction: 'intro',
					lessons: 'some',
					objectives: ' objectives',
					resources: '',
					enrichment: 'some ',
					supportMaterial: '',
					bellringer: '',
					hasAdvanced: true,
				},
			],
		},
	],
	setLessonPlans: (plans: LessonSection[]) => {
		set({ lessons: plans });
	},
	currentGroupIndex: '',
	currentIndex: 0,
	setIndex: (id) => {
		set({ currentIndex: id });
	},
	setGroupIndex: (id) => {
		set({ currentGroupIndex: id, currentIndex: 0 });
	},
	updateLesson: (value, lesson, prop) => {
		const { lessons, currentIndex } = get();
		const currentGroup = lessons.findIndex((group: LessonSection) => group.id === lesson.id);

		if (prop === 'group' && lessons[currentGroup]) {
			lessons[currentGroup].title = value;
		} else {
			lessons[currentGroup].lessons[currentIndex][prop] = value;
		}
		set({ lessons });
	},
	addLesson: () => {
		const { lessons, currentGroupIndex } = get();
		const currentGroup =
			lessons.findIndex((group: LessonSection) => group.id === currentGroupIndex) ?? 0;

		lessons[currentGroup].lessons.push({
			id: uuidv4(),
			title: 'New Lesson',
			introduction: '',
			lessons: '',
			objectives: '',
			resources: '',
			enrichment: '',
			supportMaterial: '',
			bellringer: '',
			hasAdvanced: false,
		});
		set({ lessons });
	},
	addLessonSection: (title) => {
		const { lessons } = get();
		set({
			lessons: [
				...lessons,
				{
					id: uuidv4(),
					title,
					lessons: [
						{
							id: uuidv4(),
							title: 'New Lesson',
							introduction: '',
							lessons: '',
							objectives: '',
							resources: '',
							enrichment: '',
							supportMaterial: '',
							bellringer: '',
							hasAdvanced: false,
						},
					],
				},
			],
		});

		set({ currentGroupIndex: get().lessons[get().lessons.length - 1].id });
	},
	removeGroup: (groupId) => {
		const { lessons } = get();

		const newLessons = lessons.filter((lesson: LessonSection) => lesson.id !== groupId);
		if (newLessons.length !== 0) {
			set({
				lessons: newLessons,
				currentGroupIndex: newLessons[newLessons.length - 1].id,
				currentIndex: 0,
			});
		} else {
			set({ lessons: newLessons, currentIndex: 0 });
		}
	},
	removeLesson: (groupId, lessonId) => {
		const { lessons, currentIndex } = get();

		if (lessons[currentIndex]?.id === groupId) {
			set({ currentIndex: 0 });
		}

		const currentGroup = lessons.findIndex((group: LessonSection) => group.id === groupId);
		const newLessons = lessons[currentGroup].lessons.filter(
			(lesson: Lesson) => lesson.id !== lessonId
		);
		if (newLessons.length === 0) {
			const filteredLessons = lessons.filter((group: LessonSection) => group.id !== groupId);
			set({ lessons: filteredLessons });
		}

		if (newLessons.length !== 0) {
			lessons[currentGroup].lessons = newLessons;
			set({ lessons, currentIndex: currentIndex - 1 });
		}
	},
}));
