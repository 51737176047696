import React, { useRef, useState, ChangeEvent, MouseEvent } from 'react';

import * as d3 from 'd3';
import Fuse from 'fuse.js';
import Select, { StylesConfig } from 'react-select';

import { NodeEntity } from '@/components/CourseMap/coursemap.types';
import { GetSiteSettingsAndDisciplinesQuery } from '@/graphql/graphql';
import useOutsideClick from '@/hooks/useOutsideClick';
import useParentMapStore from '@/stores/parentMapStore';
import { themeSelectStyles } from '@/utils';

import { StyledAutoComplete, StyledWrapper } from './MapFilters.Styles';

interface MapFiltersProps {
	dropdownOptions?: GetSiteSettingsAndDisciplinesQuery['dev_disciplines'];
}
const MapFilters = ({ dropdownOptions }: MapFiltersProps) => {
	const courseListRef = useRef<HTMLDivElement>(document.createElement('div'));

	const { courseCoordsArray, duration, zoom, d3InstanceRef } = useParentMapStore();
	const { current: svg } = d3InstanceRef;
	const courses = courseCoordsArray;

	function handleSelection(node: NodeEntity) {
		const nodeElm = document.querySelector(`.course-${node?.data.tracker}`) as HTMLElement;
		if (!nodeElm) {
			const ancestors = (node as any)?.ancestors();
			if (!ancestors) {
				return;
			}
			const baseNode = ancestors[ancestors.length - 2];
			const baseNodeDomRef = document.querySelector(
				`.course-${baseNode.data.tracker}`
			) as HTMLElement;

			d3.select(baseNodeDomRef).dispatch('click');
			setTimeout(() => {
				handleSelection(node);
			}, duration);
			return;
		}

		nodeElm.querySelector('.course-backdrop')?.classList.add('active-node');
		const gElement = nodeElm as any;
		const xCoords = (gElement.transform.baseVal[0].matrix.e - 200 - 180) * -1;
		const yCoords = gElement.transform.baseVal[0].matrix.f * -1 + 90;

		zoom.current.transform(
			svg
				.interrupt()
				.transition()
				.on('end', () => {
					setTimeout(() => {
						d3.selectAll('.course-backdrop').classed('active-node', false);
					}, 2000);
				})
				.duration(duration * 2),
			d3.zoomIdentity.translate(xCoords, yCoords).scale(1)
		);
	}

	const [showSuggestions, setShowSuggestions] = useState(false);
	const [userInput, setUserInput] = useState('');
	const [disciplineFilter, setDisciplineFilter] = useState('');
	const disciplinesNames = dropdownOptions?.map((d) => d.name) ?? [];

	const filteredCourses = courses?.filter(
		(c: NodeEntity) =>
			![`${import.meta.env.VITE_ORG}`, 'Elevate', '- Spacer -', ...disciplinesNames].includes(
				c.data.name
			)
	);
	const [suggestionsArray, setSuggestionsArray] = useState<any[]>();

	const options = {
		useExtendedSearch: true,
		threshold: 0.0,
		findAllMatches: true,
		shouldSort: true,
		ignoreLocation: true,
		keys: ['data.name'],
	};
	const fuse = new Fuse(filteredCourses, options);

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const userInput = e.currentTarget.value;

		const results = fuse.search(userInput) as Fuse.FuseResult<any>[];
		let filteredResults = results;
		if (disciplineFilter !== '') {
			filteredResults = results.filter((x) => x.item.data.discipline === disciplineFilter);
		}
		if (e.currentTarget.value !== '') {
			setShowSuggestions(true);
		}
		if (e.currentTarget.value === '') {
			setShowSuggestions(false);
		}
		setSuggestionsArray(filteredResults.map((x) => x.item));
		setUserInput(e.currentTarget.value);
	};

	const handleOnClick = (e: React.MouseEvent<HTMLElement>, node: NodeEntity) => {
		document.querySelector('.initial-node')?.classList.remove('stop-zoom');
		setShowSuggestions(false);
		setUserInput(e.currentTarget.innerText);
		handleSelection(node);
	};

	const handleOnKeyDown = (e: React.KeyboardEvent) => {
		// User pressed the enter key
		const searchValue = (e.target as HTMLInputElement).value;
		if (e.code === 'Enter') {
			if (userInput === '') return;
			const currentCourse = suggestionsArray?.find((c) => c.data.name === searchValue);
			if (currentCourse) {
				handleSelection(currentCourse);
			}
		}
	};

	function clearSelect() {
		setUserInput('');
		setShowSuggestions(false);
	}

	interface OptionType {
		value: string;
		id: string;
		label: string;
	}

	const customStyles: StylesConfig<OptionType, false> = {
		...themeSelectStyles,
		control: (provided, state) => ({
			...provided,
			...(themeSelectStyles as any).control(provided, state),
			width: '250px',
		}),
	};

	const formattedList = (
		dropdownOptions: GetSiteSettingsAndDisciplinesQuery['dev_disciplines']
	) => {
		return [...new Set(dropdownOptions)].map((item) => {
			return {
				value: item.name,
				label: item.name,
				id: item.id,
			};
		}) as OptionType[];
	};

	useOutsideClick(courseListRef, () => {
		setShowSuggestions(false);
	});

	return (
		<StyledWrapper className="map-search-bar" data-testid="mapFilters">
			<StyledAutoComplete ref={courseListRef}>
				<input
					tabIndex={0}
					placeholder="Search Course Map"
					type="text"
					onChange={handleOnChange}
					onKeyDown={handleOnKeyDown}
					value={userInput}
				/>
				{userInput !== '' && (
					<button type="button" onClick={clearSelect}>
						<svg
							aria-hidden="true"
							width="8"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor">
							<path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z" />
						</svg>
						<span className="screen-reader-text">Clear Dropdown</span>
					</button>
				)}
				{showSuggestions && (
					<ul className="suggestions">
						{suggestionsArray?.map((suggestion: any) => (
							<li
								aria-label={suggestion.data.name}
								aria-hidden
								key={`suggestion-${suggestion.data.tracker}-${suggestion.depth}-${suggestion.height}`}
								onClick={(e: MouseEvent<HTMLElement>) =>
									handleOnClick(e, suggestion)
								}>
								{suggestion.data.name}
							</li>
						))}
						{suggestionsArray?.length === 0 && (
							<li>
								<span>No matching courses found.</span>
							</li>
						)}
					</ul>
				)}
			</StyledAutoComplete>
			{dropdownOptions && (
				<Select
					data-testid="filterSelect"
					styles={customStyles}
					placeholder="Filter Search"
					onChange={(option) => setDisciplineFilter(`${option?.value}`)}
					isClearable
					options={formattedList(dropdownOptions)}
					noOptionsMessage={() => 'No Disciplines Found'}
				/>
			)}
		</StyledWrapper>
	);
};

export default MapFilters;
