import { useEffect } from 'react';

import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { EditorType } from '@/Enums/enum';

import { Video } from './extensions/embed';
import { StyledEditor } from './TipTapEditor.Styles';
import TiptapMenuBar from './TiptapMenuBar';

interface SimpleEditorProps {
	isEditable?: boolean;
	content: string;
	placeholder: string;
	className?: string;
	id?: string;
	onChange?: (content: string) => void;
}

const SimpleEditor = ({
	isEditable = true,
	content,
	className = '',
	placeholder,
	onChange,
	id = 'simple-editor',
}: SimpleEditorProps) => {
	const editor = useEditor(
		{
			extensions: [
				StarterKit.configure({
					bulletList: {},
				}),
				Link.configure({
					openOnClick: false,
					linkOnPaste: true,
				}),
				Placeholder.configure({
					showOnlyWhenEditable: true,
					placeholder,
				}),
				Video,
			],
			content,
			editable: isEditable,
			editorProps: {
				attributes: {
					spellcheck: 'false',
				},
			},
			onUpdate: ({ editor }) => {
				onChange?.(editor.getHTML());
			},
		},
		[]
	);

	useEffect(() => {
		editor?.commands.setContent(content);
	}, [id, content, editor]);

	if (!editor) {
		return null;
	}

	return (
		<StyledEditor className={`tiptap ${className}`}>
			{editor && <TiptapMenuBar editor={editor} type={EditorType.STANDARD} />}
			<section className="tiptap-container">
				<EditorContent className="editor-content" editor={editor} />
			</section>
		</StyledEditor>
	);
};

export default SimpleEditor;
