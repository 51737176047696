import useConfirmationDialog from '@/hooks/useConfirmationModal';
import { TrashCanIcon } from '@/icons';
import { useCourseLessonsStore } from '@/stores/lessonStore';

import LessonPlanEntry from './LessonPlanEntry';

interface LessonPlanGroupProps {
	group: LessonSection;
}

const LessonPlanGroup = ({ group }: LessonPlanGroupProps) => {
	const { removeGroup, setGroupIndex, currentGroupIndex, currentIndex } = useCourseLessonsStore();
	const isActive = currentGroupIndex === group.id;

	const confirm = useConfirmationDialog({
		message: 'Are you sure you want to delete this lesson group and all entries?',
		onConfirm: () => removeGroup(group.id),
		onCancel: () => null,
	});

	return (
		<div className={`lesson-plan-group ${isActive ? 'lesson-plan-group-active' : ''}`}>
			{confirm.Dialog()}
			<div className="lesson-plan-item">
				<button
					type="button"
					className="lesson-plan-button"
					onClick={() => setGroupIndex(group.id)}>
					{group.title || 'Untitled'}
				</button>
				<TrashCanIcon passedEvent={() => confirm.onOpen(group.id)} />
			</div>
			<div className="lesson-plan-list">
				{group.lessons.map((lesson, index) => (
					<LessonPlanEntry
						key={lesson.id}
						groupId={group.id}
						lesson={lesson}
						index={index}
						isActive={isActive && currentIndex === index}
					/>
				))}
			</div>
		</div>
	);
};

export default LessonPlanGroup;
